import React, {useState} from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import firebase from "firebase";
import { withRouter } from "../../../providers/withRouter";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Lupa from "../../../icons/lupa.svg"
import CircularProgress from '@material-ui/core/CircularProgress';
import { LinearProgress, withStyles, Box } from "@material-ui/core";
import Swal from 'sweetalert2'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {props.label}
      </Box>
    </Box>
  );
}

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      tecnico: {},
      aaff: [],
      isLoading: true,
      desde: '',
      hasta: '',
      msjFincas: '',
      porcentaje: 0,
      pendientes:0,
      cargandoVisitas:false
    }
  }
  
  componentDidMount() {
    firestore.collection("tecnicos").doc(this.props.params.nif).get().then(snapshot => {
      if (!snapshot.exists) {
        window.location.replace("/tecnicos")
      }
      this.setState({ tecnico: snapshot.data() })

      let aaffs = []
      let promises = []
      firestore.collection("aaff").where('nifTecnico', '==', snapshot.data().nif).orderBy("razonSocial").get().then(snapshot2 => {
        let fincasVisitadas=0
        snapshot2.docs.forEach(doc => {
          let aaffData = doc.data()
          promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaffData.nif).get().then(fincas => {
            //let fincasVisitadas=0
            /*fincas.docs.forEach(finca => {
               if(finca.data().documentos?.hojaVisita?.validado && finca.data().activo !== "false"){
                fincasVisitadas++
              } 
            })*/
            
            //aaffData.fincasVisitadas = fincasVisitadas
            aaffData.nFincas = fincas.size
          }))
          promises.push(firestore.collection("hojasVisita").where("aaff.nif", "==", aaffData.nif).get().then(hv => {
            
            aaffData.fincasVisitadas = hv.size
          }))
          aaffs.push(aaffData)
        })
        Promise.all(promises).then(() => {
          let fVisitadas = 0
          let nFincas = 0
          aaffs.forEach(a => {
            fVisitadas = fVisitadas+a.fincasVisitadas
            nFincas = nFincas+a.nFincas
          })
          let pendientes = nFincas-fVisitadas
          let porcentaje = Math.round(((fVisitadas/nFincas)*100 + Number.EPSILON) * 100) / 100
          //console.log(nFincas, fVisitadas, pendientes, porcentaje)
          this.setState({ aaff: aaffs, isLoading: false, pendientes:pendientes, porcentaje:porcentaje })
        })
      })
    })
    //this.mostrarHojasVisita()
  }
  

  /* mostrarHojasVisita(){
    let datos = []
    let promises = []
    
    firestore.collection("aaff").get().then(snapshot => {
      snapshot.docs.forEach( h => {
        let data = h.data()
        if(h.data().fechaTecnico !== undefined){
          let d = new Date(h.data().fechaTecnico.seconds*1000)
          var diasdif= new Date() - d;
          var contdias = Math.round(diasdif/(1000*60*60*24));
          if(contdias === 3){
            console.log(new Date(), d, contdias)
            
            promises.push(firestore.collection("tecnicos").where("nif", "==", h.data().nifTecnico).get().then(tec => {
              tec.docs.forEach( t => {
                data.nombreTec = t.data().nombre+" "+t.data().apellidos
              })
            }))
            datos.push(data)
          }
        }
      })
      Promise.all(promises).then(() => {
        datos.map(dato => {
          console.log(dato.email, dato.nombreTec)
        })

        //this.enviarEmail()
    
      })
    })
  }
  enviarEmail = () => {
    const notificarAAFFNuevoTecAsignado = firebase.app().functions('europe-west1').httpsCallable('notificarAAFFNuevoTecAsignado');
      notificarAAFFNuevoTecAsignado({emailAAFF:'asier@globalpixel.es'})
      .then( result => {
        console.log(result.data);
        //toast.success("Email enviado con éxito")
      })
  } */

  cambiarFechaEncargo = (f) => {
    Swal.fire({
      title: 'Cambiar fecha encargo',
      html: `<input type="date" id="fecha" class="swal2-input">`,
      confirmButtonText: 'Cambiar',
      focusConfirm: false,
      preConfirm: () => {
        const fecha = Swal.getPopup().querySelector('#fecha').value
        if (!fecha) {
          Swal.showValidationMessage(`insertar fecha`)
        }
        return {fecha: fecha }
      }
    }).then((result) => {
      Swal.fire({
        icon: 'success',
        title: 'Fecha cambiada'
      })
      firestore.collection("aaff").doc(f).update({ 
        fechaEncargo: new Date(result.value.fecha)
      })
    })
    
  }

  filtrarFechas() {
    this.setState({cargandoVisitas:true})
    let desde = new Date(this.state.desde)
    let hasta = new Date(this.state.hasta)
    let numFincasVisitadas = 0
    let numFincasVisitadasRenovadas = 0
    let msjFincas = ''
    if(hasta<desde || desde === "Invalid Date" || hasta === "Invalid Date"){
      msjFincas="Las fechas no son correctas"
      this.setState({msjFincas: msjFincas})
    }else{
      firestore.collection('hojasVisita').where('tecnico.nif', '==', this.state.tecnico.nif).get().then(snapshot=>{
        let fechaVisita = ''
        let fechaVisitaRenovacion = ''
        let promises = []
        snapshot.docs.forEach( (e,i) => {
          fechaVisita = new Date(e.data().fechaVisita?.seconds * 1000)
          fechaVisitaRenovacion = new Date(e.data().fechaVisitaRenovacion?.seconds * 1000)
          
          if((desde <= fechaVisita && hasta >= fechaVisita) || (desde <= fechaVisitaRenovacion && hasta >= fechaVisitaRenovacion)){
            console.log(i, e.data().comunidad.nif, desde, fechaVisita, hasta, fechaVisitaRenovacion)
            console.log(i, desde <= fechaVisita, hasta >= fechaVisita, desde <= fechaVisitaRenovacion, hasta >= fechaVisitaRenovacion)
            console.log(i, (desde <= fechaVisita && hasta >= fechaVisita), (desde <= fechaVisitaRenovacion && hasta >= fechaVisitaRenovacion))
            console.log("fechaVisitaRenovacion > fechaVisita")
            console.log(i, fechaVisitaRenovacion > fechaVisita)
            if(fechaVisitaRenovacion > fechaVisita){
              numFincasVisitadasRenovadas++
              console.log("numFincasVisitadasRenovadas "+ numFincasVisitadasRenovadas)
            }
            else{
              numFincasVisitadas++
              console.log("numFincasVisitadas "+ numFincasVisitadas)
            }
            // promises.push(firestore.collection('fincas').doc(e.data().comunidad.nif).get().then( f => {
            //   // console.log(f.data()?.nif, f.data()?.renovada)
            //   console.log(fechaVisitaRenovacion > fechaVisita)
            //   if(fechaVisitaRenovacion > fechaVisita){
            //     numFincasVisitadasRenovadas++
            //   }
            //   else{
            //     numFincasVisitadas++
            //   }
              
            // }))
          }
        })
        Promise.all(promises).then(() => {
          // console.log(numFincasVisitadas, numFincasVisitadasRenovadas)
          if(numFincasVisitadas === 0 && numFincasVisitadasRenovadas === 0){
            msjFincas = "No se han realizado visitas en este periodo de tiempo"
          }else{
            msjFincas = "Se han realizado "+ numFincasVisitadas +" visitas nuevas y "+numFincasVisitadasRenovadas+" visitas de renovación en este periodo de tiempo"
          }
          this.setState({msjFincas: msjFincas, cargandoVisitas:false})
        })
      })
    }
  }

  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Técnicos / <b>{this.state.tecnico.nombre + " " + this.state.tecnico.apellidos}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO ADMINISTRADORES DE FINCAS
              <Link to="/tecnicos"><div className="gridHeadRight">{"<< volver al listado de Técnicos"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="buscador">
                <input type="date" placeholder="Desde" value={this.state.desde}
                  onChange={event => this.setState({desde: event.currentTarget.value})}   />
                <input type="date" placeholder="Hasta" value={this.state.hasta}
                  onChange={event => this.setState({hasta: event.currentTarget.value})}   />
                <button onClick={() => this.filtrarFechas()}>Buscar</button>
                <p>{this.state.cargandoVisitas ? <CircularProgress style={{color:'#fc7271', height: '20px', width: '20px', padding: '0'}} /> : this.state.msjFincas}</p>
              </div>
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Administrador</th>
                      <th>Fecha encargo</th>
                      <th>NIF</th>
                      <th>Localización</th>
                      <th style={{ textAlign: "center" }}>Nº Comunidades</th>
                      <th style={{ textAlign: "center" }}>Comunidades visitadas</th>
                      <th style={{ textAlign: "center" }}>Pendientes</th>
                      <th style={{ textAlign: "center" }}>Hojas de visita</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.isLoading ? <tr><td colSpan='10' style={{textAlign:'center'}}><CircularProgress style={{color:'#fc7271'}} /> </td></tr> : 
                    this.state.aaff.length === 0 ? <tr><td colSpan='9' style={{textAlign:'center'}}>No tiene administradores</td></tr> :
                      this.state.aaff.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.razonSocial}</Link></td>
                            <td onClick={() => this.cambiarFechaEncargo(e.nif)}>{e.fechaEncargo === undefined ? '-' : new Date(e.fechaEncargo.seconds*1000).toLocaleDateString()}</td>
                            <td>{e.nif}</td>
                            <td>{e.municipio} - {e.provincia === undefined ? '' : e.provincia}</td>
                            <td style={{ textAlign: "center" }}>{e.nFincas}</td>
                            <td style={{ textAlign: "center" }}><LinearProgressWithLabel value={e.fincasVisitadas / (e.nFincas || 1) * 100} label={e.fincasVisitadas + '/' + e.nFincas} variant="determinate" /></td>
                            <td style={{ textAlign: "center" }}>{e.nFincas-e.fincasVisitadas}</td>
                            <td style={{ textAlign: "center" }}><Link to={"/tecnicos/" + this.state.tecnico.nif + "/aaff/" + e.nif + "/hojas-visita"}><img src={Lupa} alt="Hojas de visita" className="tableIcon"></img></Link></td>
                          </tr>
                        )
                      })
                  }
                  {this.state.isLoading ? null : this.state.aaff.length === 0 ? null :
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>{this.state.porcentaje}%</td>
                      <td style={{ textAlign: "center" }}>{this.state.pendientes}</td>
                      <td></td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(AAFF);