import React, { useState, useEffect } from "react";
import { firestore, auth } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from "react-router-dom";
import { ExportToExcel } from "../../../providers/export-excel-comunidades";
import firebase from "firebase";
import NoVisitado from "../../../components/noVisitadoComunidad";

const GestionComunidades = () => {

    const [fincas, setFincas] = useState([]);
    const [centrosTrabajo, setCentrosTrabajo] = useState([]);
    const [fincasPendientes, setFincasPendientes] = useState([]);
    const [fincasVisitadas, setFincasVisitadas] = useState([])
    const [fincasRenovadasPendientes, setFincasRenovadasPendientes] = useState([])
    const [fincasRenovadasVisitadas, setFincasRenovadasVisitadas] = useState([])
    const [fincasPendientesAux, setFincasPendientesAux] = useState([]);
    const [fincasVisitadasAux, setFincasVisitadasAux] = useState([])
    const [fincasRenovadasPendientesAux, setFincasRenovadasPendientesAux] = useState([])
    const [fincasRenovadasVisitadasAux, setFincasRenovadasVisitadasAux] = useState([])
    const [hojasVisita, setHojasVisita] = useState([])
    const [nifHojasVisita, setNifHojasVisita] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [cargando, setCargando] = useState(false)
    const [tab, setTab] = useState(0)
    const [mensaje, setMensaje] = useState('')
    const [desde, setDesde] = useState('')
    const [hasta, setHasta] = useState('')
    const [msjFincas, setMsjFincas] = useState('')
    const [fichero, setFichero] = useState('')
    const [tipoOrden, setTipoOrden] = useState('asc')
    const [aaffs, setAAFFS] = useState([])
    const [tecnicos, setTecnicos] = useState([])
    const [aaffSeleccionado, setAAFFSeleccionado] = useState('')
    const [tecnicoSeleccionado, setTecnicoSeleccionado] = useState('')

    /* useEffect(() => {
        let hojasVisita = []
        let nifHojasVisita = []
        let fincasPendientes = []
        let fincasVisitadas = []
        let fincasRenovadasPendientes = []
        let fincasRenovadasVisitadas = []
        firestore.collection("hojasVisita").get().then( snapshot => {
        snapshot.docs.forEach( hv => {
            hojasVisita.push(hv.data())
            nifHojasVisita.push(hv.data().comunidad.nif)

        })
        setHojasVisita(hojasVisita)
        setNifHojasVisita(nifHojasVisita)
        })

        firestore.collection('fincas').limit(100).get().then(snapshot => {
            let promises = []
            let fincasData = []
            // let p = 0
            // let v = 0
            // let rp = 0
            // let rv = 0
            console.log(snapshot.docs.length)
            snapshot.docs.forEach( (finca, i) => {
                let fincaData = {nif: finca.data().nif, razonSocial: finca.data().razonSocial, fechaAlta: new Date(finca.data().fechaAlta?.seconds *1000).toLocaleDateString()}
                if (finca.data().activo !== "false"){
                    console.log(i+1, finca.data().nif, finca.data().nifAAFF)
                    promises.push(firestore.collection("aaff").where("nif","==",finca.data().nifAAFF).get().then(aaff => {
                        aaff.docs.forEach(a => {
                            fincaData.razonSocialAAFF = a.data().razonSocial
                            firestore.collection("tecnicos").where("nif","==",a.data().nifTecnico).get().then(tecnico => {
                                if(tecnico.docs.length===0){
                                    fincaData.tecnico = '-'
                                }else{
                                    tecnico.docs.forEach((t) => {
                                        fincaData.tecnico = t.data().nombre+" "+t.data().apellidos
                                    })
                                }
                            })
                        })
                    }))
                    // console.log(fincaData.nif, nifHojasVisita.includes(fincaData.nif))
                    // console.log(fincaData.nif)
                    if(nifHojasVisita.includes(fincaData.nif)){
                        hojasVisita.forEach( fhv => {
                            if(fhv.comunidad.nif === fincaData.nif){
                                let dias = ''
                                let year = new Date(fhv.fechaVisitaRenovacion?.seconds*1000).getFullYear()
                                if(finca.data().renovada && fhv.fechaVisitaRenovacion !== undefined && year !== 1970){
                                  //console.log(finca.nif, this.daysDiff(new Date(fhv.fechaVisitaRenovacion?.seconds*1000), new Date()), finca.renovadaVisitada)
                                  dias = daysDiff(new Date(fhv.fechaVisitaRenovacion?.seconds*1000), new Date())
                                }else{
                                  //console.log(finca.nif, this.daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date()), finca.renovadaVisitada)
                                  dias = daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date())
                                }
              
                                if(finca.data().renovada && !finca.data().renovadaVisitada && dias > 304){ //9 meses = 273 dias - 6 meses = 182 dias - 10 meses = 304 dias
                                  fincasRenovadasPendientes.push(fincaData)
                                //   rp++
                                //   console.log(rp, i, fincaData.nif, fincaData.razonSocial, " RENOVADA PENDIENTE")
                                }else if(finca.data().renovadaVisitada){
                                  fincasRenovadasVisitadas.push(fincaData)
                                //   rv++
                                //   console.log(rv, i, fincaData.nif, fincaData.razonSocial, " RENOVADA VISITADA")
                                }else{
                                  fincasVisitadas.push(fincaData)
                                //   v++
                                //   console.log(v, i, fincaData.nif, fincaData.razonSocial, " VISITADAA")
                                }
                            }
                        })
                        // fincasVisitadas.push(fincaData)
                        // console.log("VISITADA")
                        
                    }else{
                        fincasPendientes.push(fincaData)
                        // p++
                        // console.log(p, i, fincaData.nif, fincaData.razonSocial, " PENDIENTEE")
                        
                    }
                    
                }
            })
            Promise.all(promises).then(() => {
                console.log(fincasPendientes)
                console.log(fincasVisitadas)
                console.log(fincasRenovadasPendientes)
                console.log(fincasRenovadasVisitadas)
                setTimeout(()=>{
                    setFincas(fincasPendientes)
                    setFincasPendientes(fincasPendientes)
                    setFincasVisitadas(fincasVisitadas)
                    setFincasRenovadasPendientes(fincasRenovadasPendientes)
                    setFincasRenovadasVisitadas(fincasRenovadasVisitadas)
                    setFincasPendientesAux(fincasPendientes)
                    setFincasVisitadasAux(fincasVisitadas)
                    setFincasRenovadasPendientesAux(fincasRenovadasPendientes)
                    setFincasRenovadasVisitadasAux(fincasRenovadasVisitadas)
                    setIsLoading(false)
                    setFichero("Listado comunidades pendientes")
                },200)
                setTimeout(() => {
                    ordenarFecha()
                    // cambiarFormatoFecha()
                }, 300)
            })
        })
    },[]) */

    useEffect(() => {
        firestore.collection('aaff').orderBy('razonSocial').get().then(snapshot=> {
            let aaffs = []
            snapshot.docs.forEach(a => {
                aaffs.push({nif: a.data().nif, razonSocial: a.data().razonSocial})
            })
            setAAFFS(aaffs)
        })
        firestore.collection('tecnicos').orderBy('nombre').get().then(snapshot=> {
            let tecnicos = []
            snapshot.docs.forEach(t => {
                if(t.data().status){
                    tecnicos.push({nif: t.data().nif, nombre: t.data().nombre+" "+t.data().apellidos})
                }
            })
            setTecnicos(tecnicos)
        })
    },[])

    const enviarFiltros = () => {
        setIsLoading(true)
        setTab(0)
        let fincasPendientes = []
        let fincasVisitadas = []
        let fincasRenovadasPendientes = []
        let fincasRenovadasVisitadas = []
        let p = 0
        let v = 0
        let rp = 0
        let rv = 0
        let tc = !tecnicoSeleccionado ? '' : tecnicoSeleccionado
        let as = !aaffSeleccionado ? '' : aaffSeleccionado
        let d = !desde ? '' : Date.parse(desde)/1000
        let h = !hasta ? Date.parse(new Date)/1000 : Date.parse(hasta)/1000
        let fincas = []
        let promises = []
        // Construir la consulta de Firebase
        let consulta = firestore.collection("aaff")
        let consultaFinca = firestore.collection("fincas")

        if (tc !== "") {
            consulta = consulta.where("nifTecnico", "==", tc);
        }

        if (as !== "") {
            consulta = consulta.where('nif', '==', as)
        }
        if(d !== ""){
            consultaFinca.where("fechaAlta", "==", firebase.firestore.Timestamp.fromMillis(1686787200000));
            console.log(new Date(d*1000).toLocaleDateString(), desde, firebase.firestore.Timestamp.fromMillis(1686787200000))
            // consultaFinca = consultaFinca.where("FechaAlta", ">=", { seconds: 1685577600, nanoseconds: 0 }).where("FechaAlta", "<=", { seconds: 1686787200, nanoseconds: 999999999 });
            // let fechaInicioTimestamp = firebase.firestore.Timestamp.fromMillis(d * 1000);
            // let fechaFinTimestamp = firebase.firestore.Timestamp.fromMillis(h * 1000);
            // console.log(new Date(fechaInicioTimestamp.seconds*1000), new Date(fechaFinTimestamp.seconds*1000), firebase.firestore.Timestamp.fromDate(new Date(fechaFinTimestamp.seconds*1000)), new Date(fechaInicioTimestamp.seconds*1000)>= new Date(), new Date(fechaFinTimestamp.seconds*1000)<= new Date())
            // consultaFinca = consultaFinca.where("FechaAlta", ">=", new Date(fechaInicioTimestamp.seconds*1000)).where("FechaAlta", "<=", new Date(fechaFinTimestamp.seconds*1000));
        }
        // console.log(d,h)
        // Ejecutar la consulta
        
        consulta.get().then((querySnapshot) => {
            let numCT = 0
            querySnapshot.docs.forEach((doc) => {
                // Hacer algo con los documentos que cumplen las condiciones
                // console.log(doc.data());
                promises.push(consultaFinca.where('nifAAFF', '==', doc.data().nif).get().then(finca => {
                    finca.docs.forEach( (f,i) => {
                        // console.log(f.data().fechaAlta)
                        // console.log(f.data().nif, (f.data().fechaAlta.seconds), d, h)
                        if (f.data().activo !== "false"){
                            // console.log(f.data().noVisitada)
                            let fincaData = {
                                nif: f.data().nif, 
                                razonSocial: f.data().razonSocial, 
                                fechaAlta: new Date(f.data().fechaAlta?.seconds *1000).toLocaleDateString(),
                                fechaRenovacion: new Date(f.data().fechaRenovacion?.seconds *1000).toString() !== 'Invalid Date' ? new Date(f.data().fechaRenovacion?.seconds *1000).toLocaleDateString() : " ",
                                // renovada: f.data().renovada,
                                // renovadaVisitada: f.data().renovadaVisitada,
                                fechaNoVisita: new Date(f.data().fechaNoVisita?.seconds *1000).toString() !== 'Invalid Date' ? new Date(f.data().fechaNoVisita?.seconds *1000).toLocaleDateString() : " ",
                                observacionNoVisita: f.data().observacionNoVisita,
                                centrosTrabajo: f.data().centrosTrabajo
                            }
                            firestore.collection("tecnicos").where("nif","==",doc.data().nifTecnico).get().then(tecnico => {
                                if(tecnico.docs.length===0){
                                    fincaData.tecnico = '-'
                                }else{
                                    tecnico.docs.forEach((t) => {
                                        fincaData.tecnico = t.data().nombre+" "+t.data().apellidos
                                    })
                                }
                            })
                            fincaData.aaff = doc.data().razonSocial
                            fincaData.nifAAFF = doc.data().nif
                            let fecha = f.data().fechaRenovacion?.seconds !== undefined ? f.data().fechaRenovacion?.seconds : f.data().fechaAlta.seconds
                            // console.log(fecha)
                            fincaData.fecha = new Date(fecha *1000).toLocaleDateString()
                            // console.log(f.data().nif, f.data().fechaAlta.seconds >= d, f.data().fechaAlta.seconds <= h)
                            // console.log(fincaData.nif)
                            firestore.collection("hojasVisita").where('comunidad.nif', '==', f.data().nif).get().then( snapshot => {
                                /* if(f.data().centrosTrabajo?.length > 0){
                                    snapshot.docs.forEach(hv => {
                                        // console.log(hv.data())
                                        if(hv.data().centrosTrabajo?.length > 0 && hv.data().centrosTrabajo?.length === f.data().centrosTrabajo?.length){
                                            console.log(hv.data().centrosTrabajo.length)
                                            numCT = 1
                                        }else{
                                            numCT = 2
                                        }
                                    })
                                } */
                                if(snapshot.docs.length === 0){
                                    if(d !== "" && fecha >= d && fecha <= h){
                                        // console.log("FECHAS PENDIENTE", f.data().fechaAlta.seconds >= d, f.data().fechaAlta.seconds <= h)
                                        fincasPendientes.push(fincaData)
                                    }
                                    if(d === ""){
                                        // console.log("PENDIENTEE")
                                        fincasPendientes.push(fincaData)
                                    }
                                    p++
                                    // console.log(p, i, fincaData.nif+ " PENDIENTE")
                                }else{
                                    snapshot.docs.forEach(fhv => {
                                        // if(fhv.comunidad.nif === finca.nif){}
                                        // console.log(fhv.data())
                                        let dias = ''
                                        let year = new Date(fhv.data().fechaVisitaRenovacion?.seconds*1000).getFullYear()
                                        // console.log(year)
                                        if(f.data().renovada && fhv.data().fechaVisitaRenovacion !== undefined && year !== 1970){
                                            // console.log(new Date(fhv.fechaVisitaRenovacion?.seconds*1000))
                                            // console.log(fincaData.nif, daysDiff(new Date(fhv.fechaVisitaRenovacion?.seconds*1000), new Date()), fincaData.renovadaVisitada)
                                            dias = daysDiff(new Date(fhv.data().fechaVisitaRenovacion?.seconds*1000), new Date())
                                        }else{
                                            // console.log(fincaData.nif, daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date()), fincaData.renovadaVisitada)
                                            dias = daysDiff(new Date(fhv.data().fechaVisita?.seconds*1000), new Date())
                                        }
                                        // console.log(f.data().nif, dias)
                                        //fincaData.dias = dias
                                        if(f.data().renovada && !f.data().renovadaVisitada && dias > 304){ //9 meses = 273 dias - 6 meses = 182 dias - 10 meses = 304 dias
                                            if(d !== "" && fecha >= d && fecha <= h){
                                                // console.log("FECHAS RENOVADA PENDIENTE", fecha >= d, fecha <= h)
                                                fincasRenovadasPendientes.push(fincaData)
                                            }
                                            if(d === ""){
                                                // console.log("RENOVADA PENDIENTE")
                                                fincasRenovadasPendientes.push(fincaData)
                                            }
                                            rp++
                                            // console.log(rp, i, fincaData.nif, fincaData.razonSocial, " RENOVADA PENDIENTE")
                                        }else if(f.data().renovadaVisitada){
                                            if(d !== "" && fecha >= d && fecha <= h){
                                                // console.log("FECHAS RENOVADA VISITADA", f.data().fechaAlta.seconds >= '1672531200', f.data().fechaAlta.seconds <= '1686873600')
                                                fincasRenovadasVisitadas.push(fincaData)
                                            }
                                            if(d === ""){
                                                // console.log("RENOVADA VISITADA")
                                                fincasRenovadasVisitadas.push(fincaData)
                                            }
                                            rv++
                                            // console.log(rv, i, fincaData.nif, fincaData.razonSocial, " RENOVADA VISITADA")
                                        }else{
                                            if(d !== "" && fecha >= d && fecha <= h){
                                                // console.log("FECHAS VISITADAA", f.data().fechaAlta.seconds, d, '1672531200', h, '1686873600', f.data().fechaAlta.seconds >= '1672531200', f.data().fechaAlta.seconds <= '1686873600')
                                                fincasVisitadas.push(fincaData)
                                            }
                                            if(d === ""){
                                                // console.log("VISITADAA",)
                                                fincasVisitadas.push(fincaData)
                                            }
                                            v++
                                            // console.log(v, i, fincaData.nif, fincaData.razonSocial, " VISITADAA")
                                        }
                                    })
                                }
                            })
                            // console.log(i+1, f.data().nif, f.data().nifAAFF)
                            fincas.push(fincaData)
                        }
                    })
                }))
            });
            Promise.all(promises).then(()=> {
                console.log(fincasPendientes)
                console.log(fincasVisitadas)
                console.log(fincasRenovadasPendientes)
                console.log(fincasRenovadasVisitadas)
                console.log(fincas)
                setTimeout(()=>{
                    setFincas(fincasPendientes)
                    setFincasPendientes(fincasPendientes)
                    setFincasVisitadas(fincasVisitadas)
                    setFincasRenovadasPendientes(fincasRenovadasPendientes)
                    setFincasRenovadasVisitadas(fincasRenovadasVisitadas)
                    setFincasPendientesAux(fincasPendientes)
                    setFincasVisitadasAux(fincasVisitadas)
                    setFincasRenovadasPendientesAux(fincasRenovadasPendientes)
                    setFincasRenovadasVisitadasAux(fincasRenovadasVisitadas)
                    setIsLoading(false)
                    setFichero("Listado comunidades pendientes")
                },1300)
                setTimeout(() => {
                    ordenarFecha()
                    // cambiarFormatoFecha()
                }, 1400)
            })
        }).catch((error) => {
            console.log("Error al ejecutar la consulta:", error);
        });
    }
    const daysDiff = (dateFrom, dateTo) => {
        var diasdif= dateTo.getTime()-dateFrom.getTime();
        var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
        return contdias 
    }

    const handleChange = (event, newValue) => {
        if(newValue === 0){
            console.log(fincasPendientes)
            setFincas( fincasPendientes )
            setMensaje("No hay fincas pendientes")
            setFichero("Listado comunidades pendientes")
        }else if(newValue === 2){
            console.log(fincasRenovadasPendientes)
            setFincas( fincasRenovadasPendientes )
            setMensaje("No hay fincas pendientes")
            setFichero("Listado comunidades renovadas pendientes")
        }else if(newValue === 3){
            setFincas( fincasRenovadasVisitadas )
            console.log(fincasRenovadasVisitadas)
            setMensaje("No hay fincas visitadas")
            setFichero("Listado comunidades renovadas visitadas")
        }else{
            setFincas( fincasVisitadas )
            console.log(fincasVisitadas)
          setMensaje("No hay fincas visitadas")
          setFichero("Listado comunidades visitadas")
        }
        setTab(newValue);
        // setTimeout(() =>{
        //   this.ordenarCP();
        // },800)
    };
    useEffect(() => {
        if(tab === 0){
            setFincas( fincasPendientes )
            console.log(fincasPendientes)
            setMensaje("No hay fincas pendientes")
        }else if(tab === 2){
            console.log(fincasRenovadasPendientes)
            setFincas( fincasRenovadasPendientes )
            setMensaje("No hay fincas pendientes")
        }else if(tab === 3){
            setFincas( fincasRenovadasVisitadas )
            console.log(fincasRenovadasVisitadas)
            setMensaje("No hay fincas visitadas")
        }else{
            setFincas( fincasVisitadas )
            console.log(fincasVisitadas)
          setMensaje("No hay fincas visitadas")
        }
    },[fincasPendientes])

    const filtrarFechas = () => {
        let d 
        let h 
        if (!desde){
            d = ''
        }else{
            d = Date.parse(desde)
        }
        if (!hasta){
            h = ''
        }else{
            h = Date.parse(hasta)
        }
        let fp = []
        let fv = []
        let frp = []
        let frv = []
        let msjFincas = ''
        console.log(d, h)
        // if(h<d ){
        //     msjFincas="Las fechas no son correctas"
        //     setMsjFincas(msjFincas)
        // }else{
        //     if(d === '' || h === ''){
        //         setFincasPendientes(fincasPendientesAux)
        //         setFincasVisitadas(fincasVisitadasAux)
        //         setFincasRenovadasPendientes(fincasRenovadasPendientesAux)
        //         setFincasRenovadasVisitadas(fincasRenovadasVisitadasAux)
        //     }else{
        //         msjFincas=""
        //         setMsjFincas(msjFincas)
        //         fincasPendientesAux.forEach(f => {
        //             // let fechaAlta = new Date(f.fechaAlta?.seconds * 1000)
        //             if(d <= new Date(convertToDate(f.fechaAlta)) && h >= new Date(convertToDate(f.fechaAlta))){
        //                 fp.push(f)
        //             }
        //         })
        //         fincasVisitadasAux.forEach(f => {
        //             // let fechaAlta = new Date(f.fechaAlta?.seconds * 1000)
        //             if(d <= new Date(convertToDate(f.fechaAlta)) && h >= new Date(convertToDate(f.fechaAlta))){
        //                 fv.push(f)
        //             }
        //         })
        //         fincasRenovadasPendientesAux.forEach(f => {
        //             // let fechaAlta = new Date(f.fechaAlta?.seconds * 1000)
        //             if(d <= new Date(convertToDate(f.fechaAlta)) && h >= new Date(convertToDate(f.fechaAlta))){
        //                 frp.push(f)
        //             }
        //         })
        //         fincasRenovadasVisitadasAux.forEach(f => {
        //             // let fechaAlta = new Date(f.fechaAlta?.seconds * 1000)
        //             if(d <= new Date(convertToDate(f.fechaAlta)) && h >= new Date(convertToDate(f.fechaAlta))){
        //                 frv.push(f)
        //             }
        //         })
        //         setFincasPendientes(fp)
        //         setFincasVisitadas(fv)
        //         setFincasRenovadasPendientes(frp)
        //         setFincasRenovadasVisitadas(frv)
        //         // console.log(fp)
        //         // console.log(fv)
        //     }
        // }
    }
    
    const ordenarAAFF = () => {
        const f = [...fincas];
        f.sort((a, b) => {
            if (tipoOrden === 'asc') {
                return a.aaff.localeCompare(b.aaff)
            }else{
                return b.aaff.localeCompare(a.aaff)
            }
        });
        setFincas(f)
        setTipoOrden(tipoOrden === 'asc' ? 'desc' : 'asc')
    }
    const ordenarTecnico = () => {
        const f = [...fincas];
        f.sort((a, b) => {
            if (tipoOrden === 'asc') {
                return a.tecnico.localeCompare(b.tecnico)
            }else{
                return b.tecnico.localeCompare(a.tecnico)
                }
            
        });
        setFincas(f)
        setTipoOrden(tipoOrden === 'asc' ? 'desc' : 'asc')
    }
    
    const ordenarComunidad = () => {
        const f = [...fincas];
        f.sort((a, b) => {
            if (tipoOrden === 'asc') {
                return a.razonSocial.localeCompare(b.razonSocial)
            }else{
                return b.razonSocial.localeCompare(a.razonSocial)
                }
            
        });
        setFincas(f)
        setTipoOrden(tipoOrden === 'asc' ? 'desc' : 'asc')
    }

    const ordenarFecha = () => {
        let f = fincas
        f.sort((a, b) => {
            // console.log(a.fecha, b.fecha, a.fecha - b.fecha, b.fecha - a.fecha)
            // cambiar el formato a las fechas con convertToDate para poder compararlas y ordenarlas de mas recientes a mas viejas y viceversa
            const A = new Date(convertToDate(a.fecha));
            const B = new Date(convertToDate(b.fecha));
          if (tipoOrden === 'asc') {
            return A - B;
          } else {
            return B - A;
          }
        });
        setTipoOrden(tipoOrden === 'asc' ? 'desc' : 'asc')
    };

    const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return `${month}/${day}/${year}`;
    };

    // const cambiarFormatoFecha = () => {
    //     let fincasNueva = []
    //     fincas.forEach(f => {
    //         fincasNueva.push({'CIF': f.nif, 'Razón social': f.razonSocial, 'Fecha Alta': new Date(f.fechaAlta *1000).toLocaleDateString(), "AAFF razón social": f.razonSocialAAFF, 'Técnico': f.tecnico})
    //     })
    //     console.log(fincasNueva)
    //     setFincas(fincasNueva)
    // }

    return ( 
        <>
            <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})` }}>Comunidades</div>
            <div className="pageContent tableContainer">
                <div className="buscador">
                    <input type="date" placeholder="Desde" value={desde} onChange={event => setDesde(event.currentTarget.value)}   />
                    <input type="date" placeholder="Hasta" value={hasta} onChange={event => setHasta(event.currentTarget.value)}   />
                    <select value={aaffSeleccionado} onChange={event => setAAFFSeleccionado(event.currentTarget.value)} style={{width:200}}>
                        <option value=""></option>
                        {aaffs.map((e, i) => {
                            return (
                                <option key={i} value={e.nif}>{e.razonSocial}</option>
                            )
                        })}
                    </select>
                    <select value={tecnicoSeleccionado} onChange={event => setTecnicoSeleccionado(event.currentTarget.value)} style={{width:200}}>
                        <option value=""></option>
                        {tecnicos.map((e, i) => {
                            return (
                                <option key={i} value={e.nif}>{e.nombre}</option>
                            )
                        })}
                    </select>
                    <button onClick={() => enviarFiltros()}>Buscar</button>
                    <p>{cargando ? <CircularProgress style={{color:'#fc7271', height: '20px', width: '20px', padding: '0'}} /> : msjFincas}</p>
                </div>
                <ExportToExcel fincasPendientes={fincasPendientes} fincasRenovadasPendientes={fincasRenovadasPendientes} fincasVisitadas={fincasVisitadas} fincasRenovadasVisitadas={fincasRenovadasVisitadas} />
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label="No visitadas" />
                    <Tab label="Visitadas" />
                    <Tab label="Renovadas no Visitadas" />
                    <Tab label="Renovadas Visitadas" />
                </Tabs>
                <div className="tableWrapper">
                    <table>
                    <thead>
                        <tr>
                        <th></th>
                        <th>Nif</th>
                        <th onClick={() => ordenarComunidad()} style={{cursor:'pointer'}}>Comunidad</th>
                        <th onClick={() => ordenarFecha()} style={{cursor:'pointer'}}>Fecha</th>
                        <th onClick={() => ordenarAAFF()} style={{cursor:'pointer'}}>AAFF</th>
                        <th onClick={() => ordenarTecnico()} style={{cursor:'pointer'}}>Técnico</th>
                        <th style={{cursor:'pointer'}}>Motivo no visita</th>
                        <th style={{cursor:'pointer'}}>Fecha no visita</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <tr><td colSpan='14' style={{textAlign:'center'}}><CircularProgress style={{color:'#fc7271'}} /> </td></tr> : 
                        fincas.length===0 ? <tr><td colSpan='14' style={{textAlign:'center'}}>{mensaje}</td></tr> :
                        fincas.map((f,i)=>{
                            return(
                                <>
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td><Link to={"/aaff/"+f.nifAAFF+"/comunidades/" + f.nif} target="_blank">{f.nif}</Link></td>
                                        <td><Link to={"/aaff/"+f.nifAAFF+"/comunidades/" + f.nif} target="_blank">{f.razonSocial}</Link></td>
                                        <td>{f.fechaRenovacion !== " " ? f.fechaRenovacion : f.fechaAlta}</td>
                                        <td><Link to={"/aaff/"+f.nifAAFF+"/comunidades"} target="_blank"> {f.aaff}</Link></td>
                                        <td>{f.tecnico}</td>
                                        <td style={{ textAlign: "center", width: 105 }}>{f.observacionNoVisita !== undefined ? f.observacionNoVisita : "-"}</td>
                                        <td style={{ textAlign: "center", width: 105 }}>{f.fechaNoVisita}</td>
                                    </tr>
                                    {f.centrosTrabajo?.length > 0 &&
                                    f.centrosTrabajo?.map((c,j) => {
                                        return(
                                            <tr key={j} style={{backgroundColor: j%2==0 ? "#fffbf4" : "#fff9ea" }}>
                                                <td>{j+1}</td>
                                                <td>{c.nombre}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={{ textAlign: "center", width: 105 }}>{c.observacionNoVisita !== undefined ? c.observacionNoVisita : "-"}</td>
                                                <td style={{ textAlign: "center", width: 105 }}>{new Date(c.fechaNoVisita?.seconds*1000).toString() !== 'Invalid Date' ? new Date(c.fechaNoVisita?.seconds*1000).toLocaleDateString() : "-"}</td>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                        })
                        }
                    </tbody>
                    </table>
                </div>
            </div>
        </>
     );
}
 
export default withRouter(GestionComunidades);