import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify";
import { firestore } from "../../../providers/firebase";
import { Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();

  const [editarDatos, setEditarDatos] = useState({});
  
  useEffect(()=>{
    const fincaData = {
      razonSocial: props.finca.razonSocial,
      email: props.finca.contacto.email,
      nombre: props.finca.contacto.nombre,
      telefono: props.finca.contacto.telefono,
      municipio: props.finca.municipio,
      direccion: props.finca.direccion,
      codigoPostal: props.finca.codigoPostal,
      provincia: props.finca.provincia
    }
    setEditarDatos(fincaData)
  },[props])

  function handleChange(evt) {
    const value = evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value
    });
  }

  const editar = (finca) => {
    console.log(finca)
    console.log(props.finca.nif)
    const toastInfo = toast.info("Guardando datos",{autoClose:false})
    /* firestore.collection("fincas").doc(props.finca.nif).get().then(f => {
      console.log(f.data())
    }) */
    firestore.collection("fincas").doc(props.finca.nif).update({
      razonSocial: finca.razonSocial,
      contacto:{
        nombre: finca.nombre,
        email: finca.email,
        telefono: finca.telefono,

      },
      codigoPostal: finca.codigoPostal,
      direccion: finca.direccion,
      municipio: finca.municipio,
      provincia: finca.provincia,
      
    }).then(() => {
      toast.update(toastInfo, {
        render: "Datos guardados con éxito",
        type: toast.TYPE.SUCCESS,
        autoClose: true
      });
      props.handleClose()
    }).catch(err => {
      console.log(err)
      toast.error("Error al editar los datos")
    })
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.opened}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div className={classes.form}>
                  <TextField id="razonSocial" name="razonSocial" label="Razón Social" value={editarDatos.razonSocial} onChange={handleChange} />
                  <TextField id="email" name="email" label="Email" value={editarDatos.email} onChange={handleChange} />
                  <TextField id="nombre" name="nombre" label="Nombre" value={editarDatos.nombre} onChange={handleChange} />
                  <TextField id="telefono" name="telefono" label="Teléfono" value={editarDatos.telefono} onChange={handleChange} />
                  <TextField id="direccion" name="direccion" label="Dirección" value={editarDatos.direccion} onChange={handleChange} />
                  <TextField id="codigoPostal" name="codigoPostal" label="Codigo Postal" value={editarDatos.codigoPostal} onChange={handleChange} />
                  <TextField id="municipio" name="municipio" label="Municipio" value={editarDatos.municipio} onChange={handleChange} />
                  <Select native name="provincia" id="provincia" style={{ textTransform: 'uppercase'}} onChange={handleChange} defaultValue={editarDatos.provincia}>
                  <option value=""></option>
                  <option value="A CORUÑA">A CORUÑA</option>
                  <option value="ÁLAVA/ARABA">ÁLAVA/ARABA</option>
                  <option value="ALBACETE">ALBACETE</option>
                  <option value="ALICANTE">ALICANTE</option>
                  <option value="ALMERÍA">ALMERÍA</option>
                  <option value="ASTURIAS">ASTURIAS</option>
                  <option value="ÁVILA">ÁVILA</option>
                  <option value="BADAJOZ">BADAJOZ</option>
                  <option value="BALEARES">BALEARES</option>
                  <option value="BARCELONA">BARCELONA</option>
                  <option value="BIZKAIA">BIZKAIA</option>
                  <option value="BURGOS">BURGOS</option>
                  <option value="CÁCERES">CÁCERES</option>
                  <option value="CÁDIZ">CÁDIZ</option>
                  <option value="CANTABRIA">CANTABRIA</option>
                  <option value="CASTELLÓN">CASTELLÓN</option>
                  <option value="CEUTA">CEUTA</option>
                  <option value="CIUDAD REAL">CIUDAD REAL</option>
                  <option value="CÓRDOBA">CÓRDOBA</option>
                  <option value="CUENCA">CUENCA</option>
                  <option value="GIRONA">GIRONA</option>
                  <option value="GRANADA">GRANADA</option>
                  <option value="GUADALAJARA">GUADALAJARA</option>
                  <option value="GIPUZKOA">GIPUZKOA</option>
                  <option value="HUELVA">HUELVA</option>
                  <option value="HUESCA">HUESCA</option>
                  <option value="JAÉN">JAÉN</option>
                  <option value="LA RIOJA">LA RIOJA</option>
                  <option value="LAS PALMAS">LAS PALMAS</option>
                  <option value="LEÓN">LEÓN</option>
                  <option value="LLEIDA">LLEIDA</option>
                  <option value="LUGO">LUGO</option>
                  <option value="MADRID">MADRID</option>
                  <option value="MÁLAGA">MÁLAGA</option>
                  <option value="MELILLA">MELILLA</option>
                  <option value="MURCIA">MURCIA</option>
                  <option value="NAVARRA">NAVARRA</option>
                  <option value="OURENSE">OURENSE</option>
                  <option value="PALENCIA">PALENCIA</option>
                  <option value="PONTEVEDRA">PONTEVEDRA</option>
                  <option value="SALAMANCA">SALAMANCA</option>
                  <option value="SEGOVIA">SEGOVIA</option>
                  <option value="SEVILLA">SEVILLA</option>
                  <option value="SORIA">SORIA</option>
                  <option value="TARRAGONA">TARRAGONA</option>
                  <option value="TENERIFE">TENERIFE</option>
                  <option value="TERUEL">TERUEL</option>
                  <option value="TOLEDO">TOLEDO</option>
                  <option value="VALENCIA">VALENCIA</option>
                  <option value="VALLADOLID">VALLADOLID</option>
                  <option value="ZAMORA">ZAMORA</option>
                  <option value="ZARAGOZA">ZARAGOZA</option>
                  </Select>
                </div>
                <div className={classes.form}>
                  <Button variant="contained" onClick={props.handleClose}>Cancelar</Button>
                  <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => editar(editarDatos)}>Editar</Button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}