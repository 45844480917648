import React from "react"
import { Routes, Route, Link } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ProveedorIcon from "../icons/proveedor.svg";

import Inicio from "./pages//aaff/Inicio";
import Comunidades from "./pages/aaff/Comunidades";
import Proveedores from "./pages/aaff/Proveedores";
import Comunidad from "./pages/aaff/Comunidad";
import Proveedor from "./pages/aaff/Proveedor";
import AAFF from "./pages/aaff/AAFF";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="comunidades" element={<Comunidades />} />
      <Route path="comunidades/:nif" element={<Comunidad />} />
      <Route path="proveedores" element={<Proveedores />} />
      <Route path="proveedores/:nif" element={<Proveedor />} />
      <Route path="aaff" element={<AAFF />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <Link to="/aaff">
        <div className="menuBtn">
          <img src={Edificios} alt="Panel Principal" />
          Panel Principal
        </div>
      </Link>
      <Link to="/comunidades">
        <div className="menuBtn">
          <img src={Edificios} alt="Comunidades" />
          Comunidades
        </div>
      </Link>
      <Link to="/proveedores">
        <div className="menuBtn">
          <img src={ProveedorIcon} alt="Proveedores" />
          Proveedores
        </div>
      </Link>
    </>
  )
}