import React from "react"
import { Routes, Route, Link } from "react-router-dom";
import Edificios from "../icons/edificios.svg";

import Inicio from "./pages/comercial/Inicio";
import AAFF from "./pages/comercial/AAFF";
import Comunidades from "./pages/comercial/Comunidades";
import Proveedores from "./pages/comercial/Proveedores";
import Proveedor from "./pages/comercial/Proveedor";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff/:nif/comunidades" element={<Comunidades />} />
      <Route path="/aaff/:nif/proveedores" element={<Proveedores />} />
      <Route path="/aaff/:nifAAFF/proveedores/:nifProveedor" element={<Proveedor />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <Link to="/aaff">
        <div className="menuBtn">
          <img src={Edificios} alt="Administradores de Fincas" />
          Administradores de Fincas
        </div>
      </Link>
    </>
  )
}