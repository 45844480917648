import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { firestore/* , storage */ } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { toast } from "react-toastify";
import { Collapse } from "@material-ui/core";
import {abrirArchivo } from "../../../providers/documentos";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Minus from "../../../icons/minus.svg"
import Descargar from "../../../icons/descargar.svg"

class Comunidades extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      tecnico: {},
      aaff: {},
      expandir: true,
    }
  }
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => this.setState({ aaff: doc.data() })).catch(err => window.location.replace("/aaff"))
    this.loadPageContent()
  }

  loadPageContent = () => {
    firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).orderBy('razonSocial').get().then(snapshot => {
      let data = []
      snapshot.docs.forEach(e => data.push(e.data()))
      this.setState({ fincas: data })
      //console.log(this.state.fincas)
    })
  }
  generarInformeProveedor = () => {
    toast.info("Generando informe...")
    var iFrame = document.getElementById('iFramePdf');
    iFrame.src = `/doc-render/${this.props.params.nif}/situacion-proveedores`
  }

  generarInformeRiesgos = () => {
    toast.info("Generando informe...")
    var iFrame = document.getElementById('iFramePdf');
    iFrame.src = `/doc-render/${this.props.params.nif}/situacion-riesgos`
  }

  generarInformeComunidades = () => {
    toast.info("Generando informe...")
    var iFrame = document.getElementById('iFramePdf');
    iFrame.src = `/doc-render/${this.props.params.nif}/situacion-comunidades`
  }
  /* abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  } */

  render() {
    if (this.state.fincas === []) return <></> //Loading data...
    return (
      <>
        <iframe title='pdf' id="iFramePdf" style={{ display: 'none' }}></iframe>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Administrador / <b>{this.state.aaff.razonSocial}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO DE COMUNIDADES
              <Link to="/aaff"><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>
              <div style={{ display: "flex", justifyContent: "left", alignItems: 'center' }}>
                <div className="bigBtn" onClick={this.generarInformeComunidades}><img src={Descargar} alt="Eliminar" />GENERAR INFORME COMUNIDADES</div>
                <div className="bigBtn" onClick={this.generarInformeProveedor}><img src={Descargar} alt="Eliminar" />GENERAR INFORME PROVEEDORES</div>
                {/* <Link className="bigBtn" style={{color:'#fff !important'}} target="_blank" to={`/doc-render/${this.props.params.nif}/situacion-comunidades-pdf`}><img src={Descargar} alt="GENERAR INFORME COMUNIDADES" />GENERAR INFORME COMUNIDADES</Link> */}
                {/* <Link className="bigBtn" style={{color:'#fff !important'}} target="_blank" to={`/doc-render/${this.props.params.nif}/situacion-proveedores-pdf`}><img src={Descargar} alt="GENERAR INFORME PROVEEDORES" />GENERAR INFORME PROVEEDORES</Link> */}
                <div className="bigBtn" onClick={this.generarInformeRiesgos}><img src={Descargar} alt="GENERAR INFORME RIESGOS" />GENERAR INFORME RIESGOS</div>
              </div>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Comunidad</th>
                      <th>NIF</th>
                      <th style={{ textAlign: "center" }}>LOPD</th>
                      <th style={{ textAlign: "center" }}>Hoja de visita</th>
                      {/* <th style={{ textAlign: "center" }}>Plan de prevención</th> */}
                      <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                      <th style={{ textAlign: "center" }}>Plan emergencia</th>
                      <th>Activa</th>
                      <th>Servicios</th>
                      <th>Empleados</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fincas.map((e, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td>{e.razonSocial}</td>
                            <td>{e.nif}</td>
                            <td style={{ textAlign: "center" }}>{e.documentos.lopd?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                              e.documentos.lopd?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                abrirArchivo(e.documentos.lopd?.ruta)
                              }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                            </td>
                            {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? 
                              <>
                                <td style={{ textAlign: "center" }}>{e.documentos.hojaVisita?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                  e.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                    abrirArchivo(e.documentos.hojaVisita?.ruta)
                                  }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                                </td>
                                {/* <td style={{ textAlign: "center" }}>{e.documentos.prevencionRiesgos?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                  e.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                    abrirArchivo(e.documentos.prevencionRiesgos?.ruta)
                                  }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                                </td> */}
                                <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                  e.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                    abrirArchivo(e.documentos.evaluacionRiesgos?.ruta)
                                  }} /> : <span className="tableIcon">-</span> /* <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" /> */}
                                </td>
                                <td style={{ textAlign: "center" }}>{e.documentos.planEmergencia?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                  e.documentos.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                    abrirArchivo(e.documentos.planEmergencia?.ruta)
                                  }} /> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                                </td>
                              </>
                            :
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            }
                            <td>{e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva"}</td>
                            <td>{e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE"}</td>
                            <td>{e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No"}</td>
                          </tr>
                          {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? null :
                            <tr>
                              <td colSpan="16">
                                <Collapse in={this.state.expandir}>
                                  <table className="subTable">
                                    <tbody>
                                      {e.centrosTrabajo.map((c,j) => {
                                        return(
                                          <tr key={j}>
                                            <td style={{ width: '10px'}}>{j+1}</td>
                                            <td colSpan={3} style={{ width: '120px'}}>{c.nombre}</td>
                                            <td style={{ textAlign: "center", width:'5%' }}>{c.documentos.hojaVisita?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                              c.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                                abrirArchivo(c.documentos.hojaVisita?.ruta)
                                              }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                                            </td>
                                            {/* <td style={{ textAlign: "center" }}>{c.documentos.prevencionRiesgos?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                              c.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                                abrirArchivo(c.documentos.prevencionRiesgos?.ruta)
                                              }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                                            </td> */}
                                            <td style={{ textAlign: "center" }}>{c.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                              c.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                                abrirArchivo(c.documentos.evaluacionRiesgos?.ruta)
                                              }} /> : <span style={{ padding: '7px'}} className="tableIcon">-</span> /* <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" /> */}
                                            </td>
                                            <td style={{ textAlign: "center" }}>{c.documentos.planEmergencia?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                                              c.documentos.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                                                abrirArchivo(c.documentos.planEmergencia?.ruta)
                                              }} /> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        )
                                      })}

                                    </tbody>
                                  </table>
                                </Collapse>
                              </td>

                            </tr>

                          }
                        </>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Comunidades);

