import React from "react";
import { firestore } from "../../../providers/firebase";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { Grid } from "@material-ui/core";
import firebase from "firebase";

class NuevoAAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      nif: "",
      razonSocial: "",
      municipio: "",
      email: "",
      telefono: "",
      provincia: "",
      contacto: "",
      nifComercial: "",
      comerciales: []
    }
  }

  componentDidMount(){
    firestore.collection("fincas").where("fechaReclamacion", "!=", null).get().then(fincs => {
      fincs.docs.forEach(f => {
        console.log("dsfvb")
        console.log(f.data())
      })
    })
    // firestore.collection("fincas").get().then( fincas => {
    //   console.log("AAAAA")
    //   fincas.docs.forEach( finca => {
    //     console.log(finca.data().nif, finca.data().fechaRenovacion)
    //   //   if(finca.data().fechaRenovacion !== undefined){
    //   //     let d = new Date(finca.data().fechaRenovacion.seconds*1000)
    //   //     let diasdif= new Date() - d;
    //   //     let contdias = Math.round(diasdif/(1000*60*60*24));
    //   //     console.log(finca.data().nif, contdias)
    //   //     if(contdias > 395){
    //   //       console.log(finca.data())
    //   //       // return admin.firestore().collection("fincas").doc(finca.data().nif).update({
    //   //       //   renovada: false
    //   //       // })
    //   //     }
    //   //   }
    //   })
    // }).catch(err => console.error(err))
    firestore.collection("comerciales").get().then( snapshot => {
      let comerciales = []
      console.log(snapshot)
      snapshot.docs.forEach( comercial => {
        comerciales.push(comercial.data())
      })
      this.setState({comerciales: comerciales})
    })
  }

  crearAAFF = (e) => {
    e.preventDefault();
    firestore.collection("aaff").doc(this.state.nif).get().then(snapshot => {
      if (snapshot.exists) {
        toast.error("Ya existe un Administrador de Fincas registrado con el NIF introducido")
      } else {
        firestore.collection("aaff").doc(this.state.nif?.toUpperCase()).set({ 
          nif: this.state.nif?.toUpperCase(), 
          razonSocial: this.state.razonSocial?.toUpperCase(), 
          municipio: this.state.municipio?.toUpperCase(), 
          email: this.state.email, 
          nifComercial: this.state.nifComercial?.toUpperCase(),
          telefono: this.state.telefono,
          contacto: this.state.contacto,
          provincia: this.state.provincia?.toUpperCase(), 
          nifTecnico: "", 
          nifConsultor: "",
        }).then(() => {
          toast.success("Administrador de Fincas creado correctamente")
          window.location.replace("/aaff/"+this.state.nif.toUpperCase()+"/comunidades")
        }).catch((err) => {
          toast.error("Ha ocurrido un error al crear el Administrador de Fincas")
          console.log(err)
        })
      }
    })

  }

  render() {
    return <>
    {console.log(this.state.comerciales)}
      <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Administradores de Fincas / <b>Nuevo</b></div>
      <div className="pageContent">
        <div className="titleContent">Crear nuevo Administrador de Fincas</div>
        <form className="nuevoAAFFForm" onSubmit={this.crearAAFF}>
          <Grid container spacing={5} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="NIF" pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)" title="Introduce un NIF válido (CIF o DNI con letra mayúscula)" value={this.state.nif} onChange={e => this.setState({ nif: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Razón social" value={this.state.razonSocial} onChange={e => this.setState({ razonSocial: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="email" required placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Telefono" value={this.state.telefono} onChange={e => this.setState({ telefono: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Municipio" value={this.state.municipio} onChange={e => this.setState({ municipio: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Provincia" value={this.state.provincia} onChange={e => this.setState({ provincia: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Persona de contacto" value={this.state.contacto} onChange={e => this.setState({ contacto: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><select onChange={e => this.setState({nifComercial: e.target.value})}><option value="">Comercial</option>{this.state.comerciales.map((e, i) => {return ( <option value={e.nif}>{e.nombre+" "+e.apellidos}</option> )})}</select></Grid>
          </Grid>
          <input type="submit" value="Guardar y publicar"></input>
        </form>
      </div>
    </>
  }
}

export default NuevoAAFF;
