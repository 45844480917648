import React from "react";
import { Link } from "react-router-dom";
import { firestore, storage } from "../../../providers/firebase";
import firebase from "firebase";
import Add from "../../../icons/add.svg"
import Descargar from "../../../icons/descargar.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { excelToJson } from "../../../providers/excel-to-json";
import { toast } from "react-toastify";
import { LinearProgress, withStyles, Box } from "@material-ui/core";
import Warning from "../../../icons/warning.svg"
//import Editar from "../../../icons/editar.svg"
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2'
import Tick from "../../../icons/tick.svg"
import Button from '@material-ui/core/Button';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {props.label}
      </Box>
    </Box>
  );
}

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: [],
      tecnicos: {},
      consultores: {},
      docsPendientes: [],
      proveedorDatos: [],
      provsnif: [],
      isLoading: true
    }
  }
  componentDidMount() {
    this.loadPageContent()
    this.loadTecnicosConsultoresComerciales()
  }

  loadPageContent = () => {
    let proveedores = {}
    let promises = []
    let provnif = []
    promises.push(firestore.collection("proveedores").get().then(querysnapshot => {
      querysnapshot.docs.forEach(proveedor => {
        proveedores = { ...proveedores, [proveedor.data().nif]: proveedor.data() }
        provnif.push(proveedor.data().nif)
      })
    
    }))
    Promise.all(promises).then(() => {
      this.setState({proveedorDatos:proveedores})
      this.setState({provsnif:provnif})
    })
    firestore.collection("aaff").orderBy("razonSocial").get().then(snapshot => {
      let promises = []
      let promises1 = []
      let aaffs = []
      snapshot.docs.forEach(aaff => {
        let aaffData = []
        let fincasVisitadas = 0
        let fincasVisitadasLAE = 0
        let fincasVisitadasOtros = 0
        let fincasRenovadas = 0
        let fincasRenovadasVisitadas = 0
        let fincasHV = []
        firestore.collection('hojasVisita').where("aaff.nif", '==', aaff.data().nif).get().then( a => {
          //fincasVisitadas = a.size
          a.docs.forEach(s => {
            fincasHV.push(s.data().comunidad.nif)
          })
        })
        promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaff.data().nif).get().then(fincas => {
          aaffData = aaff.data()
          //aaffData.nFincas = fincas.size
          let proveedores = []
          let fincasActivas = []
          //let fincasVisitadas = 0
          let nfincas = 0
          let nFincasTotales = 0
          let nCT = 0
          let ctRenovado = 0
          //console.log(fincasHV)
          fincas.docs.forEach(finca => {
            /* if(finca.data().centrosTrabajo?.length !== undefined){
              nCT = nCT + finca.data().centrosTrabajo?.length
              finca.data().centrosTrabajo.forEach(ct => {
                if(ct.renovada){
                  ctRenovado++
                }
              })
            } */
            /* if(finca.data().documentos?.hojaVisita?.validado  && finca.data().activo !== false && finca.data().activo !== "false" && finca.data().renovada === undefined && finca.data().renovadaVisitada === undefined){
                if(fincasHV.includes(finca.data().nif)){
                  fincasVisitadasLAE++
                }else{
                  fincasVisitadasOtros++
                }
            } */
            /* if(finca.data().renovada === true || finca.data().renovadaVisitada === true){
              console.log(aaff.data().nif, finca.data().nif, finca.data().renovada, finca.data().renovadaVisitada)
            } */
            if((finca.data().renovada === true || finca.data().renovada === 'true') && (finca.data().activo === undefined || finca.data().activo === true || finca.data().activo === "true")){
              //console.log("entra en renovadas")
              fincasRenovadas++
            }
            if((finca.data().renovadaVisitada === true || finca.data().renovadaVisitada === 'true') && (finca.data().activo === undefined || finca.data().activo === true || finca.data().activo === "true")){
              //console.log("entra en renovadasVisitadas")
              fincasRenovadasVisitadas++
            }
            if(finca.data().activo === undefined || finca.data().activo === true || finca.data().activo === "true"){
              nfincas++
              fincasActivas = fincasActivas.concat(finca.data().nif)
            }
            /* if((finca.data().activo === undefined || finca.data().activo === true || finca.data().activo === "true") && finca.data().renovada === undefined && finca.data().renovadaVisitada === undefined){
              nFincasTotales++
            } */
            proveedores = proveedores.concat(finca.data().nifProveedores)
            if(finca.data().documentos?.hojaVisita?.validado && finca.data().activo !== "false"){
              fincasVisitadas++
            }
          })
          proveedores = [...new Set(proveedores)]
          fincasActivas = [...new Set(fincasActivas)]
          
          /* for(let i=0;i<1;i++){
            firestore.collection("hojasVisita").where("c.nif", "==", aaff.data().nif).get().then(hv => {
              //aaffData.fincasVisitadas = hv.size
              console.log(hv.size)
            })
          } */
          
          //console.log(fincasVisitadasLAE, fincasVisitadasOtros, fincasVisitadas, nfincas)
          //console.log(fincasActivas)
          let nprof = 0
          for(let i=0; i< proveedores.length; i++){
            for(let j=0; j< this.state.provsnif.length; j++){
              if(this.state.provsnif[j]?.includes(proveedores[i])){
                let e = this.state.proveedorDatos[this.state.provsnif[j]]
                let dr = e.documentos?.declaracionResponsable
                const hayDrPendiente = Object.values(dr).some(objeto => objeto.pendiente === true);
                if(e.documentos !== 'undefined' && (e.documentos?.certificadoSS?.pendiente || hayDrPendiente || e.documentos?.evaluacionRiesgos?.pendiente || e.documentos?.justificante?.pendiente
                  || e.documentos?.lopd?.pendiente || e.documentos?.planEmergencia?.pendiente || e.documentos?.planPrevencion?.pendiente || e.documentos?.planificacionPreventiva?.pendiente 
                  || e.documentos?.seguro?.pendiente || e.documentos?.vigilancia?.pendiente)){
                    nprof++;
                }
              } 
            }
          }
          aaffData.nFincas = nfincas
          aaffData.nFincasTotales = nFincasTotales
          aaffData.proveedores = proveedores
          aaffData.nProveedores = proveedores.length
          aaffData.fincasVisitadas = fincasVisitadas
          /* aaffData.fincasVisitadasLAE = fincasVisitadasLAE
          aaffData.fincasVisitadasOtros = fincasVisitadasOtros */
          aaffData.fincasRenovadas = fincasRenovadas
          aaffData.fincasRenovadasVisitadas = fincasRenovadasVisitadas
          aaffData.nCT = nCT
          aaffData.ctRenovado = ctRenovado
          aaffData.docsPendientes = nprof
          aaffs.push(aaffData)
        }))
        
      })
      
      
      Promise.all(promises).then(() => {
        setTimeout(() => {
          this.setState({ aaff: aaffs, isLoading: false })
        }, 1000);
          
        
        
        
      })
    })
  }
  
  
  loadTecnicosConsultoresComerciales = () => {
    let tecnicos = {}
    let consultores = {}
    let comerciales = {}
    let promises = []
    promises.push(firestore.collection("tecnicos").get().then(snapshot => {
      snapshot.docs.forEach(tecnico => {
        tecnicos = { ...tecnicos, [tecnico.data().nif]: tecnico.data() }
      })
    }))
    promises.push(firestore.collection("consultores").get().then(snapshot => {
      snapshot.docs.forEach(consultor => {
        consultores = { ...consultores, [consultor.data().nif]: consultor.data() }
      })
    }))
    promises.push(firestore.collection("comerciales").get().then(snapshot => {
      snapshot.docs.forEach(comercial => {
        comerciales = { ...comerciales, [comercial.data().nif]: comercial.data() }
      })
    }))
    Promise.all(promises).then(() => {
      this.setState({ tecnicos: tecnicos, consultores: consultores, comerciales: comerciales })
    })
  }
  subirListado = (res) => {
    toast.info("Subiendo AAFFs...")
    let promises = []
    let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
    res.forEach(json => {
      if (regexp.test(json.nif)) {
        promises.push(firestore.collection("aaff").doc(json.nif?.toUpperCase()).set({
          nif: json.nif?.toUpperCase(),
          razonSocial: json.razonSocial?.toUpperCase() || "",
          email: json.email,
          municipio: json.municipio?.toUpperCase() || "",
          nifConsultor: "",
          nifTecnico: ""
        }))
      } else {
        toast.error("NIF incorrecto: " + json.nif)
      }
    });
    Promise.all(promises).then(resp => {
      toast.info("Listado subido con éxito")
      this.loadPageContent()
    }).catch(err => {
      toast.error("Error al cargar la lista")
      console.log(err)
    })
  }

  descargar = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  ordenarVisitas = () => {
    let aaff = this.state.aaff
    aaff.sort((a, b) => {
      if (a.nFincas === 0) {
        return 1; //Sin fincas al final
      }
      if (b.nFincas === 0) {
        return -1; //Sin fincas al final
      }

      if (a.fincasVisitadas / a.nFincas > b.fincasVisitadas / b.nFincas) {
        return 1;
      }
      if (a.fincasVisitadas / a.nFincas < b.fincasVisitadas / b.nFincas) {
        return -1;
      }
      return 0;
    })
    //console.log(aaff)
    this.setState({ aaff: aaff })
  }
  cambiarFechaEncargo = (f) => {
    Swal.fire({
      title: 'Cambiar fecha encargo',
      html: `<input type="date" id="fecha" class="swal2-input">`,
      confirmButtonText: 'Cambiar',
      focusConfirm: false,
      preConfirm: () => {
        const fecha = Swal.getPopup().querySelector('#fecha').value
        if (!fecha) {
          Swal.showValidationMessage(`insertar fecha`)
        }
        return {fecha: fecha }
      }
    }).then((result) => {
      if(result.value !== undefined){
        Swal.fire({
          icon: 'success',
          title: 'Fecha cambiada'
        })
        firestore.collection("aaff").doc(f).update({ 
          fechaEncargo: new Date(result.value.fecha)
        })
      }
    })
    
  }
  renovarComunidades = (nifAaff, nombre) => {
    let fincas = []
    let centrosTrabajo = []
    let promises = []
    console.log("todas las fincas renovadas de "+ nifAaff)
    promises.push(firestore.collection('fincas').where('nifAAFF', '==', nifAaff).orderBy('razonSocial').get().then(a => {
      a.docs.forEach((f, i)=>{
        fincas.push(f.data())
        if(f.data().centrosTrabajo !== undefined){
          for(let x=0; x<f.data().centrosTrabajo.length; x++){
            centrosTrabajo.push(f.data()?.centrosTrabajo[x].nif)
          }
        }
      })
    }))
    Promise.all(promises).then(() => {
      //comprobamos si la finca tiene centros de trabajo. Si tiene lo comparamos con el array de nifs de centros de trabajo y si existe lo eliminamos y lo creamos de nuevo con los campos renovada y renovadaVisitada 
      
      Swal.fire({
        title: nombre,
        text: "¿Quieres renovar todas las comunidades y centros de trabajo de este aaff?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Renovar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let ctNif = centrosTrabajo
          fincas.forEach(f => {
            firestore.doc(`fincas/${f.nif}`).set({
              renovada:true,
              renovadaVisitada: false,
              fechaRenovacion: new Date()
            }, { merge: true }).then(() => {
              toast.success("Finca "+ f.nif + " renovada")
            }).catch(err => {
              toast.error("Ha ocurrido un error " + err)
            })
            if(f.centrosTrabajo !== undefined){
              let ct = f.centrosTrabajo
              let cLength = f.centrosTrabajo.length
              firestore.collection('fincas').doc(f.nif).set({
                centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...f.centrosTrabajo)
              }, { merge: true })
              for(let x=0; x<cLength; x++){
                if(ctNif.includes(ct[x].nif)){
                  console.log({...ct[x], renovada:true, renovadaVisitada:false})
                  firestore.doc(`fincas/${f.nif}`).set({
                    centrosTrabajo:
                    firebase.firestore.FieldValue.arrayUnion({
                      documentos: ct[x].documentos,
                      nombre: ct[x].nombre,
                      nif: ct[x].nif,
                      renovada: true,
                      renovadaVisitada: false
                    })
                  }, { merge: true }).then(() => {
                    toast.success("centro de trabajo "+ ct[x].nif + " renovado")
                  }).catch(err => {
                    toast.error("Ha ocurrido un error " + err)
                  })
                }
              }
            }
          })
        }
      })
    })
  }

  reasignar = (a, b) => {
    let pers = ''
    let quitar = {}
    switch(b){
      case 'tecnico':
        pers = 'técnico'
        quitar = {nifTecnico: ''}
      break;
      case 'consultor':
        pers = 'consultor'
        quitar = {nifConsultor: ''}
      break;
      case 'comercial':
        pers = 'comercial'
        quitar = {nifComercial: ''}
      break;
    }
    Swal.fire({
      title: '¿Cambiar '+ pers +'?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      denyButtonText: `Desasignar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.replace("/aaff/" + a.nif + "/"+ b)
      } else if (result.isDenied) {
        firestore.collection("aaff").doc(a.nif).update(
          quitar
        ).then(() => {
          toast.success(pers +" desasignado")
    
        }).catch(err => {
          console.log(err)
          toast.error("Error al desasignar el "+ pers)
        })
      }
    })
  }

  componentWillUnmount() {
    //this._isMounted = false;
  }
  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})` }}>Administradores de Fincas</div>
        <div className="pageContent tableContainer">
          <div className="contentBar">
            <div>
              <label htmlFor="listadoAAFF"><div className="listadoBtn" style={{ marginLeft: 0, marginTop: 5 }}>Subir listado de AAFF</div></label><input type="file" id="listadoAAFF" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={doc => { excelToJson(doc.target.files[0], (res) => this.subirListado(res)) }} className="inputfile" />
              <div className="descargarBtn" onClick={() => this.descargar("PlantillasExcel/plantillaAAFF.xlsx")}><img src={Descargar} alt="Descargar" />Descargar plantilla de AAFF</div>
            </div>
            <Link to="/aaff/nuevo"><div className="contentButton"><img src={Add} alt="Más" />Nuevo Administrador</div></Link>
          </div>
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Administrador</th>
                  <th>NIF</th>
                  <th>Fecha Encargo</th>
                  <th>Provincia</th>
                  <th>Municipio</th>
                  <th>Consultor</th>
                  <th>Comercial</th>
                  <th>Técnico</th>
                  {/* <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarVisitas}>Comunidades Visitadas Otros</th>
                  <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarVisitas}>Comunidades Visitadas LAE</th>
                  <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarVisitas}>Comunidades Renovadas</th> */}
                  <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarVisitas}>Comunidades Visitadas</th>
                  <th style={{ textAlign: "center" }}>Proveedores</th>
                  <th style={{ textAlign: "center" }}></th>
                  <th style={{ textAlign: "center" }}>Renovar comunidades</th>
                  {/* <th style={{ textAlign: "center" }}>Proveedores</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? <tr><td colSpan='15' style={{textAlign:'center'}}><CircularProgress style={{color:'#fc7271'}} /> </td></tr> : <></>}
                {this.state.aaff.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.razonSocial}</Link></td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.nif}</Link></td>
                      <td  style={{cursor:'pointer'}} onClick={() => this.cambiarFechaEncargo(e.nif)}>
                        {e.fechaEncargo === undefined ? 
                          '-' 
                        : 
                          new Date(e.fechaEncargo.seconds*1000).toLocaleDateString()
                        }
                      </td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.provincia}</Link></td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.municipio}</Link></td>
                      {e.nifConsultor === "" || e.nifConsultor === undefined ? 
                        <td><Link to={"/aaff/" + e.nif + "/consultor"}><div className="asignarButton"><img src={Add} alt="Más" />Asignar</div></Link></td>
                      :
                      <td onClick={() => this.reasignar(e, 'consultor')} style={{cursor:"pointer"}}>{this.state.consultores[e.nifConsultor]?.nombre + " " + this.state.consultores[e.nifConsultor]?.apellidos}</td>
                      }

                      {e.nifComercial === "" || e.nifComercial === undefined ? 
                        <td><Link to={"/aaff/" + e.nif + "/comercial"}><div className="asignarButton"><img src={Add} alt="Más" />Asignar</div></Link></td>
                      :
                        <td onClick={() => this.reasignar(e, 'comercial')} style={{cursor:"pointer"}}>{this.state.comerciales[e.nifComercial]?.nombre + " " + this.state.comerciales[e.nifComercial]?.apellidos}</td>
                      }
                      
                        
                      {e.nifTecnico === "" || e.nifTecnico === undefined ? 
                        <td><Link to={"/aaff/" + e.nif + "/tecnico"}><div className="asignarButton"><img src={Add} alt="Más" />Asignar</div></Link></td>
                      : 
                        <td onClick={() => this.reasignar(e, 'tecnico')} style={{cursor:"pointer"}}>{this.state.tecnicos[e.nifTecnico]?.nombre + " " + this.state.tecnicos[e.nifTecnico]?.apellidos}</td>
                      }
                        
                      
                      {/* <td style={{ textAlign: "center" }}><Link to={"/aaff/" + e.nif + "/comunidades"}><LinearProgressWithLabel value={e.fincasVisitadasOtros / (e.nFincasTotales || 1) * 100} label={e.fincasVisitadasOtros + '/' + e.nFincasTotales} variant="determinate" /></Link></td>
                      <td style={{ textAlign: "center" }}><Link to={"/aaff/" + e.nif + "/comunidades"}><LinearProgressWithLabel value={e.fincasVisitadasLAE / (e.nFincasTotales || 1) * 100} label={e.fincasVisitadasLAE + '/' + e.nFincasTotales} variant="determinate" /></Link></td>
                      <td style={{ textAlign: "center" }}><Link to={"/aaff/" + e.nif + "/comunidades"}><LinearProgressWithLabel value={e.fincasRenovadasVisitadas / (e.fincasRenovadas || 1) * 100} label={e.fincasRenovadasVisitadas + '/' + e.fincasRenovadas} variant="determinate" /></Link></td> */}
                      <td style={{ textAlign: "center" }}><Link to={"/aaff/" + e.nif + "/comunidades"}><LinearProgressWithLabel value={e.fincasVisitadas / (e.nFincas || 1) * 100} label={e.fincasVisitadas + '/' + e.nFincas} variant="determinate" /></Link></td>
                      <td style={{ textAlign: "center" }}><Link to={`/aaff/${e.nif}/proveedores`}>{e.nProveedores}</Link></td>
                      {/* <td>{this.state.docsPendientes[i]?.docsPendientesValidacion>0 ? <img src={Warning} alt="" width="15px" style={{ marginLeft: 10 }}/> : ""}</td> */}
                      <td>{e.docsPendientes > 0 ? <img src={Warning} alt="" width="15px" style={{ marginLeft: 10 }}/> : ""}</td>
                      <td style={{ cursor:"pointer", textAlign: "center" }}>{e.fincasRenovadas === e.nFincas && e.nCT === e.ctRenovado && e.nFincas > 0 ? <img src={Tick} width="30px" alt="todas renovadas" /> : <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => this.renovarComunidades(e.nif, e.razonSocial)}>Renovar</Button>}</td>
                      {/* <td style={{ textAlign: "center" }}><Link to={`/aaff/${e.nif}/proveedores`}><img src={Lupa} alt="Proveedores" className="tableIcon"></img></Link></td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default AAFF;