
import React from 'react'
/* import * as FileSaver from "file-saver"; */
import * as XLSX from "xlsx";
import Excel from "../icons/excel.svg"
export const ExportToExcel = ({ apiData, fileName }) => {
  /* const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"; */
  const fileExtension = ".xlsx";

  const exportar = (columnas, fileName) => {
    const newData = columnas.map(row=>{
      delete row.tableData
      delete row.__position
      delete row.path
      delete row._highlightResult
      delete row.objectID
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Listado")
    let buf = XLSX.write(workBook, {bookType:"xlsx", type:"buffer"})
    XLSX.write(workBook, {bookType:"xlsx", type:"binary"})
    XLSX.writeFile(workBook, fileName + fileExtension)
  };

  return (
    <button className='bigBtn' onClick={(e) => exportar(apiData, fileName)}><img src={Excel} alt="exportar listado" width="30px"/> Exportar listado</button>
  );
};