import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"
import "firebase/functions"

firebase.initializeApp({
  apiKey: "AIzaSyCCqHNZijcpSYzQfapaflyd-5vdElW8B-w",
  authDomain: "fincas-cae.firebaseapp.com",
  databaseURL: "https://fincas-cae.firebaseio.com",
  projectId: "fincas-cae",
  storageBucket: "fincas-cae.appspot.com",
  messagingSenderId: "453941303726",
  appId: "1:453941303726:web:198c53cabbbf943bb2103f",
});

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const functions = firebase.app().functions('europe-west1')