import React, {useState, Component} from "react";
import { firestore } from "../../../providers/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";


class BuscadorFincas extends Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      finca:{}
    }
  }
  componentDidMount() {

      let fincas = [];
      this.props.fincas.forEach(e => {
        const data = {
          razonSocial:e.razonSocial,
          nif: e.nif,
          direccion: e.direccion,
          codigoPostal: e.codigoPostal,
          municipio: e.municipio,
          provincia: e.provincia,
          activo: e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva",
          servicios: e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE",
          empleados: e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No"
        }
        fincas.push(data)
        this.setState({fincas:fincas})
      })
  }
  render(){   
    
    return (
      <>
      {this.state.fincas.length === this.props.fincas.length ?
        <ExportToExcel apiData={this.state.fincas} fileName={"Listado de fincas "+this.props.aaffNif} />
      :
        <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
      }
      </>
    );
  }
  
}


export default BuscadorFincas;
