import { firestore, storage, auth } from "./firebase";
import { toast } from "react-toastify";

import firebase from "firebase";
//import firebase from "firebase";



export const subirDocumentacionProveedor = async (nombreDoc, proveedor, callback, fechaCaducidad) => {

    /* 

      PARA USAR LA FUNCIÓN RECUERDA INCLUIR EN EL DOCUMENTO EL INPUT DE UPLOAD:
      <input type="file" id="fileElem" hidden></input>

    */

    var el = document.getElementById("fileElem");
    el.click()
    const idToken = await auth.currentUser.getIdTokenResult()
    const rol = idToken.claims.rol
    el.onchange = () => {
      if (el.files[0] !== undefined) {
        const toastInfo = toast.info("Guardando archivo",{autoClose:false})
        storage.ref(`${proveedor.nif}/${nombreDoc}.${el.files[0]["name"].split('.').pop()}`).put(el.files[0]).then(snapshot => {
          firestore.doc(`proveedores/${proveedor.nif}`).update({
            documentos: {
              ...proveedor.documentos,
              [nombreDoc]: {
                validado: rol === "admin" || rol === "tec" ? true : false,
                pendiente:  rol !== "admin" && rol !== "tec" ? true : false,
                ruta: snapshot.ref.fullPath,
                fechaCaducidad: fechaCaducidad ? new Date(fechaCaducidad) : new Date(0),
                avisado: fechaCaducidad ? false : true,
                segundoAviso: fechaCaducidad ? false : true,
              }
            }
          }).then(() => {
            toast.update(toastInfo, {
              render: "El archivo se ha subido correctamente",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
            callback()
          })
        }).catch(error => {
          console.log(error)
          toast.error("Ha ocurrido un error al subir el archivo")
        })
      }
    }
}

export const subirDocumentacionCentroTrabajo = (nombreDoc, finca, nifCentroTrabajo, callback) => {

  /* 

    PARA USAR LA FUNCIÓN RECUERDA INCLUIR EN EL DOCUMENTO EL INPUT DE UPLOAD:
    <input type="file" id="fileElem" hidden></input>

  */
  let toRemove = []
  finca.centrosTrabajo.forEach((f,i) => {
    if(f.nif === nifCentroTrabajo){
      toRemove.push(f)
      console.log(i)
      console.log(f.nif)
      console.log(f.documentos[nombreDoc])
      const ctSeleccionado = {nif: f.nif, nombre: f.nombre, documentos: f.documentos}
      const documentos = f.documentos
      const nifCt = f.nif
      const nombreCt = f.nombre
      console.log(ctSeleccionado)
      
      var el = document.getElementById("fileElem");
      el.click()
      el.onchange = () => {
        if (el.files[0] !== undefined) {
          firestore.collection('fincas').doc(finca.nif).set({
            centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
          }, { merge: true })
          
          storage.ref(`${finca.nif}/${f.nif}/${nombreDoc}.${el.files[0]["name"].split('.')[1]}`).put(el.files[0]).then(snapshot => {
            const docAct = {...documentos, [nombreDoc]: {validado: true, pendiente: false, ruta: snapshot.ref.fullPath}}
            
            console.log(docAct)
            firestore.doc(`fincas/${finca.nif}`).set({
              centrosTrabajo:
                firebase.firestore.FieldValue.arrayUnion({
                  nombre: nombreCt,
                  nif: nifCt,
                  documentos: docAct
                })
            }, { merge: true }).then(() => {
              toast.success("El archivo se ha subido correctamente")
              callback()
            })
          }).catch(error => {
            console.log(error)
            toast.error("Ha ocurrido un error al subir el archivo")
          })
        }
      }
    }
  })
  
}

export const subirDocumentacionComunidad = (nombreDoc, finca, callback) => {

  /* 

    PARA USAR LA FUNCIÓN RECUERDA INCLUIR EN EL DOCUMENTO EL INPUT DE UPLOAD:
    <input type="file" id="fileElem" hidden></input>

  */

  var el = document.getElementById("fileElem");
  el.click()
  el.onchange = () => {
    if (el.files[0] !== undefined) {
      storage.ref(`${finca.nif}/${nombreDoc}.${el.files[0]["name"].split('.')[1]}`).put(el.files[0]).then(snapshot => {
        if(nombreDoc === 'lopd'){
          firestore.doc(`fincas/${finca.nif}`).set({
            documentos: {
              [nombreDoc]: {
                validado: true,
                pendiente: false,
                ruta: snapshot.ref.fullPath,
                fechaSubida: new Date()
              }
            }
          }, { merge: true }).then(() => {
            toast.success("El archivo se ha subido correctamente")
            callback()
          })
        }else{
          firestore.doc(`fincas/${finca.nif}`).set({
            documentos: {
              [nombreDoc]: {
                validado: true,
                pendiente: false,
                ruta: snapshot.ref.fullPath
              }
            }
          }, { merge: true }).then(() => {
            toast.success("El archivo se ha subido correctamente")
            callback()
          })
        }
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al subir el archivo")
      })
    }
  }
}

export const abrirArchivo = (ruta) => {
  console.log("ruta del archivo "+ruta)
  if (!ruta) {
    toast.error("No existe el archivo")
  } else {
    try {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      })
    } catch (error) {
      console.log(error)
      toast.error("Ha ocurrido un error al descargar el archivo")
    }
  }
}

export const abrirPdf = (nif, ruta) => {
  // let urlText = `/comunidades/${nif}/PlanEmergencia`
  let urlText = ''
  switch(ruta){
    case `${nif}/evaluacion-riesgos.pdf` :
      urlText = `/comunidades/${nif}/evaluacion-riesgos`
    break;
    case `${nif}/hoja-visita.pdf`:
      urlText = `/comunidades/${nif}/hoja-visita`
    break;
    case `${nif}/hoja-visita_2023.pdf`:
      urlText = `/comunidades/${nif}/hoja-visita`
    break;
    case `${nif}/hoja-visita_2024.pdf`:
      urlText = `/comunidades/${nif}/hoja-visita`
    break;
    case `${nif}/plan-emergencia.pdf`:
      urlText = `/comunidades/${nif}/PlanEmergencia`
    break;
  }
  window.open(urlText, "_blank")
}

export const abrirPdfCt = (nif, nifCt, ruta) => {
  // let urlText = `/comunidades/${nif}/${nifCt}/PlanEmergencia`
  let urlText = ''
  switch(ruta){
    case `${nif}/${nifCt}/evaluacion-riesgos.pdf` :
      urlText = `/comunidades/${nif}/${nifCt}/evaluacion-riesgos`
    break;
    case `${nif}/${nifCt}/hoja-visita.pdf`:
      urlText = `/comunidades/${nif}/${nifCt}/hoja-visita`
    break;
    case `${nif}/${nifCt}/hoja-visita_2023.pdf`:
      urlText = `/comunidades/${nif}/${nifCt}/hoja-visita`
    break;
    case `${nif}/${nifCt}/hoja-visita_2024.pdf`:
      urlText = `/comunidades/${nif}/${nifCt}/hoja-visita`
    break;
    case `${nif}/${nifCt}/plan-emergencia.pdf`:
      urlText = `/comunidades/${nif}/${nifCt}/PlanEmergencia`
    break;
  }
  window.open(urlText, "_blank")
}

export const borrarArchivo = (ruta) => {
  // Por hacer
}

export const subirDeclaracionResponsable = async (nifAAFF, proveedor, callback) => {

  /* 

    PARA USAR LA FUNCIÓN RECUERDA INCLUIR EN EL DOCUMENTO EL INPUT DE UPLOAD:
    <input type="file" id="fileElem" hidden></input>

  */

  var el = document.getElementById("fileElem");
  el.click()
  const idToken = await auth.currentUser.getIdTokenResult()
  const rol = idToken.claims.rol
  el.onchange = () => {
    if (el.files[0] !== undefined) {
      storage.ref(`${proveedor.nif}/declResp_${nifAAFF}.${el.files[0]["name"].split('.').pop()}`).put(el.files[0]).then(snapshot => {
        firestore.doc(`proveedores/${proveedor.nif}`).set({
          documentos: {
            declaracionResponsable: {
              [nifAAFF]: {
                validado: rol === "admin" ? true : false,
                pendiente:  rol !== "admin" ? true : false,
                ruta: snapshot.ref.fullPath,
              }
            }
          }
        }, {merge: true}).then(() => {
          toast.success("El archivo se ha subido correctamente")
          callback()
        })
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al subir el archivo")
      })
    }
  }
}