import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify";
import { firestore } from "../../../providers/firebase";
import { Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
}));

export default function TransitionsModal(props) {
  var d = new Date(props.aaff.fechaEncargo?.seconds*1000)
  var mm = d.getMonth() + 1 <10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1;
  var dd = d.getDate() <10 ? '0'+d.getDate():d.getDate();
  var yy = d.getFullYear();
  let fechaEncargo = yy+"-"+mm+"-"+dd

  var d1 = new Date(props.aaff.fechaBienvenida?.seconds*1000)
  var mm1 = d1.getMonth() + 1 <10 ? '0'+(d1.getMonth() + 1) : d1.getMonth() + 1;
  var dd1 = d1.getDate() <10 ? '0'+d1.getDate():d1.getDate();
  var yy1 = d1.getFullYear();
  let fechaBienvenida = yy1+"-"+mm1+"-"+dd1

  var d2 = new Date(props.aaff.fechaReclamacion?.seconds*1000)
  var mm2 = d2.getMonth() + 1 <10 ? '0'+(d2.getMonth() + 1) : d2.getMonth() + 1;
  var dd2 = d2.getDate() <10 ? '0'+d2.getDate():d2.getDate();
  var yy2 = d2.getFullYear();
  let fechaReclamacion = yy2+"-"+mm2+"-"+dd2
  
  var d3 = new Date(props.aaff.fechaInforme?.seconds*1000)
  var mm3 = d3.getMonth() + 1 <10 ? '0'+(d3.getMonth() + 1) : d3.getMonth() + 1;
  var dd3 = d3.getDate() <10 ? '0'+d3.getDate():d3.getDate();
  var yy3 = d3.getFullYear();
  let fechaInforme = yy3+"-"+mm3+"-"+dd3
  
  const classes = useStyles();

  const [editarDatos, setEditarDatos] = useState({});
  
  useEffect(()=>{
    const aaffData = {
      razonSocial: props.aaff.razonSocial,
      email: props.aaff.email,
      contacto: props.aaff.contacto,
      telefono: props.aaff.telefono,
      municipio: props.aaff.municipio,
      provincia: props.aaff.provincia,
      fechaEncargo: fechaEncargo,
      fechaBienvenida: fechaBienvenida,
      fechaReclamacion: fechaReclamacion,
      fechaInforme: fechaInforme,
      comentarios: props.aaff.comentarios
    }
    setEditarDatos(aaffData)
  },[props])

  function handleChange(evt) {
    const value = evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value
    });
  }

  const editar = (aaff) => {
    if(aaff.fechaEncargo !== "NaN-NaN-NaN"){ d = new Date(aaff.fechaEncargo)}else{ d = ''}
    if(aaff.fechaBienvenida !== "NaN-NaN-NaN"){ d1 = new Date(aaff.fechaBienvenida)}else{ d1 = ''}
    if(aaff.fechaReclamacion !== "NaN-NaN-NaN"){ d2 = new Date(aaff.fechaReclamacion)}else{ d2 = ''}
    if(aaff.fechaInforme !== "NaN-NaN-NaN"){ d3 = new Date(aaff.fechaInforme)}else{ d3 = ''}
    let c= "";
    if(aaff.comentarios === undefined){c= "-"}else{ c= aaff.comentarios}
    let cont = "";
    if(aaff.contacto === undefined){cont= "-"}else{ cont= aaff.contacto}
    let tel = "";
    if(aaff.telefono === undefined){tel= "-"}else{ tel= aaff.telefono}
    //console.log(aaff)
    firestore.collection("aaff").doc(props.aaff.nif).update({
      razonSocial: aaff.razonSocial,
      email: aaff.email,
      contacto: cont,
      telefono: tel,
      municipio: aaff.municipio,
      provincia: aaff.provincia,
      fechaEncargo: d,
      fechaBienvenida: d1,
      fechaReclamacion: d2,
      fechaInforme: d3,
      comentarios: c
      
    }).then(() => {
      toast.success("Datos editados con éxito")
      props.handleClose()
    }).catch(err => {
      console.log(err)
      toast.error("Error al editar los datos")
    })
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.opened}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div className={classes.form}>
                  <TextField id="razonSocial" name="razonSocial" label="Razón Social" value={editarDatos.razonSocial} onChange={handleChange} />
                  <TextField id="email" name="email" label="Email" value={editarDatos.email} onChange={handleChange} />
                  <TextField id="contacto" name="contacto" label="Contacto" value={editarDatos.contacto} onChange={handleChange} />
                  <TextField id="telefono" name="telefono" label="Telefono" value={editarDatos.telefono} onChange={handleChange} />
                  <TextField id="municipio" name="municipio" label="Municipio" value={editarDatos.municipio} onChange={handleChange} />
                  <Select native name="provincia" id="provincia" style={{ textTransform: 'uppercase'}} onChange={handleChange} defaultValue={editarDatos.provincia}>
                  <option value=""></option>
                  <option value="A CORUÑA">A CORUÑA</option>
                  <option value="ÁLAVA/ARABA">ÁLAVA/ARABA</option>
                  <option value="ALBACETE">ALBACETE</option>
                  <option value="ALICANTE">ALICANTE</option>
                  <option value="ALMERÍA">ALMERÍA</option>
                  <option value="ASTURIAS">ASTURIAS</option>
                  <option value="ÁVILA">ÁVILA</option>
                  <option value="BADAJOZ">BADAJOZ</option>
                  <option value="BALEARES">BALEARES</option>
                  <option value="BARCELONA">BARCELONA</option>
                  <option value="BIZKAIA">BIZKAIA</option>
                  <option value="BURGOS">BURGOS</option>
                  <option value="CÁCERES">CÁCERES</option>
                  <option value="CÁDIZ">CÁDIZ</option>
                  <option value="CANTABRIA">CANTABRIA</option>
                  <option value="CASTELLÓN">CASTELLÓN</option>
                  <option value="CEUTA">CEUTA</option>
                  <option value="CIUDAD REAL">CIUDAD REAL</option>
                  <option value="CÓRDOBA">CÓRDOBA</option>
                  <option value="CUENCA">CUENCA</option>
                  <option value="GIRONA">GIRONA</option>
                  <option value="GRANADA">GRANADA</option>
                  <option value="GUADALAJARA">GUADALAJARA</option>
                  <option value="GIPUZKOA">GIPUZKOA</option>
                  <option value="HUELVA">HUELVA</option>
                  <option value="HUESCA">HUESCA</option>
                  <option value="JAÉN">JAÉN</option>
                  <option value="LA RIOJA">LA RIOJA</option>
                  <option value="LAS PALMAS">LAS PALMAS</option>
                  <option value="LEÓN">LEÓN</option>
                  <option value="LLEIDA">LLEIDA</option>
                  <option value="LUGO">LUGO</option>
                  <option value="MADRID">MADRID</option>
                  <option value="MÁLAGA">MÁLAGA</option>
                  <option value="MELILLA">MELILLA</option>
                  <option value="MURCIA">MURCIA</option>
                  <option value="NAVARRA">NAVARRA</option>
                  <option value="OURENSE">OURENSE</option>
                  <option value="PALENCIA">PALENCIA</option>
                  <option value="PONTEVEDRA">PONTEVEDRA</option>
                  <option value="SALAMANCA">SALAMANCA</option>
                  <option value="SEGOVIA">SEGOVIA</option>
                  <option value="SEVILLA">SEVILLA</option>
                  <option value="SORIA">SORIA</option>
                  <option value="TARRAGONA">TARRAGONA</option>
                  <option value="TENERIFE">TENERIFE</option>
                  <option value="TERUEL">TERUEL</option>
                  <option value="TOLEDO">TOLEDO</option>
                  <option value="VALENCIA">VALENCIA</option>
                  <option value="VALLADOLID">VALLADOLID</option>
                  <option value="ZAMORA">ZAMORA</option>
                  <option value="ZARAGOZA">ZARAGOZA</option>
                  </Select>
                  <TextField id="fechaEncargo" type="date" name="fechaEncargo" InputLabelProps={{ shrink: true }}  label="Fecha Encargo" defaultValue={editarDatos.fechaEncargo} onChange={handleChange} />
                  <TextField id="fechaBienvenida" type="date" name="fechaBienvenida" InputLabelProps={{ shrink: true }}  label="Fecha Bienvenida" defaultValue={editarDatos.fechaBienvenida} onChange={handleChange} />
                  <TextField id="fechaReclamacion" type="date" name="fechaReclamacion" InputLabelProps={{ shrink: true }}  label="Fecha Reclamacion" defaultValue={editarDatos.fechaReclamacion} onChange={handleChange} />
                  <TextField id="fechaInforme" type="date" name="fechaInforme" InputLabelProps={{ shrink: true }}  label="Fecha Informe" defaultValue={editarDatos.fechaInforme} onChange={handleChange} />
                  <div></div>
                </div>
                <div>
                  <TextField style={{display:'flex'}} id="comentarios" name="comentarios" label="Comentarios" value={editarDatos.comentarios} onChange={handleChange} multiline rows={6} columns={100}/>
                </div>
                <div className={classes.form}>
                  <Button variant="contained" onClick={props.handleClose}>Cancelar</Button>
                  <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => editar(editarDatos)}>Editar</Button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}