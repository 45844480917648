import React, {Component} from "react";
import { firestore } from "../../../providers/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";

const PAGE_SIZE = 1000; // Ajusta el tamaño de la página según sea necesario

async function verHojasVistas(tabla) {
  const collectionRef = firestore.collection(tabla);
  const pageSize = 1000; // Número de documentos por página
  let lastVisible = null;
  let fincasCount = 0;
  const hojasVisitaMap = {};
  try {
      while (true) {
          let query = collectionRef.orderBy('__name__').limit(pageSize);

          if (lastVisible) {
              query = query.startAfter(lastVisible);
          }

          const snapshot = await query.get();
          

          if (snapshot.empty) {
              break; // No hay más documentos
          }

          snapshot.docs.forEach(hojaVisita => {
            hojasVisitaMap[hojaVisita.id] = hojaVisita.data();
            // console.log(hojaVisita.id, hojasVisitaMap);
          });

          lastVisible = snapshot.docs[snapshot.docs.length - 1];
          fincasCount += snapshot.docs.length;
          console.log(`Processed ${fincasCount} ${tabla} so far.`);
      }
      // console.log(hojasVisitaMap);
      console.log(`All ${tabla} processed.`);
      return hojasVisitaMap
  } catch (error) {
      console.error(`Error getting ${tabla}: `, error);
  }
}

class ExportarListaTecnicos extends Component {
  constructor() {
    super()
    this.state = {
      tecnicos: [],
      activar: false
    }
  }
  async componentDidMount() {
    let tec = [];
    let HvRealizadas = []
    let hojasVisita = await verHojasVistas('hojasVisita');
    let totalFincas = await verHojasVistas('fincas');
    console.log(hojasVisita)
    console.log(totalFincas)
    let nifAAFF = Object.values(totalFincas).map(f => f.nifAAFF);
    let fVisitadas = [];
    // Procesamiento de técnicos
    this.props.tecnicos.forEach((e, i) => {
      firestore.collection("aaff").where('nifTecnico', '==', e.nif).get().then(snapshot => {
        let nFincas = 0;
        let nPendientes = 0;
        let nPendientesRenovadas = 0;
        let nVisitadas = 0;
        let nVisitadasRenovadas = 0;

        snapshot.docs.forEach(aaff => {
          Object.values(hojasVisita).forEach(hv => {
            if (hv.aaff?.nif === aaff.data().nif) {
              fVisitadas.push(hv.comunidad.nif);
              HvRealizadas.push(hv)
            }
          });

          if (nifAAFF.includes(aaff.data().nif)) {
            Object.values(totalFincas).forEach(f => {
              if (f.nifAAFF === aaff.data().nif && (f.activo === true || f.activo === "true" || f.activo === undefined)) {
                nFincas++;
                if(fVisitadas.includes(f.nif)){
                  //console.log(f.renovada, f.renovadaVisitada, f.activo)
                  HvRealizadas.forEach( fHv => {
                    if(fHv.comunidad.nif === f.nif){
                      let dias = ''
                      let year = new Date(fHv.fechaVisitaRenovacion?.seconds*1000).getFullYear()
                      if(f.renovada && fHv.fechaVisitaRenovacion !== undefined && year !== 1970){
                        dias = this.daysDiff(new Date(fHv.fechaVisitaRenovacion?.seconds*1000), new Date())
                      }else{
                        dias = this.daysDiff(new Date(fHv.fechaVisita?.seconds*1000), new Date())
                      }
                      //console.log(f.nif, f.renovada, f.renovadaVisitada, dias)
                      if(f.renovada && !f.renovadaVisitada && dias > 304){
                        //console.log(e.nif+ f.nif+ " RENOVADA!")
                        nPendientesRenovadas++
                      }else if(f.renovadaVisitada){
                        //console.log(e.nif+ f.nif+ " RENOVADA VISITADA!")
                        nVisitadasRenovadas++
                      }else{
                        //console.log(e.nif+ f.nif+ " VISITADA!")
                        nVisitadas++
                      }
                    }
                  })
                } else {
                  nPendientes++;
                }
              }
            });
          }
        });
        
        let porcentajeVisitadas = nVisitadas === 0 && nFincas === 0 ? 0 : Math.round(((nVisitadas / nFincas) * 100 + Number.EPSILON) * 100) / 100;
        let porcentajeVisitadasRenovacion = nVisitadasRenovadas === 0 && nFincas === 0 ? 0 : Math.round(((nVisitadasRenovadas / nFincas) * 100 + Number.EPSILON) * 100) / 100;
        let estimacion = Math.round(((nPendientes / 160) + Number.EPSILON) * 100) / 100;
        let estimacionRenovacion = Math.round(((nPendientesRenovadas / 160) + Number.EPSILON) * 100) / 100;

        const data = {
          nombreTecnico: e.nombre + " " + e.apellidos,
          nif: e.nif,
          porcentajeVisitado: porcentajeVisitadas,
          pendientes: nPendientes,
          estimacion: estimacion,
          porcentajeVisitadoRenovacion: porcentajeVisitadasRenovacion,
          pendientesRenovacion: nPendientesRenovadas,
          estimacionRenovacion: estimacionRenovacion
        };
        console.log(data, this.props.tecnicos.length)
        tec.push(data);
        this.setState({ tecnicos: tec, activar: true });
      });
    });
  }

  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  render(){   
    
    return (
      <>
      {this.state.tecnicos.length === this.props.tecnicos.length ?
        <ExportToExcel apiData={this.state.tecnicos} fileName={"Listado de técnicos"} />
      :
        <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
      }
      </>
    );
  }
  
}


export default ExportarListaTecnicos;
