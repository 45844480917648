import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import Add from "../../../icons/add.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Lupa from "../../../icons/lupa.svg"
import Delete from "../../../icons/delete.svg"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from "react-toastify";


class Comerciales extends React.Component {
  constructor() {
    super()
    this.state = {
      comerciales: [],
      dialog: {
        opened: false,
        title: "",
        docName: "",
        comercial: undefined
      },
      eliminarComercial: {
        opened: false,
        comercial: undefined
      }
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }
  loadPageContent() {
    firestore.collection("comerciales").orderBy("nombre").get().then(snapshot => {
      let comerciales = []
      snapshot.docs.forEach(comercial => {
        comerciales.push(comercial.data())
      })
      this.setState({ comerciales: comerciales })
    })
  }

  eliminarComercial = () => {
    //console.log(this.state.eliminarComercial.comercial.nif)
    firestore.collection("comerciales").doc(this.state.eliminarComercial.comercial.nif).delete().then(() => {
      toast.success("Comercial eliminado con éxito")
      this.setState({ eliminarComercial: { opened: false, finca: undefined } })
      this.loadPageContent()
    }).catch(err => {
      toast.error("Error al eliminar el comercial")
      console.log(err)
    })
  }

  render() {
    if (this.state.comerciales === []) return <></> //Loading data...
    return (
      <>
      
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})` }}>Comerciales</div>
        <div className="pageContent tableContainer">
          <div className="contentBar">
            <div></div>
            <Link to="/comerciales/nuevo"><div className="contentButton"><img src={Add} alt="Más" />Nuevo Comercial</div></Link>
          </div>
          
            
          
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Comercial</th>
                  <th>Email</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.comerciales.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.email}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}><Link to={"/comerciales/" + e.nif + "/aaff"}><img src={Lupa} alt="Administradores de Fincas" className="tableIcon"></img></Link></td>
                      <td style={{ textAlign: "center" }}><img src={Delete} alt="ELIMINAR" className="tableIcon" onClick={() => this.setState({ eliminarComercial: { opened: true, comercial: e } })}></img></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          
          
        </div>
        {/* ELIMINAR UNA COMERCIAL */}
        <Dialog
          open={this.state.eliminarComercial.opened}
          onClose={() => this.setState({ eliminarComercial: { opened: false, comercial: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres eliminar el comercial <b>{this.state.eliminarComercial.comercial?.nombre+" "+this.state.eliminarComercial.comercial?.apellidos}</b>?</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.setState({ eliminarComercial: { opened: false, comercial: undefined } })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.eliminarComercial} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Eliminar</b>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default Comerciales;

