import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Lupa from "../../../icons/lupa.svg"
import { LinearProgress, withStyles, Box } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {props.label}
      </Box>
    </Box>
  );
}
class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      tecnico: {},
      aaff: []
    }
  }
  componentDidMount() {
    firestore.collection("tecnicos").doc(this.props.params.nif).get().then(snapshot => {
      if (!snapshot.exists) {
        window.location.replace("/tecnicos")
      }
      this.setState({ tecnico: snapshot.data() })

      let aaffs = []
      let promises = []
      firestore.collection("aaff").where('nifTecnico', '==', snapshot.data().nif).get().then(snapshot2 => {
        snapshot2.docs.forEach(doc => {
          let aaffData = doc.data()
          promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaffData.nif).get().then(fincas => {
            aaffData.nFincas = fincas.size
            let proveedores = []
            let fincasVisitadas = 0
          fincas.docs.forEach(finca => {
            proveedores = proveedores.concat(finca.data().nifProveedores)
            if (finca.data().documentos?.hojaVisita?.validado) {
              fincasVisitadas++
            }
          })
            proveedores = [...new Set(proveedores)]
            aaffData.proveedores = proveedores
            aaffData.nProveedores = proveedores.length
            aaffData.fincasVisitadas = fincasVisitadas
            aaffs.push(aaffData)
          }))
        })
        Promise.all(promises).then(() => {
          this.setState({ aaff: aaffs })
        })
      })
    })
  }

  ordenarVisitas = () => {
    let aaff = this.state.aaff
    aaff.sort((a, b) => {
      if (a.nFincas === 0) {
        return 1; //Sin fincas al final
      }
      if (b.nFincas === 0) {
        return -1; //Sin fincas al final
      }

      if (a.fincasVisitadas / a.nFincas > b.fincasVisitadas / b.nFincas) {
        return 1;
      }
      if (a.fincasVisitadas / a.nFincas < b.fincasVisitadas / b.nFincas) {
        return -1;
      }
      return 0;
    })
    //console.log(aaff)
    this.setState({ aaff: aaff })
  }

  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Técnicos / <b>{this.state.tecnico.nombre + " " + this.state.tecnico.apellidos}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO ADMINISTRADORES DE FINCAS
              <Link to="/tecnicos"><div className="gridHeadRight">{"<< volver al listado de Técnicos"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Administrador</th>
                      <th>NIF</th>
                      <th style={{ textAlign: "center" }}>Comunidades visitadas</th>
                      <th style={{ textAlign: "center" }}>Comunidades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.aaff.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{e.razonSocial}</td>
                          <td>{e.nif}</td>
                          <td style={{ textAlign: "center" }}><LinearProgressWithLabel value={e.fincasVisitadas / (e.nFincas || 1) * 100} label={e.fincasVisitadas + '/' + e.nFincas} variant="determinate" /></td>
                          <td style={{ textAlign: "center" }}><Link to={"/tecnicos/" + this.state.tecnico.nif + "/aaff/" + e.nif + "/hojas-visita"}><img src={Lupa} alt="Hojas de visita" className="tableIcon"></img></Link></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(AAFF);

