import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { firestore } from "../../../providers/firebase";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { Grid } from "@material-ui/core";
import Add from "../../../icons/add.svg"

class AsignarComercial extends React.Component {
  constructor() {
    super()
    this.state = {
      nombre: "",
      aaff: undefined,
      resultados: undefined
    }
  }

  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => this.setState({ aaff: doc.data() })).catch(err => window.location.replace("/aaff"))
  }

  buscar = (e) => {
    e.preventDefault();
    firestore.collection("comerciales").where("nombre", ">=", this.state.nombre.toUpperCase()).where("nombre", "<=", this.state.nombre.toUpperCase() + '\uf8ff').get().then(snapshot => {
      let resultados = []
      snapshot.docs.forEach(res => resultados.push(res.data()))
      this.setState({ resultados: resultados })
    }).catch(err => {
      console.log(err)
      toast.error("Error en la busqueda")
    })
  }

  render() {
    if (this.state.aaff === undefined) return null
    return <>
      <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Administradores de Fincas / {this.state.aaff.razonSocial} / <b>Asignar comercial</b></div>
      <div className="pageContent">
        <div className="titleContent">Asignar comercial</div>
        <form className="nuevoAAFFForm" onSubmit={this.buscar}>
          <Grid container spacing={5} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={4}><input type="text" placeholder="Nombre" required value={this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })}></input></Grid>
          </Grid>
          <input type="submit" value="Buscar"></input>
        </form>
        <Resultados resultados={this.state.resultados} aaff={this.state.aaff} />
      </div>
    </>
  }
}

class Resultados extends React.Component {
  asignar = (resultado) => {
    firestore.collection("aaff").doc(this.props.aaff.nif).update({
      nifComercial: resultado.nif
    }).then(() => {
      toast.success("Comercial añadido con éxito")

    }).catch(err => {
      console.log(err)
      toast.error("Error al añadir el comercial")
    })
  }
  render() {
    if (this.props.resultados === undefined) return null;
    return (
      <div className="tableContainer" style={{ marginTop: 30 }}>
        <table>
          <thead>
            <tr>
              <th>Comercial</th>
              <th>NIF</th>
              <th style={{ textAlign: "center" }}>Asignar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.resultados.map((e, i) => {
              return (
                <tr key={i}>
                  <td>{e.nombre + " " + e.apellidos}</td>
                  <td>{e.nif}</td>
                  <td style={{ textAlign: "center" }}><div className="asignarButton" style={{ marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer' }} onClick={() => this.asignar(e)}><img src={Add} alt="Más" />Asignar</div></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default withRouter(AsignarComercial);
