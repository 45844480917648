import React, { useState, useEffect, useRef } from 'react'
import noVisitada from "../icons/noVisitada.svg"
import notaNV from "../icons/notaNV.svg"
import { withRouter } from "../providers/withRouter";
import { firestore, auth } from "../providers/firebase";
import Swal from 'sweetalert2';
import { Popover, Tooltip, Typography } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
}));

const NoVisitado = (props) => {
// console.log(props)
  const classes = useStyles();
    const textareaRef = useRef(null);
    const [rol, setRol] = useState('')
    const aaff = props.aaff
    const [fincas, setfincas] = useState([])
    const [activado, setNoActivado] = useState(false)
    const [ObsTexto, setObsTexto] = useState('')
    const [recargar, setRecargar] = useState(props.activado)
    
    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            userAuth.getIdTokenResult().then((idToken) => {
                setRol(idToken.claims.rol)
            })
        });

        firestore.collection('fincas').where("nifAAFF", "==", props.nif).get().then(finca => {
          // console.log(finca.docs.length)
          let numFincas = 0
          let i = 0 
          finca.docs.forEach(f=> {
            if(f.data().centrosTrabajo?.length>0){
              let j = 0
              f.data().centrosTrabajo.forEach(ct=> {
                if(ct.noVisitada && (ct.observacionNoVisita === 'El AAFF no quiere visita en la renovación' || ct.observacionNoVisita === 'El AAFF no quiere que empecemos todavía las visitas')){j++}
                // console.log(j, ct.nif+" centroTrabajo")
              })
              if(f.data().centrosTrabajo?.length === j){i++} //si todos los centros de trabajo de una comunidad tienen noVisitada en true y es la misma cantidad que el total de centros de trabajo de esa comunidad sumo 1 a i
              
            }else{
              if(f.data().noVisitada && (f.data().observacionNoVisita === 'El AAFF no quiere visita en la renovación' || f.data().observacionNoVisita === 'El AAFF no quiere que empecemos todavía las visitas')){i++}
              // console.log(i, f.data().nif+" finca")
            }
          })
          // console.log(finca.docs.length, i)
          if(finca.docs.length === i){ setNoActivado(true)} //si el total de comunidades es igual al numero de comunidades con noVisitada en true se pone la variable activado en true 
          
        })
        
    },[aaff, recargar])
    

    const handleAdd = () => {
        // console.log(aaff)
        let fincas =[]
        Swal.fire({
            title: 'Motivo de la no visita',
            // html: `<textarea id="motivoTexto" style="width: 373px; height: 210px; font-size:11px; padding:10px;" class="swal2-input" ></textarea>`,
            html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" class="swal2-input" >
                    <option>El AAFF no quiere visita en la renovación</option>
                    <option>El AAFF no quiere que empecemos todavía las visitas</option>
                   </select>`,
            confirmButtonText: 'Guardar',
            confirmButtonColor: '#05811b',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#d33',
            focusConfirm: false,
            didOpen: () => {
              Swal.showLoading()
              let promises = []
              
              promises.push(firestore.collection('fincas').where("nifAAFF", "==", props.nif).get().then(finca => {
                finca.docs.forEach(f=> {
                  fincas.push(f.data())
                })
              }))
              Promise.all(promises).then(() => {
                Swal.hideLoading()
                // console.log(fincas)
                //setfincas(fincas)
              })
            },
            preConfirm: () => {
              const texto = Swal.getPopup().querySelector('#motivoTexto').value
              // console.log(texto)
              if (!texto) {
                Swal.showValidationMessage(`insertar texto`)
              }
              return {texto: texto }
            }
          }).then((result) => {
            if(result.value !== undefined){
              Swal.fire({
                icon: 'success',
                title: 'Datos guardados'
              })
              // console.log(result.value.texto, aaff.nif, new Date())
              // console.log(new Date()+": "+auth.currentUser.displayName+" - "+result.value.texto)
              // console.log(fincas)
              let i = 0 
              
              fincas.forEach(f=> {
                if(f.centrosTrabajo?.length>0){
                  let centros = []
                  f.centrosTrabajo.forEach((ct, j)=> {
                    // console.log(j, ct.nif+" centroTrabajo")
                     
                    const objetoModificar = ct
                    objetoModificar.observacionNoVisita = result.value.texto;
                    objetoModificar.fechaNoVisita = new Date();
                    objetoModificar.noVisitada = true;
                    centros.push(objetoModificar)
                  })
                  // console.log(centros)
                  firestore.collection("fincas").doc(f.nif).update({ 
                    centrosTrabajo: centros
                  })
                }else{
                  // console.log(i, f.nif+" finca")
                  // const objetoModificar = f
                  // objetoModificar.observacionNoVisita = result.value.texto;
                  // objetoModificar.fechaNoVisita = new Date();
                  // objetoModificar.noVisitada = true;
                  // console.log(objetoModificar)
                  firestore.collection("fincas").doc(f.nif).update({ 
                    observacionNoVisita: result.value.texto,
                    fechaNoVisita: new Date(),
                    noVisitada: true
                  })
                  i++
                }
                setRecargar(!recargar)
                setNoActivado(true)
              })
            }
          })
    }
    return ( 
        <>
            {rol === 'tec' && activado !== true &&
                <button type="button" onClick={handleAdd}><img src={notaNV} alt="No visitado" className="tableIcon" /></button>
            }
            {activado && 
            <ThemeProvider theme={theme}>
              <Tooltip title={ObsTexto} classes={{ tooltip: classes.customWidth }}>
                <img src={noVisitada} alt="No visitado" className="tableIcon"/>
              </Tooltip>
            </ThemeProvider>
            }
            <style jsx="true">{`
                .MuiTooltip-popper div{
                    padding:14px;
                    font-size:14px;
                    visibility:visible;
                    white-space: pre-line;
                }                 
            `}</style>
        </>
     );
}
 
export default withRouter(NoVisitado);