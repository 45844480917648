import React from "react";
import { firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Minus from "../../../icons/minus.svg"
import Editar from '../../../icons/editar.svg'
import Adjuntar from "../../../icons/upload.svg"
import Documento from "../../../icons/document.svg"
import Aviso from "../../../icons/email.svg"
import { Link } from "react-router-dom";
import ModalEditar from './editarDatosComunidad'
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { serviciosProveedores } from "../../../providers/serviciosProveedores";
import firebase from "firebase";

class Comunidad extends React.Component {
  constructor() {
    super()
    this.state = {
      finca: undefined,
      proveedores: [],
      existeHojaVisita: false,
      anoFechaVisita: 0,
      anoFechaVisitaCt: 0,
      modalEditarOpened: false,
      expandir: true,
      hojasDeVisitaHTML:[],
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    firestore.collection("fincas").doc(this.props.params.nifFinca).get().then(snapshot => {
        let finca = snapshot.data();
        let proveedores = [];
        let promises = [];
        promises.push(firestore.collection("aaff").doc(finca.nifAAFF).get().then(a=> {finca.aaff = {telefono:a.data().telefono, email: a.data().email, nombre: a.data().contacto}}))
        finca.proveedores.forEach(proveedor => {
          promises.push(firestore.collection("proveedores").doc(proveedor.nif).get().then(p => proveedores.push({ ...p.data(), servicio: proveedor.servicio })))
        });
        Promise.all(promises).then( async () => {
          // console.log(finca.aaff)
          this.setState({ finca: finca, proveedores: proveedores })
          const html = await this.loopHv(finca.documentos.hojaVisita?.ano === undefined ? 2022 : finca.documentos.hojaVisita?.ano, finca)
          this.setState({ hojasDeVisitaHTML: html });
        })
      }).catch(error => {
        window.location.replace("/")
      })
    firestore.collection("hojasVisita").doc(this.props.params.nifFinca).get().then( hv => hv.exists ? this.setState({existeHojaVisita:true, anoFechaVisita: new Date(hv.data().fechaVisita?.seconds*1000).getFullYear()}) : this.setState({anoFechaVisita: new Date().getFullYear()}))
    
    firestore.collection("hojasVisita").doc(this.props.params.nifFinca).get().then( hv => {
      if(hv.exists && hv.data().centrosTrabajo !== undefined){
        if(hv.data().centrosTrabajo.length === 0){
          this.setState({anoFechaVisitaCt: new Date().getFullYear()})
        }else{
          hv.data().centrosTrabajo.forEach(ct => {
            this.setState({anoFechaVisitaCt: new Date(ct.fechaVisita?.seconds*1000).getFullYear()})
          })
        }
      }else{
        this.setState({anoFechaVisitaCt: new Date().getFullYear()})
      } 
    })  
  }

  subirLOPD = (event) => {
    storage.ref(`${this.state.finca.nif}/${event.target.files[0]["name"]}`).put(event.target.files[0]).then(snapshot => {
      this.setState(prevState => ({
        finca: {
          ...prevState.finca,
          estado: prevState.finca.documentos.lopd?.validado ? prevState.finca.estado - 5 : prevState.finca.estado,
          documentos: {
            ...prevState.finca.documentos,
            lopd: {
              ...prevState.finca.documentos.lopd,
              ruta: snapshot.ref.fullPath,
              pendiente: true,
              validado: false
            }
          }
        }
      }))
      firestore.doc(`fincas/${this.state.finca.nif}`).update(this.state.finca)
      toast.success("El archivo se ha subido correctamente")
    }).catch(error => {
      console.log(error)
      toast.error("Ha ocurrido un error al subir el archivo")
    })
  }

  abrirArchivo = (ruta) => {
      console.log(ruta)
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }

  }

  loopHvCt = (num, c, fNif) => {
      //console.log(num)
      let anoFechaVisita = this.state.anoFechaVisitaCt
      if(num < this.state.anoFechaVisitaCt){
        anoFechaVisita = num
      }
      let y = 0
      let arr = []
      let anos = []
      let rutas = []
      console.log(c.nif, anoFechaVisita, num)
      for(let x = anoFechaVisita; x <= num; x++){
        console.log(x)
        y++
        anos.push(x)
        if(x === 2022 || c.documentos.hojaVisita?.ano === '' || c.documentos.hojaVisita?.ano === undefined){
            rutas.push("/"+fNif+"/"+c.nif+"/hoja-visita.pdf")
        }else{
            rutas.push("/"+fNif+"/"+c.nif+"/hoja-visita_"+x+".pdf")
        }
    }
    console.log(y)
    for(let z = 0; z < y; z++){
        arr.push(<tr>
            <td>{c.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
            <td style={{ width: '100%' }}>Hoja de visita {anos[z]}</td>
            <td style={{ textAlign: 'center' }}></td>
            <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(rutas[z])} /></td>
        </tr>)
    }
    return arr
  }

  loopHv = async (num, f) => {
    let anoFechaVisita = this.state.anoFechaVisita
    if(num < this.state.anoFechaVisita){
      anoFechaVisita = num
    }
    let y = 0
    let arr = []
    let anos = []
    let rutas = []
    console.log(anoFechaVisita, num)
    for(let x = anoFechaVisita; x <= num; x++){
      
      let ruta = f.documentos.hojaVisita?.ano === undefined || f.documentos.hojaVisita?.ano === '' || x === 2022
          ? `/${f.nif}/hoja-visita.pdf`
          : `/${f.nif}/hoja-visita_${x}.pdf`;
      
      try {
        // Utilizamos await para esperar la resolución de la promesa
        const url = await firebase.storage().ref().child(ruta).getDownloadURL();
        
        // Si el archivo existe, lo agregamos a anos y aumentamos y
        anos.push(x);
        y++;

        // Agregar la ruta al arreglo rutas
        rutas.push(ruta);
      } catch (error) {
        // Si hay un error, no hacemos nada
        console.error("Archivo no encontrado para el año", x, error);
      }
    }
    for(let z = 0; z < y; z++){
        arr.push(<tr>
            <td>{this.state.finca.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : this.state.finca.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
            <td style={{ width: '100%' }}>Hoja de visita {anos[z]}</td>
            <td style={{ textAlign: 'center' }}></td>
            <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(rutas[z])} /></td>
        </tr>)
    }
    
    console.log(arr)
    return arr
  }

  render() {
    if (!this.state.finca) return null //Loading data...
    return <>
         <ModalEditar finca={this.state.finca} opened={this.state.modalEditarOpened} handleClose={() => {
          this.setState({ modalEditarOpened: false })
          this.loadPageContent()
        }} />
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Comunidad / <b>Vecinos {this.state.finca.razonSocial}</b></div>
        <div className="pageGrid">
            <div className="gridCard">
                <div className="gridHead">INFORMACIÓN GENERAL</div>
                <div className="gridSubhead editarSubhead">
                    <div>DATOS COMUNIDAD</div>
                    <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <img src={Editar} alt="Editar" /></div>
                </div>
                <div className="datosGrid">
                    <p><b>Nombre comunidad:</b> {this.state.finca.razonSocial}</p>
                    <p><b>Dirección:</b> {this.state.finca.direccion}</p>
                    <p><b>NIF:</b> {this.state.finca.nif}</p>
                    <p><b>Municipio:</b> {this.state.finca.municipio}</p>
                    <p><b>Persona de contacto:</b> {this.state.finca.aaff.nombre}</p>
                    <p><b>CP:</b> {this.state.finca.codigoPostal}</p>
                    <p><b>Email:</b> {this.state.finca.aaff.email}</p>
                    <p><b>Provincia:</b> {this.state.finca.provincia}</p>
                    <p><b>Telefono:</b> {this.state.finca.aaff.telefono}</p>
                    {/* <p><b>Fecha de alta:</b> {new Date(this.state.finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
                </div>
                <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
                    <table style={{ margin: 15 }}>
                    <thead>
                    <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                        <th></th>
                        <th></th>
                        <th>{/* Adjuntar */}</th>
                        <th style={{ margin: 5 }}>Ver</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.state.finca.documentos.lopd?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : this.state.finca.documentos.lopd?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                        <td style={{ width: '100%' }}>Certificado LOPD</td>
                        <td style={{ textAlign: 'center' }}>{/* <label htmlFor="lopd"><img src={Adjuntar} alt="Adjuntar" className="tableIcon" /></label><input type="file" id="lopd" onChange={this.subirLOPD} className="inputfile" /> */}</td>
                        <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.lopd?.ruta)} /></td>
                    </tr>
                    {(this.state.finca.centrosTrabajo === undefined || this.state.finca.centrosTrabajo.length === 0) ? 
                    <>
                      {!this.state.finca.documentos.hojaVisita?.pendiente && !this.state.finca.documentos.hojaVisita?.validado ? 
                        <tr>
                          <td>{this.state.finca.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : this.state.finca.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                          <td style={{ width: '100%' }}>Hoja de visita</td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.hojaVisita?.ruta)} /></td>
                        </tr>
                      : 
                        this.state.hojasDeVisitaHTML
                      }
                    {/* {this.state.existeHojaVisita === false ? 
                        <tr>
                        <td>{this.state.finca.documentos.prevencionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : this.state.finca.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                        <td style={{ width: '100%' }}>Plan de prevención</td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.prevencionRiesgos?.ruta)} /></td>
                        </tr>
                    : null} */}
                    <tr>
                        <td>{this.state.finca.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : this.state.finca.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                        <td style={{ width: '100%' }}>Identificación de riesgos y medidas preventivas</td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'center' }}>
                          <Link to={"/comunidades/" + this.state.finca.nif+ "/evaluacion-riesgos"} target="_blank"><img src={Documento} alt="Ver" className="tableIcon" /></Link>
                          {/* <img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.evaluacionRiesgos?.ruta)} /> */}
                        </td>
                    </tr>
                    <tr>
                        <td>{this.state.finca.documentos.planEmergencia?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : this.state.finca.documentos.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                        <td style={{ width: '100%' }}>Plan de emergencia</td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'center' }}><Link to={"/comunidades/" + this.state.finca.nif+ "/planEmergencia"} target="_blank"><img src={Documento} alt="Ver" className="tableIcon"/*  onClick={() => this.abrirArchivo(this.state.finca.documentos.planEmergencia?.ruta)} */ /></Link></td>
                    </tr>
                    </>
                    : 
                    null
                    }
                    
                    </tbody>
                    </table>
                    {(this.state.finca.centrosTrabajo === undefined || this.state.finca.centrosTrabajo.length === 0) ? null :
                        <>
                            <div className="gridSubhead">CENTROS DE TRABAJO</div>
                            <table style={{ margin: 15 }}>
                                <thead>
                                    <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ margin: 5 }}>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.finca.centrosTrabajo.map((c,j) => {
                                        return (
                                        <>
                                        {console.log(c)}
                                            <tr>
                                            <td colSpan="8" style={{backgroundColor: '#fffbf4', padding: '10px'}}>{c.nombre}</td>
                                            </tr>
                                            {this.loopHvCt(c.documentos.hojaVisita?.ano === undefined ? 2022 : c.documentos.hojaVisita?.ano, c, this.state.finca.nif, this.state.anoFechaVisitaCt)}
                                           {/*  <tr>
                                            <td>{c.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ width: '100%' }}>Hoja de visita {this.loopHV(c.documentos.hojaVisita?.ano === undefined ? 2022 : c.documentos.hojaVisita?.ano)}</td>
                                            <td style={{ textAlign: 'center' }}></td>
                                            <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.hojaVisita?.ruta)} /></td>
                                            </tr> */}

                                            {/* <tr>
                                            <td>{c.documentos.prevencionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ width: '100%' }}>Plan de prevención</td>
                                            <td style={{ textAlign: 'center' }}></td>
                                            <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.prevencionRiesgos?.ruta)} /></td>
                                            </tr> */}
                                            <tr>
                                            <td>{c.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ width: '100%' }}>Identificación de riesgos y medidas preventivas</td>
                                            <td style={{ textAlign: 'center' }}></td>
                                            <td style={{ textAlign: 'center' }}>
                                              {this.state.existeHojaVisita ? 
                                                <Link to={`/comunidades/${this.props.params.nif}/${c.nif}/evaluacion-riesgos`} target={'_blank'}><img src={Documento} alt="Ver" className="tableIcon" /></Link>
                                              :
                                                <img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.evaluacionRiesgos?.ruta)} />
                                              }
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>{c.documentos.planEmergencia?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ width: '100%' }}>Plan de emergencia</td>
                                            <td style={{ textAlign: 'center' }}></td>
                                            <td style={{ textAlign: 'center' }}>
                                              {this.state.existeHojaVisita ? 
                                                <Link to={`/comunidades/${this.props.params.nif}/${c.nif}/PlanEmergencia`} target={'_blank'}><img src={Documento} alt="Ver" className="tableIcon" /></Link>
                                              :
                                                <img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.planEmergencia?.ruta)} />
                                              }
                                            </td>
                                            </tr>
                                        </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                    }
            
            </div>
            <div className="gridCard">
                <div className="gridHead">PROVEEDORES</div>
                <table style={{ margin: 15 }}>
                    <thead>
                    <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                        <th></th>
                        {/* <th>Aviso</th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.proveedores.map((e, i) => {
                        return (
                        <tr key={i}>
                            <td style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}><Link to={"/proveedores/" + e.nif}>{e.razonSocial}<p style={{ opacity: 0.55, fontSize: 14 }}>{serviciosProveedores[e.servicio]}</p></Link></td>
                            {/* <td style={{ textAlign: 'center' }}><img src={Aviso} alt="Aviso" className="tableIcon" /></td> */}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    </>
  }
}

export default withRouter(Comunidad);
