import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, Select, Button, Fade, Backdrop, Modal, InputLabel, TextField } from '@material-ui/core';
import { firestore, auth } from "../../../providers/firebase";
import firebase from "firebase";
/* const sgMail = require("@sendgrid/mail"); */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [notificarDatos, setNotificarDatos] = useState({});
  const [fincas, setFincas] = useState([]);

  useEffect(() => {
    const unsuscribe = firestore.collection("fincas").where("nifProveedores", "array-contains", auth.currentUser.uid).orderBy("razonSocial").onSnapshot((snapshot) => {
      setFincas(snapshot.docs.map((documento) => {
        return { ...documento.data() }
      }))
    })
    return unsuscribe;
  }, [])

  const enviar = () => {
    const nombreFinca = fincas.filter(item => item.nif === notificarDatos.finca).map(item => item.razonSocial)
    /* console.log(nombreFinca)
    console.log(props.proveedor.razonSocial)
    console.log(notificarDatos) */
    const enviarIncidencia = firebase.functions().httpsCallable('enviarIncidencia');
    enviarIncidencia({nombreFinca: nombreFinca, nombreProveedor: props.proveedor.razonSocial, nifFinca: notificarDatos.finca, incidencia: notificarDatos.incidencia })
    .then( result => {
      console.log(result.data);
    })
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setNotificarDatos({
      ...notificarDatos,
      [evt.target.name]: value
    });
  }


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.opened}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Enviar Nofiticación</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl required className={classes.formControl} style={{ width: '100%' }}>
                      <InputLabel htmlFor="finca">Seleccionar finca</InputLabel>
                      <Select
                        native
                        name="finca"
                        inputProps={{
                          id: 'finca',
                        }}
                        onChange={handleChange}
                      >
                        <option aria-label="None" value="" />
                        {fincas.map((e, i) => {
                          return (
                            <option key={i} value={e.nif}>{e.razonSocial}</option>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      id="incidencia"
                      name="incidencia"
                      label="Incidencia"
                      multiline
                      rows={10}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button variant="contained" onClick={props.handleClose}>Cancelar</Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => enviar()}>Enviar</Button>
                  </Grid>
                </Grid>
              </form>

            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}