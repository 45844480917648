import React, { useState, useEffect } from "react";
import Portada from "../../../images/PortadaProveedor.png"
import Cabecera from "../../../images/Encabezado.jpg"
// import Logo from "../../../images/LogoLAE.png"
import Logo from "../../../images/LogoLAENEW.png"
import Tick from "../../../icons/tick.png"
import Cross from "../../../icons/cross.png"
import { firestore } from "../../../providers/firebase"
import { withRouter } from "../../../providers/withRouter";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer"
import CircularProgress from '@material-ui/core/CircularProgress';
import orderArray from "../../../providers/orderArray";

const InformeSituacionProveedores = (props) => {

  const [proveedores, setProveedores] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    firestore.collection("fincas").where("nifAAFF", "==", props.params.nif).get().then(snapshot => {
        let nifProveedores = []
        let proveedores = []
        snapshot.docs.forEach(finca => {
          if(finca.data().activo !== false && finca.data().activo !== 'false'){
            nifProveedores = nifProveedores.concat(finca.data().nifProveedores)
          }
        })
        nifProveedores = [...new Set(nifProveedores)]
        let promises = []
        nifProveedores.forEach(nifProv => {
          let pr = 0
          let pe = 0
          let numFincas = 0
          let iconoPrevencionRiesgos = null
          let iconoPlanEmergencia = null
          let provs = {}
          promises.push(firestore.collection("proveedores").doc(nifProv).get().then(doc => {
            if(doc.exists){ 
              provs = doc.data()
              promises.push(snapshot.docs.forEach(finca => {
                if(finca.data().nifProveedores.includes(nifProv)){ numFincas++}
                if(finca.data().documentos.prevencionRiesgos.leido?.[nifProv] !== undefined ){ pr++}
                if(finca.data().documentos.planEmergencia.leido?.[nifProv] !== undefined ){ pe++}
              }))
              
              if(numFincas === pr){
                iconoPrevencionRiesgos = "tick"
              }else{
                iconoPrevencionRiesgos = "cross"
              }
              if(numFincas === pe){
                iconoPlanEmergencia = "tick"
              }else{
                iconoPlanEmergencia = "cross"
              }
              provs.prevencionRiesgos = pr
              provs.planEmergencia = pe
              provs.numFincas = numFincas
              provs.iconoPrevencionRiesgos = iconoPrevencionRiesgos
              provs.iconoPlanEmergencia = iconoPlanEmergencia
              proveedores.push(provs)
            }
          }))
        })
          
        Promise.all(promises).then(() => setProveedores(orderArray(proveedores), () => {
          var src = document.getElementById('portada').style.backgroundImage
          var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');
  
          var img = new Image();
          img.onload = function () {
            window.print()
          }
          img.src = url;
          if (img.complete) img.onload();
  
        }))
        setIsLoading(false)
      })
  },[])
  const styles = StyleSheet.create({
    imgPrincipal:{
        height:'400px', 
        width:'100%', 
        marginTop:30
    },
    body: {
        //paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: '0cm',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    }, 
    tableRowHeader: { 
        marginTop:"30px",
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "7%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        /* backgroundColor: '#375491',
        color: '#ffffff', */
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize: 14 
    }, 
    tableColHeader1: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        /* backgroundColor: '#375491',
        color: '#ffffff', */
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize: 14 
    }, 
    tableCol: { 
        width: "7%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    }, 
    tableCol1: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    }, 
    tableCell: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        marginLeft:2,
        marginRight: 2,
        fontSize: 6 
    },
    tableCellD: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        marginLeft:2,
        marginRight: 2,
        fontSize: 10 
    },
    tableColHeaderDecRes: { 
        width: "25%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        /* backgroundColor: '#375491',
        color: '#ffffff', */
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize: 14 
    }, 
    tableColHeaderDecRes1: { 
        width: "25%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        /* backgroundColor: '#375491',
        color: '#ffffff', */
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize: 14 
    }, 
    tableColDecRes: { 
        width: "25%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    }, 
    tableColDecRes1: { 
        width: "25%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    }, 
    tableCellDecRes: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        marginLeft:2,
        marginRight: 2,
        fontSize: 6 
    },
    tableCellDDecRes: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        marginLeft:2,
        marginRight: 2,
        fontSize: 10 
    },
    pdfHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        /* margin:'2cm', */
    },
    pdfHeaderImg:{
        width:'100%', 
        height:'60px',
        marginBottom:'30px',
    },
    tablePage: {
        width: '100%',
    },
    headerSpace: {
        height: '90px',
    },
    div: {
        fontSize: '10px'
    },
    h1: {
        /* marginLeft:'2cm',
        marginRight:'2cm', 
        marginTop: '50px',*/
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
    },
     h2: {
        fontSize: '11px',
        fontWeight: '600',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        marginTop: '10px',
        marginBottom: '10px',
    },
    h3: {
        fontSize: '9px',
        width: '100%',
        padding: '5px 10px',
        backgroundColor: '#f8f8f8',
        textTransform: 'uppercase',
        border: '1px solid #e6e6e6',
        marginTop: '25px',
        marginBottom: '15px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'20cm', */
    },
    h4: {
        color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '9px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    h5: {
        fontSize: '10px',
        marginTop: '15px',
        marginBottom: '5px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    tableRiesgos: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: '24px',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    simpleTable: {
        borderCollapse: 'collapse',
        width: '80%',
        margin: '0 10% 24px 10%',
    },
    simpleTableTh: {
        textTransform: 'uppercase',
    },
    simpleTableTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: '1.1'
    },
    ul: {
        // lineHeight: '1.8',
        // fontSize:'7px',
        flexDirection: "column", 
        width: 150,
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        fontSize: '7px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '7px',
    },
    imgP:{
        width:'100px', 
        marginBottom:'30px',
    },
    subp: {
        lineHeight: '1.8',
        textAlign: 'justify',
        marginLeft: '8px',
        fontSize: '8px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    footerSpace: {
        height: 50
    },
    page: {
        size: 'A4 portrait',
        margin: '2cm',
    },
    pagebreak: {
        clear: 'both',
        pageBreakAfter: 'always',
      }
  });

  const itemsPerPage = 22; // Número de elementos por página
  const pages = Math.ceil(proveedores.length / itemsPerPage); // Calcular el número total de páginas

  const firstTableData = proveedores.slice(itemsPerPage); // Datos para la tabla 1
  const secondTableData = proveedores.slice(itemsPerPage); // Datos para la tabla 2 y el texto


  const ProvDocs = (pageIndex) => {
    const startIndex = pageIndex * itemsPerPage;
    return proveedores.slice(startIndex, startIndex + itemsPerPage);
  };
  const provDeclRes = (pageIndex) => {
    const startIndex = pageIndex * itemsPerPage;
    return proveedores.slice(startIndex, startIndex + itemsPerPage);
  };

  return (
    <>
      {isLoading ? <CircularProgress style={{color:'#fc7271', position: 'absolute', top: '50%', left: '50%'}} /> :
      <PDFViewer style={{ width:"100%", height:"100vh"}}>
        <Document size="A4" margin="2cm">
        <Page>
            <View>
                <Image src={Portada} alt="Portada" style={{height:"100vh"}}/>
            </View>
        </Page>
        {Array.from({ length: pages }).map((_, pageIndex) => {
            return (
                <Page key={pageIndex} style={styles.body}>
                    <View style={{marginTop: 0, paddingBottom: 20, marginHorizontal: '1cm'}} fixed>
                        <View >
                            <Image src={Logo} style={{marginTop: 20, width:'20%'}} />
                        </View>
                        {/* <View style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                            <Image style={styles.pdfHeaderImg} src={Cabecera} alt="Cabecera" />
                        </View>  */}
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                        {pageIndex === 0 && (
                            <View>
                                <Text style={styles.p}>
                                    Estimado administrador de fincas.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Tras los primeros meses de trabajo administrativo y reclamación de la documentación a los proveedores os mostramos a continuación el informe de situación documental:
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.br}></Text>
                            </View>
                        )}
                        <View style={styles.table}>
                            <>
                                <View style={styles.tableRowHeader} fixed> 
                                    <View style={styles.tableColHeader1}> 
                                        <Text style={styles.tableCell}>Proveedor</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>NIF</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Última Reclamación</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Certificado LOPD</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Seguro Resp. Civil</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Justificante pago SPA</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Planificación preventiva</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Identificación riesgos</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Certificado Seg. Soc.</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Vigilancia salud</Text> 
                                    </View> 
                                    <View style={styles.tableColHeader}> 
                                        <Text style={styles.tableCell}>Declaración responsable</Text> 
                                    </View> 
                                </View>
                                {ProvDocs(pageIndex).map((e, i) => (
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <Text style={styles.tableCell}>{e.razonSocial}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{e.nif}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{e.ultimaReclamacion === undefined ? new Date(e.fechaAlta.seconds * 1000).toLocaleDateString() : new Date(e.ultimaReclamacion.seconds * 1000).toLocaleDateString()}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCellD}>{(e.documentos?.lopd?.validado || e.documentos?.lopd?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCellD}>{(e.documentos?.seguro?.validado || e.documentos?.seguro?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCellD}>{(e.documentos?.justificante?.validado || e.documentos?.justificante?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCellD}>{(e.documentos?.planificacionPreventiva?.validado || e.documentos?.planificacionPreventiva?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCellD}>{(e.documentos?.evaluacionRiesgos?.validado || e.documentos?.evaluacionRiesgos?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCellD}>{(e.documentos?.certificadoSS?.validado || e.documentos?.certificadoSS?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCellD}>{(e.documentos?.vigilancia?.validado || e.documentos?.vigilancia?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCellD}>{(e.documentos?.declaracionResponsable?.[props.params.nif]?.validado || e.documentos?.declaracionResponsable?.[props.params.nif]?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                        </View> 
                                    </>
                                ))}
                            </>
                        </View>
                        {pageIndex === Math.ceil(proveedores.length / itemsPerPage) - 1 && (
                            <>
                                <View>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.p}>
                                        La obtención de esta documentación es fundamental para asegurar que los proveedores que acceden a las comunidades de propietarios cumplen debidamente con las obligaciones normativas (seguros de responsabilidad civil, modalidad preventiva en vigor, obligaciones en materia laboral, etc.).
                                    </Text>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.p}>
                                        Al mismo tiempo os informamos de la relación de proveedores que se han descargado la documentación relativa a la evaluación de riesgos y/o plan de emergencia de las comunidades que prestan servicio:
                                    </Text>
                                </View>
                            </>
                        )}
                        {pageIndex === Math.ceil(proveedores.length / itemsPerPage) - 1  && (
                            <View style={styles.table}>
                                <>
                                    <View style={styles.tableRowHeader}> 
                                        <View style={styles.tableColHeaderDecRes}> 
                                            <Text style={styles.tableCellDecRes}>Proveedor</Text> 
                                        </View> 
                                        <View style={styles.tableColHeaderDecRes}> 
                                            <Text style={styles.tableCellDecRes}>NIF</Text> 
                                        </View> 
                                        <View style={styles.tableColHeaderDecRes}> 
                                            <Text style={styles.tableCellDecRes}>LECTURA DE PLANES DE PREVENCIÓN</Text> 
                                        </View> 
                                        <View style={styles.tableColHeaderDecRes}> 
                                            <Text style={styles.tableCellDecRes}>LECTURA DE PLANES DE EMERGENCIA</Text> 
                                        </View> 
                                    </View>
                                    
                                    {secondTableData.map((e, i) => (
                                        <>
                                        {e.iconoPlanEmergencia === 'tick' || e.iconoPrevencionRiesgos === 'tick' ? 
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tableColDecRes}> 
                                                    <Text style={styles.tableCellDecRes}>{e.razonSocial}</Text> 
                                                </View> 
                                                <View style={styles.tableColDecRes}> 
                                                    <Text style={styles.tableCellDecRes}>{e.nif}</Text> 
                                                </View> 
                                                <View style={styles.tableColDecRes}> 
                                                    <Text style={styles.tableCellDDecRes}>{e.iconoPrevencionRiesgos === 'tick' ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                                </View> 
                                                <View style={styles.tableColDecRes}> 
                                                    <Text style={styles.tableCellDDecRes}>{e.iconoPlanEmergencia === 'tick' ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                                </View> 
                                            </View> 
                                        : ''}
                                        </>
                                    ))}
                                </>
                            </View>
                        )}
                        {console.log("pageIndexX "+pageIndex, pages - 1)}
                        {pageIndex === pages - 1 && (
                            <View>
                                <Text style={styles.br}></Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Nuestro departamento técnico seguirá trabajando mensualmente en la reclamación activa de documentación.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Quedamos a su entera disposición para aclarar cualquier duda que puedan tener,
                                </Text>
                                <Text style={styles.p}>
                                    Atentamente,
                                </Text>
                                <Image alt='logo' src={Logo} style={styles.imgP} />
                            </View>
                        )}
                        
                    </View>
                </Page>
            )
        })}
        </Document>
      </PDFViewer>
        
      }
      <style jsx>{`
        .header-space {
          height: 100px;
          background-image: url(${`'${Cabecera}'`});
          background-position: bottom;
          background-size: cover;
          margin-bottom: 50px;
        }
        .footer-space {
          height: 30px;
        }
        .portada {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-position: bottom;
          background-size: cover;
        }
        .pagina {
          margin-top: 150px;
          padding: 0px 100px;
          font-size: 10px;
        }
        p {
          line-height: 1.8;
          text-align: justify;
        }
        br {
          display: block;
          margin: 18px 0 20px 0;
          line-height: 22px;
          content: " ";
        }
       .simpleTable {
          border-collapse: collapse;
          margin-bottom: 30px;
        }
        .simpleTable th {
          text-transform: uppercase
        }
        .simpleTable th, .simpleTable td {
          border: 1px solid #000000;
          padding: 5px;
          line-height: 1.1
        }
        .simpleTable img {
          height: 25px
        }
        @page {
          size: A4 portrait;
          margin: 0;
        }
      `}</style>
    </>
  )
}
 

export default withRouter(InformeSituacionProveedores);
