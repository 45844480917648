export default (array) => {
    array.sort((a, b) => {
        if (a.razonSocial > b.razonSocial) {
          return 1;
        }
        if (a.razonSocial < b.razonSocial) {
          return -1;
        }
        return 0;
    })

    return array
}