import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { firestore, storage } from "../../../providers/firebase";
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { subirDeclaracionResponsable, subirDocumentacionProveedor } from "../../../providers/documentos";
import orderArray from "../../../providers/orderArray";
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomSwitch = withStyles({
  switchBase: {
    color: "#FF5252",
    '&$checked': {
      color: "#7bc977",
    },
    '&$checked + $track': {
      backgroundColor: "#7bc977",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#FF5252"
  },
})(Switch);

const CustomPendienteSwitch = withStyles({
  switchBase: {
    color: "#FFD700"
  },
  checked: {},
  track: {
    backgroundColor: "#FFD700"
  },
})(Switch);

class Proveedores extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedores: [],
      consultor: {},
      aaff: {},
      dialog: {
        opened: false,
        title: "",
        docName: "",
        proveedor: undefined
      },
      verDocumentacion: {
        opened: false,
        nombreDoc: undefined,
        proveedor: undefined
      },
      subirDocumentacion: {
        opened: false,
        nombreDoc: undefined,
        proveedor: undefined,
        fecha: undefined
      },
      isLoading: true
    }
  }
  componentDidMount() {
    firestore.collection("consultores").doc(this.props.params.nifConsultor).get().then(docConsultor => {
      if (!docConsultor.exists) {
        window.location.replace("/consultores")
      }
      this.setState({ consultor: docConsultor.data() })
    }).catch(err => window.location.replace("/consultores"))
    firestore.collection("aaff").doc(this.props.params.nifAAFF).get().then(docAAFF => {
      if (!docAAFF.exists) {
        window.location.replace("/consultores")
      }
      this.setState({ aaff: docAAFF.data() })
    }).catch(err => window.location.replace("/consultores"))
    this.loadPageContent()
  }

  loadPageContent = () => {
    
    firestore.collection("fincas").where('nifAAFF', "==", this.props.params.nifAAFF).get().then(snapshot => {
      let nifProveedores = [];
      snapshot.docs.forEach(finca => {
        finca.data().proveedores.forEach(proveedor => nifProveedores = nifProveedores.concat(proveedor.nif))
      })
      nifProveedores = [...new Set(nifProveedores)]
      let proveedores = []
      let promises = []
      nifProveedores.forEach(proveedor => {
        promises.push(firestore.collection("proveedores").doc(proveedor).get().then(p => {
          if (p.exists) {
            proveedores.push(p.data())
          }
        }))
      });
      Promise.all(promises).then(() => {

        this.setState({ proveedores: orderArray(proveedores), isLoading: false })
      })
    })
  }

  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }
  validarDocumentacion = () => {
    firestore.doc(`proveedores/${this.state.dialog.proveedor.nif}`).set({
      documentos: this.state.dialog.docName === "declaracionResponsable" ? {
        declaracionResponsable: {
          [this.props.params.nifAAFF]: {
            validado: true,
            pendiente: false
          }
        }
      } : {
        [this.state.dialog.docName]: {
          validado: true,
          pendiente: false
        }
      }
    }, { merge: true })
    this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })
    this.loadPageContent()
  }
  noValidarDocumentacion = () => {
    firestore.doc(`proveedores/${this.state.dialog.proveedor.nif}`).set({
      documentos: this.state.dialog.docName === "declaracionResponsable" ? {
        declaracionResponsable: {
          [this.props.params.nifAAFF]: {
            ruta: "",
            validado: false,
            pendiente: false
          }
        }
      } : {
        [this.state.dialog.docName]: {
          ruta: "",
          validado: false,
          pendiente: false
        }
      }
    }, { merge: true })
    this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })
    this.loadPageContent()
  }
  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }
  render() {
    if (this.state.finca === {}) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Consultor / {this.state.consultor.nombre + " " + this.state.consultor.apellidos} / {this.state.aaff.razonSocial} / <b>Proveedores</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO PROVEEDORES
              <Link to={`/consultores/${this.props.params.nifConsultor}/aaff`}><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Proveedor</th>
                      <th>NIF</th>
                      <th>Última reclamación</th>
                      <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                      <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                      <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                      <th style={{ textAlign: "center" }}>Planificación preventiva</th>
                      <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                      <th style={{ textAlign: "center" }}>Certificado SS</th>
                      <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                      <th style={{ textAlign: "center" }}>Declaración responsable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.isLoading ? <tr><td colSpan='11' style={{textAlign:'center'}}><CircularProgress style={{color:'#fc7271'}} /> </td></tr> :
                    this.state.proveedores.length === 0 ? <tr><td colSpan='11' style={{textAlign:'center'}}>No tiene proveedores</td></tr> :
                    this.state.proveedores.map((e, i) => {
                      const color = this.daysDiff(new Date(e.ultimaReclamacion?.seconds* 1000), new Date()) > 30 ? "red" : "#303248"
                      return (
                        <tr key={i}>
                          <td><Link to={"/proveedores/" + e.nif}>{e.razonSocial}</Link></td>
                          <td>{e.nif}</td>
                          <td>{e.ultimaReclamacion?.seconds === undefined ? <span style={{ color: this.daysDiff(new Date(e.fechaAlta?.seconds* 1000), new Date()) > 30 ? "red" : "#303248" }}>{new Date(e.fechaAlta.seconds * 1000).toLocaleDateString()}</span> :  <span style={{ color: this.daysDiff(new Date(e.ultimaReclamacion?.seconds* 1000), new Date()) > 30 ? "red" : "#303248" }}>{new Date(e.ultimaReclamacion.seconds * 1000).toLocaleDateString()}</span>}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.lopd?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar la LOPD de " + e.razonSocial + "?", docName: "lopd", proveedor: e } })
                            this.abrirArchivo(e.documentos.lopd?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.lopd?.validado} onClick={e.documentos.lopd?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'lopd', proveedor: e } }) : () => subirDocumentacionProveedor("lopd", e, this.loadPageContent)} />}
                            <small style={{display:'block'}}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.seguro?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar el Seguro de Responsabilidad Civil de " + e.razonSocial + "?", docName: "seguro", proveedor: e } })
                            this.abrirArchivo(e.documentos.seguro?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.seguro?.validado} onClick={e.documentos.seguro?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'seguro', proveedor: e } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'seguro', proveedor: e } })} />}
                            <small style={{display:'block'}}>{e.documentos.seguro?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(e.documentos.seguro?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.seguro?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.justificante?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar el Justificante de Pago SPA de " + e.razonSocial + "?", docName: "justificante", proveedor: e } })
                            this.abrirArchivo(e.documentos.justificante?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.justificante?.validado} onClick={e.documentos.justificante?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'justificante', proveedor: e } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'justificante', proveedor: e } })} />}
                            <small style={{display:'block'}}>{e.documentos.justificante?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(e.documentos.justificante?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.justificante?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.planificacionPreventiva?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar la Planificación Preventiva de " + e.razonSocial + "?", docName: "planificacionPreventiva", proveedor: e } })
                            this.abrirArchivo(e.documentos.planificacionPreventiva?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.planificacionPreventiva?.validado} onClick={e.documentos.planificacionPreventiva?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'planificacionPreventiva', proveedor: e } }) : () => subirDocumentacionProveedor("planificacionPreventiva", e, this.loadPageContent)} />}
                            <small style={{display:'block'}}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar la Evaluación de Riesgos de " + e.razonSocial + "?", docName: "evaluacionRiesgos", proveedor: e } })
                            this.abrirArchivo(e.documentos.evaluacionRiesgos?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.evaluacionRiesgos?.validado} onClick={e.documentos.evaluacionRiesgos?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'evaluacionRiesgos', proveedor: e } }) : () => subirDocumentacionProveedor("evaluacionRiesgos", e, this.loadPageContent)} />}
                            <small style={{display:'block'}}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.certificadoSS?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar el Certificado de la Seguridad Social de " + e.razonSocial + "?", docName: "certificadoSS", proveedor: e } })
                            this.abrirArchivo(e.documentos.certificadoSS?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.certificadoSS?.validado} onClick={e.documentos.certificadoSS?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'certificadoSS', proveedor: e } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'certificadoSS', proveedor: e } })} />}
                            <small style={{display:'block'}}>{e.documentos.certificadoSS?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.vigilancia?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar la Vigilancia de Salud de " + e.razonSocial + "?", docName: "vigilancia", proveedor: e } })
                            this.abrirArchivo(e.documentos.vigilancia?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.vigilancia?.validado} onClick={e.documentos.vigilancia?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'vigilancia', proveedor: e } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'vigilancia', proveedor: e } })} />}
                            <small style={{display:'block'}}>{e.documentos.vigilancia?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.declaracionResponsable?.[this.props.params.nifAAFF]?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                            this.setState({ dialog: { opened: true, title: "¿Desea verificar la Declaración Responsable de " + e.razonSocial + "?", docName: "declaracionResponsable", proveedor: e } })
                            this.abrirArchivo(e.documentos.declaracionResponsable?.[this.props.params.nifAAFF]?.ruta)
                          }} /> :
                            <CustomSwitch checked={e.documentos.declaracionResponsable?.[this.props.params.nifAAFF]?.validado} onClick={e.documentos.declaracionResponsable?.[this.props.params.nifAAFF]?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'declaracionResponsable', proveedor: e } }) : () => subirDeclaracionResponsable(this.props.params.nifAAFF, e, this.loadPageContent)} />}
                            <small style={{display:'block'}}>-</small>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <input type="file" id="fileElem" hidden></input>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.dialog.opened}
          onClose={() => this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              El archivo se está descargando...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.noValidarDocumentacion()} color="inherit" style={{ color: "#FF5252" }}>
              No Validar
            </Button>
            <Button onClick={() => this.validarDocumentacion()} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Validar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* VER DOCUMENTACIÓN */}
        <Dialog
          open={this.state.verDocumentacion.opened}
          onClose={() => this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, proveedor: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Qué quieres hacer con el documento?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Subir un nuevo documento implica sobreescribir el anterior
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              firestore.doc(`proveedores/${this.state.verDocumentacion.proveedor.nif}`).set({
                documentos: this.state.verDocumentacion.nombreDoc === "declaracionResponsable" ? {
                  declaracionResponsable: {
                    [this.props.params.nifAAFF]: {
                      ruta: "",
                      validado: false,
                      pendiente: false
                    }
                  }
                } : {
                  [this.state.verDocumentacion.nombreDoc]: {
                    ruta: "",
                    validado: false,
                    pendiente: false
                  }
                }
              }, { merge: true }).then(() => {
                toast.success("Documento borrado con éxito")
                this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
                this.loadPageContent()
              })
            }} color="inherit" style={{ color: "#fc7271" }}>
              Borrar documento
            </Button>
            <Button onClick={() => {
              const nombreDoc = this.state.verDocumentacion.nombreDoc
              if (["seguro", "justificante", "vigilancia", "certificadoSS"].includes(nombreDoc)) {
                this.setState({ subirDocumentacion: { opened: true, nombreDoc: nombreDoc, proveedor: this.state.verDocumentacion.proveedor } })
              } else {
                if (nombreDoc === "declaracionResponsable") {
                  subirDeclaracionResponsable(this.props.params.nifAAFF, this.state.verDocumentacion.proveedor, this.loadPageContent)
                } else {
                  subirDocumentacionProveedor(this.state.verDocumentacion.nombreDoc, this.state.verDocumentacion.proveedor, this.loadPageContent)
                }
              }
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, proveedor: undefined } })
            }} color="inherit" style={{ color: "#fc7271" }}>
              Subir nuevo
            </Button>
            <Button onClick={() => {
              this.abrirArchivo(this.state.verDocumentacion.nombreDoc === "declaracionResponsable" ? this.state.verDocumentacion.proveedor.documentos.declaracionResponsable[this.props.params.nifAAFF].ruta : this.state.verDocumentacion.proveedor.documentos[this.state.verDocumentacion.nombreDoc].ruta)
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, proveedor: undefined } })
            }} color="inherit" style={{ color: "#fc7271" }} autoFocus>
              <b>VER</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* SUBIR DOCUMENTACIÓN */}
        <Dialog
          open={this.state.subirDocumentacion.opened}
          onClose={() => this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, proveedor: undefined, fecha: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Subir documentación</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Indica la fecha de caducidad del documento
              <div>
                <input type="date" required value={this.state.subirDocumentacion.fecha} onChange={event => this.setState({ subirDocumentacion: { ...this.state.subirDocumentacion, fecha: event.target.value } })} />
              </div>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, proveedor: undefined, fecha: undefined } })
            }} color="inherit" style={{ color: "#fc7271" }} autoFocus>
              Cancelar
            </Button>
            <Button onClick={() => {
              if (this.state.subirDocumentacion.fecha !== undefined) {
                subirDocumentacionProveedor(this.state.subirDocumentacion.nombreDoc, this.state.subirDocumentacion.proveedor, this.loadPageContent, this.state.subirDocumentacion.fecha)
                this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, proveedor: undefined, fecha: undefined } })
              } else {
                toast.error("Introduce la fecha de caducidad")
              }
            }} color="inherit" style={{ color: "#fc7271" }} autoFocus>
              <b>Guardar y elegir archivo</b>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withRouter(Proveedores);

