import React, {useState, Component} from "react";
import algoliasearch from 'algoliasearch/lite'
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import { LinearProgress, withStyles, Box } from "@material-ui/core";
//import Button from '@material-ui/core/Button';
//import useObtenerMasProv from '../../../hooks/useCargarMasProv';
import { firestore } from "../../../providers/firebase";
import firebase from "firebase";
//import { withRouter } from "../../../providers/withRouter";
import Warning from "../../../icons/warning.svg"
import ExportarProveedoresLista from "../admin/ExportarProveedoresLista";
import Delete from "../../../icons/delete.svg"
import Swal from 'sweetalert2'
//import * as XLSX from "xlsx";
import {
  InstantSearch,
  connectHits,
  connectSearchBox,
  //Hits,
  //SearchBox,
  Pagination,
  Highlight,
  //ClearRefinements,
  //RefinementList,
  Configure,
  connectStateResults,
  //connectInfiniteHits
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
//import { ExportToExcel } from "../../../providers/export-excel";
//import { render } from "@testing-library/react";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
  );
}
const columnas = []
const setColumnas = (result) => {
  columnas.push({
    nif: result.nif, 
    razonSocial: result.razonSocial, 
    telefono: result.telefono,
    email: result.email,
    municipio: result.municipio, 
    estado: result.estado, 
    ultimaReclamacion: result.ultimaReclamacion !== undefined ? new Date(result.ultimaReclamacion).toLocaleDateString() : "-"
  })
  //console.log(columnas)
}
const Registros = [];
const setRegs = (numRegs) =>{
   Registros.push(numRegs)
}

/* const exportExcel = () => {
  console.log(columnas)
  const newData = columnas.map(row=>{
    delete row.tableData
    delete row.__position
    delete row.path
    delete row._highlightResult
    delete row.objectID
    return row
  })
  const workSheet = XLSX.utils.json_to_sheet(newData)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, "student")
  let buf = XLSX.write(workBook, {bookType:"xlsx", type:"buffer"})
  XLSX.write(workBook, {bookType:"xlsx", type:"binary"})
  XLSX.writeFile(workBook, "ResultadoProveedores.xlsx")
} */


const searchClient = algoliasearch(
  '4FZ79L1I7R',
  '5e4362c558e9cf1323f10a8da99ae95a'
);
const indexName = 'proveedores'

const ComprobarPendientes = (props) => {
  //console.log(props.nif)
  const [pendiente, setPendiente] = useState(false);
  firestore.collection("proveedores").where('nif', '==', props.nif).get().then(snapshot => {
    let data = []
    snapshot.docs.forEach(e => data.push(e.data()))
      
    let dr = data[0]?.documentos?.declaracionResponsable
    const hayDrPendiente = Object.values(dr).some(objeto => objeto.pendiente === true);
    
    
    data[0]?.documentos?.certificadoSS?.pendiente || hayDrPendiente || data[0]?.documentos?.evaluacionRiesgos?.pendiente || data[0]?.documentos?.justificante?.pendiente
    || data[0]?.documentos?.lopd?.pendiente || data[0]?.documentos?.planEmergencia?.pendiente || data[0]?.documentos?.planPrevencion?.pendiente || data[0]?.documentos?.planificacionPreventiva?.pendiente 
    || data[0]?.documentos?.seguro?.pendiente || data[0]?.documentos?.vigilancia?.pendiente ? setPendiente(true) : setPendiente(false)
  })
  console.log(pendiente)
  return (
    <td>{pendiente ? <Link to={`/proveedores/${props.nif}`}><img src={Warning} alt="" width="30px"/></Link> : ''} </td>
  )
}
const monthDiff = (dateFrom, dateTo) => {
  return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

const daysDiff = (dateFrom, dateTo) => {
  var diasdif= dateTo.getTime()-dateFrom.getTime();
  var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
  return contdias 
}


const eliminarProveedor = (nif) => {
  Swal.fire({
    title: 'El proveedor va a ser eliminado',
    text: "No se va a poder revertir",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Eliminar'
  }).then((result) => {
    if (result.isConfirmed) {
     
      firestore.collection("proveedores").doc(nif).delete().then(() => {
        Swal.fire(
          'Eliminado!'
        )
        window.location.reload()
        console.log("ha sido eliminado el proveedor "+nif)
      }).catch(err => {
        Swal.fire({
          title: "Error",
          text: "No se ha podido eliminar el proveedor",
          icon: "error"
        })
        console.log(err)
      })
      
    }
  })
}
/* const StateResults = ({ searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const nbHits = searchResults && searchResults.nbHits;
  setRegs(nbHits)
  return (
    <div>
      <div hidden={!hasResults}>There are {nbHits} results</div>
      <div hidden={hasResults}>There is no results</div>
    </div>
  );
};
 
const CustomStateResults = connectStateResults(StateResults); */
let fecha=null
const Hits = ({ hits }) => (
  
  //console.log(columnas.length),
  columnas.length = 0, // vaciar el array por cada busqueda que se haga para exportar a excel
  //hits.length === undefined ? hits.length=0 : hits.length= Registros[2],
  
  
  
  hits.length > 0 ? 
    hits.map(hit => (
      <>
        {setColumnas(hit),
         //LA VARIABLE fecha RECOGE UNO DE LOS DOS FORMATOS QUE SE GENERAN EN LA PLATAFORMA ALGOLIA (LA DEL BUSCADOR). 
          //AL INSERTARLOS POR PRIMERA VEZ CREA UN FORMATO Y CUANDO SE MODIFICA EN LA PLATAFORMA CAE SE GUARDA EN OTRO FORMATO.
          //dE ESTA FORMA EVITAMOS POSIBLES ERRORES.
        fecha = hit.ultimaReclamacion !== undefined && hit.ultimaReclamacion !== null && hit.ultimaReclamacion !=='' ? 
          hit.ultimaReclamacion.toString().includes(" UTC") ? 
            new Date(hit.ultimaReclamacion.toString().replace(' UTC','')).toISOString()
          : 
            hit.ultimaReclamacion
        : 
          hit.fechaAlta.toString().includes(" UTC") ? 
            new Date(hit.fechaAlta.toString().replace(' UTC','')).toISOString()
          : 
            hit.fechaAlta,
            //console.log(daysDiff(new Date(fecha), new Date())),
        console.log('')
        }
        <tr hit={hit.objectID} key={hit.objectID}>
          <td><Link to={`/proveedores/${hit.objectID}`}>{hit.razonSocial}</Link></td> 
          <td><Link to={`/proveedores/${hit.objectID}`}>{daysDiff(new Date(fecha), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(fecha).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(fecha).toLocaleDateString()}</span>}</Link></td> 
          <td><Link to={`/proveedores/${hit.objectID}`}>{hit.nif}</Link></td>
          <td><Link to={`/proveedores/${hit.objectID}`}>{hit.telefono}</Link></td>
          <td><Link to={`/proveedores/${hit.objectID}`}>{hit.email}</Link></td>
          <td><Link to={`/proveedores/${hit.objectID}`} >{hit.municipio}</Link></td>
          <td><Link to={`/proveedores/${hit.objectID}`} >{hit.provincia}</Link></td>
          <ComprobarPendientes nif={hit.nif}/>
          <td style={{ textAlign: "center" }}><LinearProgressWithLabel value={hit.estado} variant="determinate" /></td>
          {/* <td style={{ textAlign: "center" }}><img src={Delete} alt="ELIMINAR" className="tableIcon" onClick={() => eliminarProveedor(hit.nif)}></img></td> */}
        </tr>
      </>
    ))
  :
    <tr>
      <td colSpan={10} style={{textAlign:'center'}}>No se ha encontrado ningun registro</td>
    </tr>
);
const CustomHits = connectHits(Hits);

const MySearchBox = connectSearchBox(({ currentRefinement, searchState, isSearchStalled, refine }) => {
  const [busqueda, setBusqueda] = useState("");
  const [Resbusqueda, setResBusqueda] = useState("");
  const getBusqueda = () =>{
    setResBusqueda(busqueda)
    refine(busqueda)
  }
  
  return (
    <>
      <div style={{ display: "flex"}}>
        <div className="buscador">
            <input type="text" value={searchState}
                  onChange={event => setBusqueda(event.currentTarget.value)} onKeyPress={(e) => e.key === 'Enter' ? refine(busqueda) : ''}></input>
            <button onClick={() => getBusqueda()}>Buscar</button>
        </div>
        <div className="exportar">
          <ExportarProveedoresLista busqueda={Resbusqueda} /> 
        </div>
      </div>
    </>
  );
});

class BuscadorProvs extends Component {
  render(){
    return (
      <>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Administrador / <b>Proveedores</b></div>
            <div className="listPage">
                <div className="gridCard">
                <div className="gridHead">
                    LISTADO PROVEEDORES
                </div>
                <div className="tableContainer">
                    <InstantSearch indexName={indexName} searchClient={searchClient}>
                            <Configure hitsPerPage={150}  restrictSearchableAttributes = {["razonSocial", "nif", "municipio", "provincia", "email"]}/>
                            <MySearchBox />
                            <div className="tableWrapper">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Proveedor</th>
                                        <th>Última Reclamación</th>
                                        <th>Nif</th>
                                        <th>telefono</th>
                                        <th>email</th>
                                        <th>Municipio</th>
                                        <th>Provincia</th>
                                        <th></th>
                                        <th style={{ textAlign: "center" }}>Cumplimiento</th>
                                        {/* <th style={{ textAlign: "center" }}>Eliminar</th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <CustomHits />
                                    </tbody>
                                </table>
                            </div>
                            <Pagination />
                    </InstantSearch>
                    {/* <div style={{ textAlign: "center", margin: "30px auto "}} dangerouslySetInnerHTML={{__html: noReg}}></div> */}
                </div>
            </div>
        </div>
      </>
    );
  }
  
}

/* function Hit(props) {
  return (
    <div>
      <div className="hit-name">
        <Highlight attribute="razonSocial" hit={props.hit} />
      </div>
      <div className="hit-description">
        <Highlight attribute="nif" hit={props.hit} />
      </div>
      <div className="hit-price">{props.hit.municipio}</div>
    </div>
  );
}
Hit.propTypes = {
  hit: PropTypes.object.isRequired,
}; */

export default BuscadorProvs;