import React from "react"
import { Routes, Route, Link } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ProveedorIcon from "../icons/proveedor.svg";
import AlertaIcon from "../icons/alerta.svg";
import Inicio from "./pages/tec/Inicio";
import AAFF from "./pages/tec/AAFF";
import AAFFComunidades from "./pages/tec/AAFFComunidades";
import Comunidades from "./pages/tec/Comunidades";
import HojaVisita from "./pages/tec/HojaVisita";
import HojaVisitaCentroTrabajo from "./pages/tec/HojaVisitaCentroTrabajo";
import ProveedoresLista from "./pages/tec/ProveedoresLista";
import Proveedor from "./pages/tec/Proveedor";
import Alertas from "./pages/tec/alertas";


export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff/:nif/comunidades" element={<AAFFComunidades />} />
      <Route path="/comunidades" element={<Comunidades />} />
      <Route path="/comunidades/:nif" element={<HojaVisita />} />
      <Route path="/comunidades/:nif/:centroTrabajo" element={<HojaVisitaCentroTrabajo />} />
      <Route path="/alertas" element={<Alertas />} />
      <Route path="/proveedores" element={<ProveedoresLista />} />
      <Route path="/proveedores/:nifProveedor" element={<Proveedor />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <Link to="/aaff">
        <div className="menuBtn">
          <img src={Edificios} alt="Administradores de Fincas" />
          Administradores de Fincas
        </div>
      </Link>
      <Link to="/comunidades">
        <div className="menuBtn">
          <img src={Edificios} alt="Comunidades" />
          Comunidades
        </div>
      </Link>
      <Link to="/alertas">
        <div className="menuBtn">
          <img src={AlertaIcon} alt="alertas" />
          Alertas Proveedores
        </div>
      </Link>
      <Link to="/proveedores">
        <div className="menuBtn">
          <img src={ProveedorIcon} alt="Proveedores" />
          Proveedores
        </div>
      </Link>
    </>
  )
}