import React from "react";
import { firestore, auth } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import Editar from '../../../icons/editar.svg'
import ModalEditar from './editarDatosAAFF'

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedor: undefined,
      declResp: {},
      aaffs: undefined,
      modalEditarOpened: false,
      modalNotificacionOpened: false

    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    //console.log(auth.currentUser.uid);
    firestore.collection("aaff").doc(auth.currentUser.uid).get().then(snapshot => {
      let aaff = snapshot.data();
      this.setState({ aaff: aaff})

    }).catch(error => {
      //window.location.replace("/")
      console.log(error)
    })
  }

  render() {
    if (!this.state.aaff) return null //Loading data...
    return <>
      <ModalEditar aaff={this.state.aaff} opened={this.state.modalEditarOpened} handleClose={() => {
          this.setState({ modalEditarOpened: false })
          this.loadPageContent()
      }} />

      <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Administrador / <b>{this.state.aaff.razonSocial}</b></div>
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS DE ADMINISTRADOR DE FINCAS</div>
            <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <img src={Editar} alt="Editar" /></div>
          </div>
          <div className="datosGrid">
            <p><b>Nombre:</b> {this.state.aaff.razonSocial}</p>
            <p><b>NIF:</b> {this.state.aaff.nif}</p>
            <p><b>Municipio:</b> {this.state.aaff.municipio}</p>
            <p><b>Provincia:</b> {this.state.aaff.provincia}</p>
            <p><b>Email:</b> {this.state.aaff.email}</p>
            <p><b>Persona de contacto:</b> {this.state.aaff.contacto}</p>
            <p><b>Teléfono:</b> {this.state.aaff.telefono}</p>
          </div>
        </div>
      </div>
    </>
  }
}

export default withRouter(AAFF);
