import React from "react";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer"
import LogoLAE from "../../../images/LAE.png"
import planEmerg_1 from "../../../images/planEmerg_1.png"
import planEmerg_2 from "../../../images/planEmerg_2.png"
import planEmerg_3 from "../../../images/planEmerg_3.png"
import planEmerg_4 from "../../../images/planEmerg_4.png"
import planEmerg_5 from "../../../images/planEmerg_5.png"
import { firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { serviciosProveedores } from "../../../providers/serviciosProveedores";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: '2cm',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "33%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#375491',
        color: '#ffffff',
    }, 
    tableColHeader1: { 
        width: "34%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#375491',
        color: '#ffffff',
    }, 
    tableColHeaderProv: { 
        width: "33%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
    }, 
    tableColHeaderProv1: { 
        width: "34%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
    }, 
    tableColHeaderSOS: { 
        width: "50%",
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
    }, 
    tableColHeaderNM: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
    }, 
    tableCol: { 
        width: "33%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCol1: { 
        width: "34%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableColSOS: { 
        width: "50%",
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        color:'red',
    }, 
    tableColNM: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        fontSize: 8 
    },
    pdfHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        /* margin:'2cm', */
    },
    pdfHeaderImg:{
        width:'60px', 
        height:'60px',
        marginBottom:'30px',
    },
    tablePage: {
        width: '100%',
    },
    headerSpace: {
        height: '90px',
    },
    div: {
        fontSize: '10px'
    },
    h1: {
        /* marginLeft:'2cm',
        marginRight:'2cm', 
        marginTop: '50px',*/
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
    },
     h2: {
        fontSize: '11px',
        fontWeight: '600',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        marginTop: '10px',
        marginBottom: '10px',
    },
    h3: {
        fontSize: '9px',
        width: '100%',
        padding: '5px 10px',
        backgroundColor: '#f8f8f8',
        textTransform: 'uppercase',
        border: '1px solid #e6e6e6',
        marginTop: '25px',
        marginBottom: '15px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'20cm', */
    },
    h4: {
        color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '9px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    h5: {
        fontSize: '10px',
        marginTop: '15px',
        marginBottom: '5px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    textBox: {
        border: '2px solid #e6e6e6',
        padding: '15px',
        marginTop: '15px',
    },
    tableRiesgos: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: '24px',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    simpleTableTh: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    simpleTableTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    tableRiesgosTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    tableRiesgosTh: {
        backgroundColor: '#375491',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    simpleTable: {
        borderCollapse: 'collapse',
        width: '80%',
        margin: '0 10% 24px 10%',
    },
    simpleTableTh: {
        textTransform: 'uppercase',
    },
    subul: {
        lineHeight: '1.8',
        fontSize:'8px',
        flexDirection: "column", 
        width: 400,
        marginLeft: '8px',
    },
    ul: {
        lineHeight: '1.8',
        fontSize:'7px',
        flexDirection: "column", 
        width: 400,
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        fontSize: '7px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '7px',
    },
    subp: {
        lineHeight: '1.8',
        textAlign: 'justify',
        marginLeft: '8px',
        fontSize: '8px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    pageTitle: {
        textAlign: 'center',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: '20px'
    },
    page: {
        size: 'A4 portrait',
        margin: '2cm',
    },
    pagebreak: {
        clear: 'both',
        pageBreakAfter: 'always',
      }
  });

class PDFDoc extends React.Component {
    constructor() {
        super()
        this.state = {
            hojaVisita: undefined,
            //proveedores: [{servicio: 1, nombre:"b", telefono:"123456789"}, {servicio: 2, nombre:"c", telefono:"444555666"}],
            proveedores: [],
            isLoading:false,
            finca: {},
            centroTrabajo: {}
        }
    }
    async componentDidMount() {
        
        this.setState({isLoading: true})
        firestore.collection("hojasVisita").doc(this.props.params.nifFinca).get().then(doc => {
            const hojaVisita = doc.data()
            let centroTrabajo = undefined
            hojaVisita.centrosTrabajo.forEach(ct => {
                if(ct.nif === this.props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                }
            })
            console.log(centroTrabajo)
            storage.ref(centroTrabajo?.img).getDownloadURL().then(img => {
                hojaVisita['imgURL'] = img
                let fechaVisita = new Date(centroTrabajo?.fechaVisita.seconds * 1000)
                let fechaVisitaRenovacion = centroTrabajo?.fechaVisitaRenovacion === undefined ? new Date (0) : new Date(centroTrabajo?.fechaVisitaRenovacion?.seconds * 1000)
                hojaVisita['diffFechas'] = centroTrabajo?.fechaVisitaRenovacion?.seconds < centroTrabajo?.fechaVisita?.seconds
                hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                if(fechaVisitaRenovacion === undefined || hojaVisita?.diffFechas === true || fechaVisitaRenovacion.getFullYear()=== 1970){
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                    // console.log(fechaVisita.toLocaleDateString())
                }else{
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                    // console.log(fechaVisitaRenovacion.toLocaleDateString())
                }
                this.setState({ hojaVisita: hojaVisita, centroTrabajo:centroTrabajo })
            })
            
        })//.catch(() => window.location.replace('/')) 
        firestore.collection("fincas").doc(this.props.params.nifFinca).get().then(doc => {
            const finca = doc.data()
            console.log(finca)
            let proveedores = []
            let totalProveedores = []
            let nifProveedores = []
            let promises = []
            
            promises.push(firestore.collection("proveedores").get().then(prov => { 
                prov.docs.forEach( p => {
                    totalProveedores.push(p.data())
                    nifProveedores.push(p.data().nif)
                })
            }))
            Promise.all(promises).then( () => {
                if (finca?.proveedores.length === 0) this.setState({ proveedores: proveedores })
                console.log(finca?.proveedores.length)
                for (let i = 0; i < finca?.proveedores.length; i++) {
                    const proveedor = finca?.proveedores[i]
                    if(nifProveedores.includes(proveedor.nif)){
                        totalProveedores.forEach( p => {
                            if(p.nif === proveedor.nif){
                                console.log(proveedor.servicio, proveedor.nif)
                                proveedores.push({ servicio: proveedor.servicio, nombre: p.razonSocial, telefono: p.telefono })
                            }    
                        })
                    }
                    // firestore.collection("proveedores").doc(proveedor.nif).get().then((prov) => { proveedores.push({ servicio: proveedor.servicio, nombre: prov.data().razonSocial, telefono: prov.data().telefono }) })
                    if (i + 1 === finca?.nifProveedores.length) {
                        this.setState({ proveedores: proveedores })
                    } 
                }
                this.setState({isLoading: false})
            })
        })
        
    }

    render() {
        //if (this.state.hojaVisita === []) return <CircularProgress style={{color:'#fc7271', position: 'absolute', top: '50%', left: '50%'}} /> //Loading data...
        return (
            <>
                {this.state.isLoading ? <CircularProgress style={{color:'#fc7271', position: 'absolute', top: '50%', left: '50%'}} /> :
                    <PDFViewer style={{ width:"100%", height:"100vh"}}>
                        <Document size="A4" margin="2cm">
                            <Page style={styles.body}>
                                <View style={styles.pdfHeader} fixed>
                                    <Image style={styles.pdfHeaderImg} src={LogoLAE} alt="Logo LAE" />
                                </View>
                                <View>
                                    <View>
                                        <Text style={styles.h1}>PLAN DE EMERGENCIA</Text>
                                        <View ><Text style={styles.h2}>Comunidad de propietarios: {this.state.hojaVisita?.comunidad.razonSocial}</Text> </View>
                                        <View><Text style={styles.h2}>Centro de Trabajo: {this.state.centroTrabajo?.nombre}</Text> </View>
                                        <View><Text style={styles.h2}>Fecha: {this.state.hojaVisita?.fechaMostrar }</Text> </View>
                                        <Image src={this.state.hojaVisita?.imgURL} style={{height:'400px', width:'100%', marginTop:50}} alt="img" />
                                    </View>
                                </View>
                                <View break>
                                    <View ><Text style={styles.h3}>1. Introducción</Text></View>
                                    <View >
                                        <Text style={styles.p}>
                                            Según el artículo 20 de la Ley de Prevención de Riesgos Laborales, y su reforma, la Ley 54/2003: “<Text style={{fontWeight:'black'}}>el empresario deberá analizar las posibles situaciones de emergencia y adoptar las medidas necesarias en materia de primeros auxilios, lucha contra incendios y evacuación de los trabajadores</Text>”<br />
                                        </Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>
                                            Se entiende por emergencia, aquellas situaciones derivadas de sucesos que ocurren de una forma anómala, repentina y no deseada, que liberan energía muy rápidamente y en tal cantidad que pueden poner en peligro a muchas personas, afectar a varias instalaciones, e incluso al entorno de la comunidad de propietarios, si no se logran controlar en los primeros momentos.<br />
                                        </Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>
                                            Las comunidades de propietarios, que cuentan con un plan de emergencia, son capaces de reanudar la actividad productiva con mayor rapidez y menor coste que las que no lo tienen realizado, en caso de emergencia. Por otra parte, las compañías de seguros valoran la existencia y puesta en práctica de los ensayos de los referidos planes.<br />
                                        </Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>
                                            En muchas ocasiones, más de las deseadas, no existe una planificación de la emergencia, porque:
                                        </Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Es difícil imaginar que a uno le suceda.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Requiere invertir tiempo y esfuerzo.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Existen diferentes factores que pueden producir una situación de emergencia:</Text>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Fallos humanos en la actividad de la comunidad de propietarios.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Fallos técnicos.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Defectos de diseño de instalaciones y organización.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Causas de la naturaleza: catástrofes naturales (riadas, terremotos, etc.)</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Causas del entorno: por instalaciones externas, etc.</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View break>
                                        <View ><Text style={styles.h3}>2. PLAN DE EMERGENCIA</Text></View>
                                        <View>
                                            <Text style={styles.h4}>2.1. Objeto</Text>
                                            <Text style={styles.p}>
                                                El plan de emergencia pretende conseguir que cualquier incidente, que pueda afectar a las personas, las propias instalaciones y el edificio, así como la continuidad de las instalaciones, tenga una incidencia mínima o nula. Para hacerlo posible, se debe conseguir delante de una emergencia, una buena coordinación, en el tiempo y el espacio, tanto de las personas afectadas como de los medios de protección existentes, de manera que sean utilizados eficazmente para obtener, según la emergencia:
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.ul}>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>Una rápida evacuación de los locales</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>La extinción del incendio.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>La limitación de los daños materiales.</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={styles.h4}>2.2. Factores de riesgo. Clasificación de la emergencia</Text>
                                            <Text style={styles.p}>
                                                Según la gravedad, las emergencias se clasifican en:
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.ul}>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>Conato de emergencia: Accidente que puede ser controlado y dominado de forma sencilla y rápida por el personal y los medios de protección del local, dependencia o sector.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>Emergencia parcial: Accidente que para ser dominado requiere la actuación de los equipos especiales de emergencia del sector. Los efectos de la emergencia parcial quedarán limitados a un sector y no afectarán a otros sectores colindantes ni a terceras personas.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>Emergencia general: Accidente que precisa la actuación de todos los equipos y medios de protección del establecimiento y la ayuda de medios de socorro y salvamento exteriores. La emergencia general comportará la evacuación de las personas de determinados sectores.</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={styles.h4}>2.3. Acciones en caso de emergencia</Text>
                                            <Text style={styles.p}>
                                                Las diversas emergencias requerirán la intervención de personas y medios para garantizar en todo momento:
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.ul}>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>La alerta, que de la forma más rápida posible, pondrá en acción a los equipos del personal de primera intervención interior e informará a los restantes equipos del personal interior y a las ayudas exteriores.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>La alarma para la evacuación de los ocupantes.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>La intención para el control de las emergencias.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>El apoyo para la recepción e información a los servicios de ayuda exterior.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>Primeros auxilios.</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={styles.h4}>2.4. Equipos de emergencia</Text>
                                            <Text style={styles.p}>
                                            Son el conjunto de personas especialmente entrenadas y organizadas para actuar en caso de accidente/incidente dentro del ámbito del establecimiento. La misión fundamental de estos equipos es tomar las precauciones pertinentes para que las condiciones que puedan originar un accidente no se vuelvan a dar.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.p}>
                                                Cada uno de los componentes de los equipos de emergencia deberá de:
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.ul}>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>a.</Text>
                                                    <Text>Estar informado del riesgo general y particular que presentan los diferentes procesos dentro de la actividad.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>b.</Text>
                                                    <Text>Señalar las anomalías que se detectan y verificar que han estado subsanadas.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>c.</Text>
                                                    <Text>Tener conocimiento de la existencia y uso de los medios materiales de que se disponga.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>d.</Text>
                                                    <Text>Hacerse cargo del mantenimiento de medios.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>e.</Text>
                                                    <Text>Estar capacitado para suprimir las causas que puedan provocar cualquier anomalía.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>f.</Text>
                                                    <Text>Combatir el fuego desde su descubrimiento.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>g.</Text>
                                                    <Text>Prestar los primeros auxilios a las personas accidentadas.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>h.</Text>
                                                    <Text>Coordinarse con los miembros de otros equipos para anular los efectos de los accidentes o reducirlos al mínimo.</Text>
                                                </View>
                                            </View>
                                            <Text style={styles.br}></Text>
                                            <View style={{marginHorizontal:8}}>
                                                <Text style={styles.subp }>
                                                    En función de las acciones que desarrollan los miembros de los equipos se denominan:
                                                </Text>
                                                <View style={styles.subul}>
                                                    <View style={styles.ol}>
                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                        <Text>Equipos de alarma y evacuación (EAE). Sus componentes realizarán acciones dirigidas a asegurar una evacuación total y ordenada del sector y garantizar que hayan dado la alarma.</Text>
                                                    </View>
                                                    <View style={styles.ol}>
                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                        <Text>Equipos de primeros auxilios (EPA). Sus componentes prestarán los primeros auxilios a los lesionados por la emergencia.</Text>
                                                    </View>
                                                    <View style={styles.ol}>
                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                        <Text>Equipos de primera intervención (EPI). Sus componentes acudirán al lugar donde se haya producido la emergencia con el objetivo de intentar controlarlo. Cualquier trabajador que descubra el principio de un incendio dará la alarma y si tiene conocimientos de extinción utilizará los extintores portátiles más cercanos hasta la llegada del Equipo de Primera Intervención de la planta, zona o sector.</Text>
                                                    </View>
                                                    <View style={styles.ol}>
                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                        <Text>Equipos de segunda intervención (ESI). Los componentes actuaran cuando la emergencia no pueda ser controlada por los equipos de primera intervención, apoyando a los servicios de ayuda exterior cuando su actuación sea necesaria.</Text>
                                                    </View>
                                                    <View style={styles.ol}>
                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                        <Text>Jefe de Intervención. Valorará la emergencia y asumirá la dirección y coordinación de los equipos de intervención.</Text>
                                                    </View>
                                                    <View style={styles.ol}>
                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                        <Text>Jefe de Emergencia. Es el máximo responsable en caso de emergencia. Ha de ser una persona responsable, con capacidad de decisión y conocimientos del edificio. Desde el Centro de comunicaciones del establecimiento y en función de la información que facilite el Jefe de Intervención sobre la evolución de la emergencia, enviará al área siniestrada las ayudas internas disponibles y solicitará las externas que sean necesarias para control de la misma. A la llegada de los bomberos, se les informará de la situación y estado de la emergencia.</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.p }>
                                                La Orden Ministerial de 29 de noviembre de 1984 hace una serie de recomendaciones sobre los equipos de emergencia que ha de tener los edificios, en función de la clasificación de su riesgo. A nivel orientativo, la constitución de los equipos de emergencia se ha de adaptar a las características y al número de personas que haya en el edificio. Se recomendará constituir los equipos siguientes:
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.table}> 
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableColHeader}> 
                                                        <Text style={styles.tableCell}>Riesgo alto</Text> 
                                                    </View> 
                                                    <View style={styles.tableColHeader}> 
                                                        <Text style={styles.tableCell}>Riesgo medio</Text> 
                                                    </View> 
                                                    <View style={styles.tableColHeader1}> 
                                                        <Text style={styles.tableCell}>Riesgo bajo</Text> 
                                                    </View> 
                                                </View>
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipos de Alarma y Evacuación </Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipos de Primera Intervención </Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Equipos de Primera Intervención </Text> 
                                                    </View> 
                                                </View> 
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipo de Primeros Auxilios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipos de Primera Intervención</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View>
                                                </View> 
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipos de Primera Intervención</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipo de Segunda Intervención</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View>
                                                </View> 
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Equipo de Segunda Intervención</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jefe de Intervención</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View>
                                                </View> 
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jefe de Intervención</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View>
                                                </View> 
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jefe de Emergencia</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View>
                                                </View> 
                                            </View>
                                        </View>
                                    </View>
                                    <View break>
                                        <View ><Text style={styles.h3}>3. IMPLANTACIÓN</Text></View>
                                        <View>
                                            <Text style={styles.h4}>3.1. Responsabilidad</Text>
                                            <Text style={styles.p}>
                                                De conformidad con lo previsto en la legislación vigente, el personal directivo, técnico, mandos intermedios y trabajadores de los establecimientos estarán obligados a practicar en los planos de autoprotección.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h4}>3.2. Organización</Text>
                                            <Text style={styles.p}>
                                                El titular de la actividad podrá delegar la coordinación de las acciones necesarias para la implantación y mantenimiento del plan de autoprotección en un Jefe de Seguridad, que en caso de emergencia podrá asumir las funciones del Jefe de Emergencia. De conformidad con lo previsto en la legislación vigente, el personal directivo, técnico, mandos intermedios y trabajadores de los establecimientos estarán obligados a practicar en los planos de autoprotección.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h4}>3.3. Medios técnicos</Text>
                                            <Text style={styles.p}>
                                                Las instalaciones, tanto las de protección contra incendios como las que son susceptibles de ocasionarlos, serán sometidas a las condiciones generales de mantenimiento y uso establecidas en la legislación vigente y en la Norma Básica de Edificación-Condiciones de Protección contra incendios.
                                                Cuando así lo exija la Reglamentación vigente o el plan tipo para cada uso, se dotará al establecimiento de todas las instalaciones de prevención precisas.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h4}>3.4. Medios humanos</Text>
                                            <Text style={styles.p}>
                                                Se programarán, al menos, una vez al año, cursos de formación y adiestramiento para equipos de emergencia y sus responsables.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h4}>3.5. Programa de implantación</Text>
                                            <Text style={styles.p}>
                                                Se programará, ateniendo a las prioridades y con el calendario correspondiente, la siguiente actividad: inventario de los medios técnicos de autoprotección.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h4}>3.6. Programa de mantenimiento</Text>
                                            <Text style={styles.p}>
                                                Se preparará un programa anual con el correspondiente calendario, que comprenderá las siguientes actividades:
                                            </Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.ul}>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>a.</Text>
                                                    <Text>Mantenimiento de las instalaciones susceptibles de provocar un incendio</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>b.</Text>
                                                    <Text>Mantenimiento de las instalaciones de detección, alarma y extinción de incendios según lo establecido en la Norma Básica de la Edificación.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>c.</Text>
                                                    <Text>Inspecciones de Seguridad.</Text>
                                                </View>
                                            </View>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h4}>3.7. Investigación de siniestros</Text>
                                            <Text style={styles.p}>
                                                Si se produjera una emergencia en el establecimiento, se investigarán las causas que posibilitaron su origen, propagación y consecuencias, se analizará el comportamiento de las personas y los equipos de emergencia y se adoptarán las medidas correctoras precisas.
                                            </Text>
                                            <Text style={styles.br}></Text>
                                        </View>
                                    </View>    
                                    <View>
                                        <Text style={styles.h4}>3.8. Comportamiento en caso de emergencia</Text>
                                        <Image src={planEmerg_1} style={{ width: '100%', marginTop: 24 }} ></Image>
                                    </View>
                                    <View break>
                                        <Image src={planEmerg_2} style={{ width: '100%', marginTop: 24 }} ></Image>
                                    </View>
                                    <View break>
                                        <Image src={planEmerg_3} style={{ width: '100%', marginTop: 24 }} ></Image>
                                    </View>
                                    <View break>
                                        <Image src={planEmerg_4} style={{ width: '100%', marginTop: 24 }} ></Image>
                                    </View>
                                    <View break>
                                        <Image src={planEmerg_5} style={{ width: '100%', marginTop: 24 }} ></Image>
                                    </View>
                                    <View break>
                                        <Text style={styles.pageTitle}>PLAN DE ACTUACIÓN EN CASO DE ACCIDENTE</Text>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>En caso de accidente leve de un trabajador que requiera atención médica: Realizar la primera cura con el material sanitario disponible en el botiquín de la comunidad de propietarios y trasladar al accidentado al Centro Asistencial de la Mutua o a un Centro de urgencias.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>En caso de accidente grave de un trabajador: Trasladar urgentemente al trabajador al Hospital señalado en el apartado 1.3 del presente informe, o al Centro de Urgencias más cercano.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>En los dos casos de accidente se tiene que activar el sistema de emergencia (PAS: Proteger, Avisar, Socorrer). Al comunicarse, se tiene que dar un mensaje preciso sobre:</Text>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>En caso de accidente leve de un trabajador que requiera atención médica: Realizar la primera cura con el material sanitario disponible en el botiquín de la comunidad de propietarios y trasladar al accidentado al Centro Asistencial de la Mutua o a un Centro de urgencias.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>En caso de accidente grave de un trabajador: Trasladar urgentemente al trabajador al Hospital señalado en el apartado 1.3 del presente informe, o al Centro de Urgencias más cercano.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>En los dos casos de accidente se tiene que activar el sistema de emergencia (PAS: Proteger, Avisar, Socorrer). Al comunicarse, se tiene que dar un mensaje preciso sobre:</Text>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Lugar donde ha sucedido el accidente.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Tipo de accidente (intoxicación, quemada térmica o química, herida, etc.).</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Número de víctimas.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Estado aparente de las víctimas (conciencia, sangran, respiran, etc.).</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Si el aviso se realiza por teléfono, no colgar antes de que el interlocutor lo haya autorizado, ya que puede necesitar otras informaciones complementarias.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Se dispondrá de una persona que reciba y acompañe a los servicios de socorrismo con la finalidad de guiarlos rápidamente hasta el lugar del accidente.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h4}>Como actuar si se tiene que socorrer a un herido</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>1. Salpicaduras en los ojos y sobre la piel</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Sin perder un instante lavarse con agua durante 10 o 15 minutos, empleando si es necesario la ducha de los vestuarios (o de seguridad si existiesen); quitarse la ropa y objetos previsiblemente mojados del producto.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>2. Mareo o pérdida de conocimiento debido a una fuga tóxica que persista.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Nos tenemos que proteger del medio con un aparato respiratorio ante de aproximarse a la víctima. Trasladar al accidentado a un lugar seguro i dejarlo recostado sobre el lado izquierdo. Aflojar la ropa y todo lo que pueda oprimirlo, verificando si ha perdido el conocimiento y si respira; tomar el pulso. Activar el PAS, y practicar si fuese necesario, la reanimación cardiorrespiratoria. No subministrar alimentos, bebidas ni productos para activar la respiración.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>3. Electrocución.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>La electrocución o choque eléctrico tiene lugar cuando, por un contacto eléctrico directo o indirecto, una persona pasa a formar parte de un circuito eléctrico, pasando por su organismo una determinada intensidad eléctrica durante un tiempo. La intensidad depende del voltaje y de la resistencia del organismo, que a su vez, depende del camino recorrido y de factores fisiológicos. Las acciones para llevar a cabo cuando alguno queda atrapado por la corriente son las siguientes,</Text>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Cortar la alimentación eléctrica del aparato causante del accidente antes de acercarse a la víctima para evitar otro accidente y retirar al accidentado.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Activar el PAS y practicar, si es necesario, la reanimación cardiorrespiratoria.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>No suministrar alimentos, bebidas ni productos para activar la respiración.</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={styles.h5}>4. Quemadas térmicas</Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.p}>Las instrucciones básicas por el tratamiento de quemadas térmicas son: lavar abundantemente con agua fría para enfriar la zona quemada, no quitarse la ropa pegada en la piel, tapar la parte quemada con la ropa limpia. Se tiene que acudir siempre al médico, aunque la superficie afectada y la profundidad sean pequeñas. Son recomendaciones específicas en estos casos;</Text>
                                            <Text style={styles.br}></Text>
                                            <View style={styles.ul}>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>No aplicar nada en la piel (ni pomada, ni grasa, ni desinfectante).</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>No enfriar demasiado al accidentado.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>No dar bebidas ni alimentos.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>No romper las ampollas.</Text>
                                                </View>
                                                <View style={styles.ol}>
                                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                    <Text>No dejar solo al accidentado.</Text>
                                                </View>
                                            </View>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.h5}>5. Intoxicación digestiva</Text>
                                            <Text style={styles.br}></Text>
                                            <Text style={styles.p}>Se tiene que tratar en función del contenido del tóxico ingerido, por lo cual se tiene que disponer de información a partir de la etiqueta y de la ficha de seguridad. La actuación inicial está encaminada a evitar la acción directa del tóxico mediante su neutralización o evitar su absorción por el organismo. Posteriormente, o en paralelo, se tratan los síntomas causados por el tóxico. Es muy importante la atención médica rápida, lo cual requerirá el traslado del accidentado, que debe llevarse a cabo en condiciones adecuadas. No se tiene que provocar el vómito cuando el accidentado presente convulsiones o esté inconsciente, o bien se trata de un producto corrosivo o volátil. Para evitar la absorción del tóxico se emplea carbón activo o agua albuminosa. Existe una lista de antídotos recomendados por la UE (Anexo III de la Resolución 90/329/03). En el caso de pequeñas ingestas de ácidos, beber soluciones de bicarbonato, mientras que se recomienda tomar bebidas ácidas. Servicio de Toxicología: 91 562 04 02.</Text>
                                        </View>
                                    </View>
                                    <View break>
                                        <Text style={styles.pageTitle}>PLAN DE ACTUACIÓN EN CASO DE INCENDIO</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>Clasificación de las emergencias</Text>
                                        <Text style={styles.p}>En función de la magnitud del siniestro y de la gravedad de sus posibles consecuencias, las emergencias las clasificamos en:</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Conato de emergencia: Accidente que puede ser controlado y dominado de forma sencilla y rápida por el personal de la comunidad de propietarios, con los medios de protección de la misma.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Emergencia general: Accidente que, además de necesitar la actuación de todos los equipos y medios de protección de la comunidad de propietarios, necesita la ayuda de medios de socorro y salvamente externo.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>Acciones a tomar</Text>
                                        <Text style={styles.p}>Alerta: Cualquier persona que se encuentre presente en el área de un siniestro dará alerta al resto de personal, ya sea conato de emergencia o emergencia general. La alerta de las ayudas externas, únicamente se dará en el caso de necesidad (ambulancia) o Emergencia General.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Evacuación: Todo el personal de la comunidad de propietarios está capacitado para realizar la evacuación de las personas que ocupan la zona del siniestro. Únicamente se procederá a la evacuación que en algún momento pueden haber varias personas en las instalaciones de la comunidad de propietarios.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Intervención: La persona designada intervendrá, ya sea en caso de Conato de Emergencia o de Emergencia General, utilizando los medios existentes (extintores) y suspendiendo el fluido eléctrico si procede.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Recepción de ayuda exterior: Cualquier persona de la comunidad de propietarios puede recibir y ser la interlocutora con los mandos de los Servicios Exteriores de Emergencia, para coordinar con las actuaciones y ganar en eficacia.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>Como evacuar un edificio en llamas</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>El último en salir de la habitación cerrará la perta (nunca con llave), comprobando que no quede nadie dentro. El cerrar las puertas dificulta el esfuerzo de rescate y de búsqueda por parte del departamento de Bomberos.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Dirigirse hacia la salida más cercana.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>No utilizar los ascensores bajo ningún concepto.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Manténgase cerca del suelo para evitar humos y gases tóxicos. La mejor atmosfera respirable se encuentra a nivel del suelo, así que circule a cuatro patas.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Si es posible cubrirse la boca y la nariz con un trapo o pañuelo húmedo para facilitar la respiración.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Si trabaja en un edificio con diferentes pisos, las escaleras serán su primera ruta de escapada. Una vez en la escalera, proceda hacia el primer piso, y nunca se dirija hacia un piso más alto.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Una vez fuera del edificio, diríjase al área preestablecida por tal de facilitar el recuento de personal.</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View break>
                                        <Text style={styles.h5}>¿Qué hacer si se está atrapado en un edificio en llamas?</Text>
                                        <Text style={styles.p}>Si está probando de escapar de un fuego, nunca debe abrir ninguna puerta cerrada sin palparla. Utilice la parte posteriormente de la mano para evitar quemadas. Si la puerta está caliente, busque otra salida. Si no existe otra salida, selle las grietas alrededor de las puertas y ventanas con lo que tenga a mano.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Si respira con dificultad, pruebe de ventilar la habitación y utilice un pañuelo mojado.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.h5}>¿Qué hacer si una persona está envuelta en llamas?</Text>
                                        <Text style={styles.p}>Si usted se encuentra envuelta en llamas:</Text>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Deténgase.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Tírese al suelo.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Ruede sobre sí mismo.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Esto le apagará las llamas y le puede salvar la vida. RECUERDE LOS PASOS ESTABLECIDOS.</Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>Si un compañero está envuelto en llamas, se tendrá que apagar envolviéndolo con una manta, alfombra, abrigo, esto puede salvarlo de graves quemadas e incluso de la muerte.</Text>
                                    </View>
                                    <View break>
                                        <View ><Text style={styles.h3}>4. RECOMENDACIONES</Text></View>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Los aparatos eléctricos no indispensables se deben apagar y desconectar al final de la jornada.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>No sobrecargar los enchufes.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Evitar la acumulación de materias y productos inflamables y alejarlos de toda fuente de calor.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Colocar los productos de limpieza inflamables en los armarios o locales cerrados con llave y ventilados.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Mantener cerrados los cubos de basura.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Asegurarse de cerrar las llaves de paso generales una vez acabados los servicios, la desconexión de los aparatos eléctricos, especialmente por la noche.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Es necesario saber distinguir los diferentes tipos de extintores y la forma de utilizarlos.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Si se han utilizado, deberán volverse a recargar de inmediato.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>En general, si se produce un incendio no se debe intentar apagar sólo por una persona, salvo que sea un conato y se esté seguro de que no ofrece ningún tipo de riesgo.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Los dispositivos de extinción de incendios (extintores) deben ser fácilmente visibles. No colocar obstáculos delante de ellos que dificulten la accesibilidad.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>En caso de incendio, si el humo es abundante, camine agachado. El humo es más ligero que el aire y tenderá a concentrarse en la parte superior.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>No abrir las puertas de golpe, ni de frente.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Si la puerta de un local está caliente, significa que el fuego está próximo. Se evitará acercarse a ella.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>No se debe intentar recoger objetos valiosos o efectos personales.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>No se deberá realizar ninguna operación que entrañe peligro de incendio.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Para coordinar las acciones relativas por cada trabajador ante una situación de emergencia, se deberá elaborar un Plan de Emergencia.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>El centro de trabajo debe estar equipado de sistema de detección de incendios (como mínimo de sistema manual de alarma de incendios) y equipos de protección contra incendios adecuados para poder llevar a cabo una actuación eficaz.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Los equipos de protección contra incendios que se emplearán serán extintores. Éstos se dispondrán de manera que puedan ser utilizados de manera rápida y fácil, que sean visibles, accesibles y próximos a puntos de riesgo de incendio y a las salidas; siempre que sea posible, se situarán en los paramentos de forma tal que el extremo superior del extintor se encuentre a una altura sobre el suelo menor que 1,70m. Se comprobará cada tres meses el estado aparente de conservación, de carga y del botellín de gas impulsor. Se verificará su carga con una periodicidad anual y cada 5 años se retimbrará el extintor de acuerdo con el Reglamento de Aparatos a Presión sobre Extintores de Incendios.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text>Todos los equipos de protección contra incendios estarán señalizados, así como las salidas de emergencia y su dirección. Se indicará teléfono de emergencia en lugar bien visible, además de formar e informar a los trabajadores de la situación de los sistemas de alarma y de las actuaciones a seguir en caso de incendio, designando a una serie de personal para realizar determinadas funciones para estos casos, especificados en el Plan de Emergencia.</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View break>
                                        <View ><Text style={styles.h3}>5. TELÉFONOS DE UTILIDAD</Text></View>
                                        <Text style={styles.br}></Text>
                                        <View style={styles.table}> 
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tableColHeaderSOS}> 
                                                    <Text style={styles.tableCell}></Text> 
                                                </View> 
                                                <View style={styles.tableColHeaderSOS}> 
                                                    <Text style={styles.tableCell}>Teléfono</Text> 
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tableColSOS}> 
                                                    <Text style={styles.tableCell}>COORDINACIÓN DE EMERGENCIAS</Text> 
                                                </View> 
                                                <View style={styles.tableColSOS}> 
                                                    <Text style={styles.tableCell, {fontWeight:'bold', textAlign:'center'}}>112 </Text> 
                                                </View>    
                                            </View> 
                                        </View>
                                        <View ><Text style={styles.h3}>6. DIRECTORIO DEL PERSONAL DEL CENTRO DE TRABAJO</Text></View>
                                        <Text style={styles.p}>
                                            <Text style={styles.bold}>CENTRO DE TRABAJO: </Text>{this.state.centroTrabajo?.nombre}
                                        </Text>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.pageTitle}>EMPRESAS DE MANTENIMIENTO</Text>
                                        <View style={styles.table}>
                                            {this.state.proveedores.length !== 0 ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableColHeaderProv}> 
                                                        <Text style={styles.tableCell}>Servicio</Text> 
                                                    </View> 
                                                    <View style={styles.tableColHeaderProv}> 
                                                        <Text style={styles.tableCell}>Nombre</Text> 
                                                    </View> 
                                                    <View style={styles.tableColHeaderProv1}> 
                                                        <Text style={styles.tableCell}>Teléfono</Text> 
                                                    </View> 
                                                </View>  
                                            :
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableColHeaderNM}> 
                                                        <Text style={styles.tableCell}>NO TIENE PROVEEDORES</Text> 
                                                    </View> 
                                                </View>
                                            }                                             
                                                
                                            {this.state.proveedores.length !== 0 ? 
                                                this.state.proveedores.map((proveedor, i) => {
                                                    return (
                                                        <View key={i} style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>{serviciosProveedores[proveedor.servicio]}</Text> 
                                                            </View>
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>{proveedor.nombre}</Text> 
                                                            </View>
                                                            <View style={styles.tableCol1}> 
                                                                <Text style={styles.tableCell}>{proveedor.telefono}</Text> 
                                                            </View>
                                                        </View>
                                                    )
                                                })
                                            : 
                                                <></>
                                            }
                                                
                                        </View>
                                        <View ><Text style={styles.h3}>7. TÉCNICO ASOCIADO</Text></View>
                                        <Text style={styles.p}>
                                            El informe ha sido realizado por <Text style={styles.bold}>{this.state.hojaVisita?.tecnico.nombre}</Text>, técnico en prevención de riesgos laborales  
                                        </Text>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                }
            </>
            
        )
    }
}







export default withRouter(PDFDoc);