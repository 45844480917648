import React from "react";
import Portada from "../../../images/PortadaProveedor.png"
import Encabezado from "../../../images/Encabezado.jpg"
// import Logo from "../../../images/LogoLAE.png"
import Logo from "../../../images/LogoLAENEW.png"
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Caducado from "../../../icons/caducado.png"
import { firestore } from "../../../providers/firebase"
import orderArray from "../../../providers/orderArray";
import { withRouter } from "../../../providers/withRouter";

class InformeSituacionProveedores extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedores: undefined
    }
  }
  componentDidMount() {
    firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).get().then(snapshot => {
      let nifProveedores = []
      let proveedores = []
      snapshot.docs.forEach(finca => {
        if(finca.data().activo !== false && finca.data().activo !== 'false'){
          nifProveedores = nifProveedores.concat(finca.data().nifProveedores)
        }
      })
      nifProveedores = [...new Set(nifProveedores)]
      console.log(nifProveedores)
      let promises = []
      nifProveedores.forEach(nifProv => {
        let pr = 0
        let pe = 0
        let numFincas = 0
        let iconoPrevencionRiesgos = null
        let iconoPlanEmergencia = null
        let provs = {}
        promises.push(firestore.collection("proveedores").doc(nifProv).get().then(doc => {
          if(doc.exists){ 
            provs = doc.data()
            promises.push(snapshot.docs.forEach(finca => {
              if(finca.data().nifProveedores.includes(nifProv)){ numFincas++}
              if(finca.data().documentos.prevencionRiesgos.leido?.[nifProv] !== undefined || finca.data().documentos.evaluacionRiesgos.leido?.[nifProv] !== undefined ){ pr++}
              if(finca.data().documentos.planEmergencia.leido?.[nifProv] !== undefined ){ pe++}
              console.log(finca.data().documentos.prevencionRiesgos.leido?.[nifProv])
            }))
            
            if(numFincas === pr){
              iconoPrevencionRiesgos = "tick"
            }else{
              iconoPrevencionRiesgos = "cross"
            }
            if(numFincas === pe){
              iconoPlanEmergencia = "tick"
            }else{
              iconoPlanEmergencia = "cross"
            }
            provs.prevencionRiesgos = pr
            provs.planEmergencia = pe
            provs.numFincas = numFincas
            provs.iconoPrevencionRiesgos = iconoPrevencionRiesgos
            provs.iconoPlanEmergencia = iconoPlanEmergencia
            proveedores.push(provs)
          }
          //console.log(proveedores)
        }))
      })
        
      Promise.all(promises).then(() => this.setState({ proveedores: orderArray(proveedores) }, () => {
        var src = document.getElementById('portada').style.backgroundImage
        var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');

        var img = new Image();
        img.onload = function () {
          window.print()
        }
        img.src = url;
        if (img.complete) img.onload();

        //console.log(this.state.proveedores)
        /* console.log("numFincas "+ numFincas+ "=? pr "+pr)
        if(numFincas === pr){
          this.setState({icono: 'tick'})
        }else{
          this.setState({icono: 'cross'})
        } */
      }))
    })
  }
  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  render() {
    if (this.state.proveedores === undefined) return 'Loading...'
    return (
      <>
        <div className="pagina" style={{ paddingTop: 0, paddingBottom:55}}>
            <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}></div>
        </div>
        <div className="pagebreak"></div>
        <table>
          <thead><tr><td style={{ border: 'none' }}>
            <div ><img alt='logo' src={Logo} className="logoHeader" /></div>
          </td></tr></thead>
          <tbody>
            {/* <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}>
            </div>*/}
            {/* <div className="pagebreak"></div>  */}
            <div className="pagina paginaPrimera">
              {/* <div style={{ height: 60 }}></div> */}
              <p style={{ paddingBottom: 30 }}>
                Estimado administrador de fincas.
              </p>
              <p>
                Tras los primeros meses de trabajo administrativo y reclamación de la documentación a los proveedores os mostramos a continuación el informe de situación documental:<br />
              </p>
              <table className="simpleTable">
                <thead>
                  <tr>
                    <th width="40%">Proveedor</th>
                    <th>NIF</th>
                    <th>Última Reclamación</th>
                    <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                    <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                    <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                    <th style={{ textAlign: "center" }}>Planificación preventiva</th>
                    <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                    <th style={{ textAlign: "center" }}>Certificado Seg. Soc.</th>
                    <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                    <th style={{ textAlign: "center" }}>Declaración responsable</th>
                    {/* <th style={{ textAlign: "center" }}>Prevención Riesgos</th>
                    <th style={{ textAlign: "center" }}>Plan Emergencia</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.proveedores.map((e, i) => {
                    
                    return <tr key={i}>
                      <td>{e.razonSocial}</td>
                      <td>{e.nif}</td>
                      <td>{e.ultimaReclamacion === undefined ? new Date(e.fechaAlta.seconds * 1000).toLocaleDateString() : new Date(e.ultimaReclamacion.seconds * 1000).toLocaleDateString()}</td>
                      <td style={{ textAlign: "center" }}>{(e.documentos?.lopd?.validado || e.documentos?.lopd?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{this.daysDiff(new Date(e.documentos?.seguro?.fechaCaducidad?.seconds*1000), new Date()) > 30 ? <img alt='caducado' src={Caducado} /> : (e.documentos?.seguro?.validado || e.documentos?.seguro?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{this.daysDiff(new Date(e.documentos?.justificante?.fechaCaducidad?.seconds*1000), new Date()) > 30 ? <img alt='caducado' src={Caducado} /> : (e.documentos?.justificante?.validado || e.documentos?.justificante?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{(e.documentos?.planificacionPreventiva?.validado || e.documentos?.planificacionPreventiva?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{(e.documentos?.evaluacionRiesgos?.validado || e.documentos?.evaluacionRiesgos?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{this.daysDiff(new Date(e.documentos?.certificadoSS?.fechaCaducidad?.seconds*1000), new Date()) > 30 ? <img alt='caducado' src={Caducado} /> : (e.documentos?.certificadoSS?.validado || e.documentos?.certificadoSS?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{this.daysDiff(new Date(e.documentos?.vigilancia?.fechaCaducidad?.seconds*1000), new Date()) > 30 ? <img alt='caducado' src={Caducado} /> : (e.documentos?.vigilancia?.validado || e.documentos?.vigilancia?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{(e.documentos?.declaracionResponsable?.[this.props.params.nif]?.validado || e.documentos?.declaracionResponsable?.[this.props.params.nif]?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      {/* <td style={{ textAlign: "center" }}>{e.iconoPrevencionRiesgos === 'tick' ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{e.iconoPlanEmergencia === 'tick' ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td> */}
                    </tr>
                  })}
                </tbody>
              </table>
              <div>
                <span><p><img alt='valido' width={20} src={Tick} />Valido</p></span>
                <span><p><img alt='no valido' width={20} src={Cross} />No Valido</p></span>
                <span><p><img alt='caducado' width={20} src={Caducado} /> Caducado</p></span>
              </div>
              <p>
                La obtención de esta documentación es fundamental para asegurar que los proveedores que acceden a las comunidades de propietarios cumplen debidamente con las obligaciones normativas (seguros de responsabilidad civil, modalidad preventiva en vigor, obligaciones en materia laboral, etc.).<br/>
              </p>
              <div className="pagebreak"></div>
              <p>
                Al mismo tiempo os informamos de la relación de proveedores que se han descargado la documentación relativa a la evaluación de riesgos y/o plan de emergencia de las comunidades que prestan servicio: <br/>
              </p>
              <table className="simpleTable">
                <thead>
                  <th>Proveedor</th>
                  <th>NIF</th>
                  <th>LECTURA DE PLANES DE PREVENCIÓN</th>
                  <th>LECTURA DE PLANES DE EMERGENCIA</th>
                </thead>
                <tbody>
                  {this.state.proveedores.map((e, i) => {
                    return e.iconoPlanEmergencia=== 'tick' || e.iconoPrevencionRiesgos=== 'tick' ? 
                     <tr>
                      <td>{e.razonSocial}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}>{e.iconoPrevencionRiesgos === 'tick' ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                      <td style={{ textAlign: "center" }}>{e.iconoPlanEmergencia === 'tick' ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                    </tr>
                    : ''
                  })}
                </tbody>
              </table>
              <p>  
                Nuestro departamento técnico seguirá trabajando mensualmente en la reclamación activa de documentación.<br />
                Quedamos a su entera disposición para aclarar cualquier duda que puedan tener,<br />
                Atentamente,<br />
              </p>
              <img alt='logo' src={Logo} width={170} />
            </div>
          </tbody>
          <tfoot><tr><td style={{ border: 'none' }}>
            <div className="footer-space">&nbsp;</div>
          </td></tr></tfoot>
        </table>

        <style jsx>{`
          .header-space {
            height: 100px;
            background-image: url(${`'${Encabezado}'`});
            background-position: bottom;
            background-size: cover;
            margin-bottom: 50px;
          }
          .logoHeader{
            width:170px;
            height: 110px;
            margin: 60px 100px 30px;
          }
          .footer-space {
            height: 80px;
          }
          .portada {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: bottom;
            background-size: cover;
          }
          .pagina {
            margin-top: 150px;
            padding: 0px 100px;
            font-size: 10px;
          }
          .paginaPrimera{
            margin-top:0cm;
            padding-top: 0px;
            margin-bottom: 0px;
          }
          .paginaSegunda{
            margin-top:450px;
          }
          p {
            line-height: 1.8;
            text-align: justify;
          }
          br {
            display: block;
            margin: 18px 0 20px 0;
            line-height: 22px;
            content: " ";
          }
         .simpleTable {
            border-collapse: collapse;
            margin-top: 30px;
            margin-bottom: 30px;
          }
          .simpleTable th {
            text-transform: uppercase
          }
          .simpleTable td {
            page-break-inside: avoid;
          }
          .simpleTable th, .simpleTable td {
            border: 1px solid #000000;
            padding: 5px;
            line-height: 1.1
          }
          .simpleTable img {
            height: 25px
          }
          @page {
            size: A4 portrait;
            margin: 0;
          }
        `}</style>
      </>
    )
  }
}

export default withRouter(InformeSituacionProveedores);
