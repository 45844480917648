import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InicioCard from "../../../components/InicioCard";
import AAFFIcon from "../../../images/aaff.png"
import ConsultoresIcon from "../../../images/consultores.png"
import TecnicosIcon from "../../../images/tecnicos.png"
import ProveedoresIcon from "../../../images/proveedores.png"
import { firestore } from "../../../providers/firebase";
import firebase from "firebase";
import { ExportToExcel } from "../../../providers/export-excel";

function Inicio() {
/*   useEffect(()=> {
    async function procesarDatos() {
      try {
        const rango = 10;
        let contador = 0;
        let consultoras = [{nombre:"Jennifer", email:"jennifer.mosqueira@grupolae.net"}, {nombre:"Ane", email:"ane.nieto@grupolae.net"}, {nombre:"Mari Cruz", email:"maricruz.jerez@grupolae.net"}, {nombre:"Diana", email:"diana.giraldo@grupolae.net"}, {nombre:"Natalia", email:"natalia.ruiz@grupolae.net"}, {nombre:"Marina", email:"marina.jimenez@grupolae.net"}, {nombre:"Irene", email:"irene.osoro@grupolae.net"}, {nombre:"Ana", email:"ana.soria@grupolae.net"}]
        const registrosPrincipales = await obtenerRegistrosPrincipalesDeFirebase()
        let numCorreo = 0
        let listado = []
        for (let i = 0; i < registrosPrincipales.length; i += rango) {
          const registrosBloque  = registrosPrincipales.slice(i, i + rango);
          for (const prov of registrosBloque ) {
            let numActivas = 0
            if( 
              ((prov.documentos?.vigilancia?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(prov.documentos.vigilancia?.fechaCaducidad?.seconds *1000), new Date()) > 30)
              || (prov.documentos?.certificadoSS?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(prov.documentos.certificadoSS?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
              || (prov.documentos?.seguro?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(prov.documentos.seguro?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
              || (prov.documentos?.justificante?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(prov.documentos.justificante?.fechaCaducidad?.seconds *1000), new Date()) > 30)
              || (prov.documentos?.evaluacionRiesgos?.ruta === '' || prov.documentos?.planificacionPreventiva?.ruta === '' || prov.documentos?.vigilancia?.ruta === '' 
              || prov.documentos?.certificadoSS?.ruta === '' ||prov.documentos?.seguro?.ruta === '' || prov.documentos?.justificante?.ruta === '' )))
              
            {
              const datosAdicionales = await obtenerDatosAdicionalesDeFirebase(prov.nif)
              // console.log('Registros principales obtenidos:', datosAdicionales)
              for (const finca of datosAdicionales) {
                if (finca.nifProveedores.includes(prov.nif)) {
                  if(finca.activo === true || finca.activo === "true" || finca.activo === undefined){
                    numActivas++
                  }
                }
              }
              let DocsPendientes = []
              if(prov.documentos.justificante?.ruta === ''){ DocsPendientes.push("Justificante de pago de Servicio de Prevención Ajeno") }
              if(prov.documentos.certificadoSS?.ruta === ''){ DocsPendientes.push("Certificado corriente de pago Seguridad Social") }
              if(prov.documentos.seguro?.ruta === ''){ DocsPendientes.push("Seguro de responsabilidad civil") }
              if(prov.documentos.vigilancia?.ruta === ''){ DocsPendientes.push("Vigilancia de la salud del trabajador") }
              if(prov.documentos.vigilancia?.ruta === ''){ DocsPendientes.push("Planificación preventiva")}
              if(prov.documentos.vigilancia?.ruta === ''){ DocsPendientes.push("Identificación riesgos") }
              prov.docs = DocsPendientes
              if(numCorreo===8){numCorreo=0}
              prov.consultora = consultoras[numCorreo]
              if(numActivas > 0){
                // console.log(prov.nif, prov.consultora, numCorreo, numActivas)
                listado.push(prov)
                contador++;
                numCorreo++
              }
            }
          }
          console.log(`Procesando bloque ${Math.floor(i / rango) + 1}`);
          console.log(listado)
          listado = []
          await esperar(1000);
        }
        console.log('Registros del bloque:', listado);
        console.log('Proceso completado.');
      }catch (error) {
        console.error('Error durante el procesamiento de datos:', error);
      }
    }
    async function esperar(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function obtenerRegistrosPrincipalesDeFirebase() {
      try {
        
        const snapshot = await firestore.collection("proveedores").limit(150).get();
        const registros = [];

        snapshot.forEach((doc) => {
          registros.push(doc.data());
        });
        return registros;
      } catch (error) {
        console.error('Error al obtener registros principales:', error);
        throw error;
      }
      
    }
    
    async function obtenerDatosAdicionalesDeFirebase(registroPrincipal) {
      try {
        
        const snapshot = await firestore.collection("fincas").where("nifProveedores", "array-contains", registroPrincipal).get();
        const registros = [];

        snapshot.forEach((doc) => {
          registros.push(doc.data());
        });
        return registros;
      } catch (error) {
        console.error('Error al obtener registros principales:', error);
        throw error;
      }
    }
    const daysDiff = (dateFrom, dateTo) => {
      var diasdif= dateTo.getTime()-dateFrom.getTime();
      var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
      return contdias 
    }
    procesarDatos()
  },[]) */
  // useEffect(() => {
  //   let listado = []
  //   let promises = []
  //   let promises1 = []
  //   let fincas = []
  //   let consultores = {}
  //   let nifAAFF = []
  //   let aaffs = []
  //   let consultoras = [{nombre:"Jennifer", email:"jennifer.mosqueira@grupolae.net"}, {nombre:"Ane", email:"ane.nieto@grupolae.net"}, {nombre:"Mari Cruz", email:"maricruz.jerez@grupolae.net"}, {nombre:"Diana", email:"diana.giraldo@grupolae.net"}, {nombre:"Natalia", email:"natalia.ruiz@grupolae.net"}, {nombre:"Marina", email:"marina.jimenez@grupolae.net"}, {nombre:"Irene", email:"irene.osoro@grupolae.net"}, {nombre:"Ana", email:"ana.soria@grupolae.net"}]
    
  //   const daysDiff = (dateFrom, dateTo) => {
  //     var diasdif= dateTo.getTime()-dateFrom.getTime();
  //     var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
  //     return contdias 
  //   }
  //   let Ultreclamacion = undefined

  //   /* promises1.push(firestore.collection("fincas").get().then(snapshot => {
  //     let lista = {}
  //     snapshot.docs.forEach(finca =>{
  //         lista = finca.data()
  //         fincas.push(lista)
  //     })
  //   })) */
  //   promises1.push(firestore.collection("aaff").get().then(aaffsnapshot => {
  //       aaffsnapshot.docs.forEach(aaff =>{
  //           aaffs = { ...aaffs, [aaff.data().nif]: aaff.data()}
  //       })
  //   }))
  //   Promise.all(promises1).then(() => {
  //       // setConsultores( consultores )
  //       //setAAFFS({ aaffs })
  //   })

  //   firestore.collection("proveedores").limit(150).get().then(provs => {
  //     let numCorreo = 0
  //     // let lista = provs.data()
  //     // let DocsPendientes = []
  //     provs.docs.forEach( (prov, index) => {
  //       let numActivas = 0
  //       let lista = prov.data()
  //       const intervalo = 10 * 1000; // 10 segundos en milisegundos
  //       const elementosPorIteracion = 10;
  //       async function provsIntervalo(proveedores, interval, elementosPorIteracion) {
  //         // generar por intervalos de 10 segundos el envio de 100 mensajes
  //         for (let i = 0; i < proveedores.length; i += elementosPorIteracion) {
  //           const elementosActuales = proveedores.slice(i, i + elementosPorIteracion);
  //           console.log(elementosActuales.nif, i); // Aquí puedes realizar las operaciones que necesites con los elementos actuales
  //           await new Promise(resolve => setTimeout(resolve, interval));
  //         }
  //       }
  //       provsIntervalo(lista, intervalo, elementosPorIteracion).catch(error => {
  //         console.error('Error:', error);
  //       });
  //       if(lista.ultimaReclamacion !== undefined){
  //         Ultreclamacion = lista.ultimaReclamacion?.seconds
  //       }else{
  //         Ultreclamacion = lista.fechaAlta.seconds
  //       }
  //       if( 
  //         ((lista.documentos?.vigilancia?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(lista.documentos.vigilancia?.fechaCaducidad?.seconds *1000), new Date()) > 30)
  //         || (lista.documentos?.certificadoSS?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(lista.documentos.certificadoSS?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
  //         || (lista.documentos?.seguro?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(lista.documentos.seguro?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
  //         || (lista.documentos?.justificante?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(lista.documentos.justificante?.fechaCaducidad?.seconds *1000), new Date()) > 30)
  //         || (lista.documentos?.evaluacionRiesgos?.ruta === '' || lista.documentos?.planificacionPreventiva?.ruta === '' || lista.documentos?.vigilancia?.ruta === '' 
  //         || lista.documentos?.certificadoSS?.ruta === '' ||lista.documentos?.seguro?.ruta === '' || lista.documentos?.justificante?.ruta === '' )))
          
  //       {            
            
  //         /* promises.push(fincas.forEach((fItem) => {
  //           if (fItem.nifProveedores.includes(lista.nif)) {
  //             if(fItem.activo === true || fItem.activo === "true" || fItem.activo === undefined){
  //               numActivas++
  //             }
  //           }
  //         }));
  //         if(numCorreo===8){numCorreo=0}
  //         console.log(numCorreo)
  //         let DocsPendientes = []
  //         if(lista.documentos.justificante?.ruta === ''){ DocsPendientes.push("Justificante de pago de Servicio de Prevención Ajeno") }
  //         if(lista.documentos.certificadoSS?.ruta === ''){ DocsPendientes.push("Certificado corriente de pago Seguridad Social") }
  //         if(lista.documentos.seguro?.ruta === ''){ DocsPendientes.push("Seguro de responsabilidad civil") }
  //         if(lista.documentos.vigilancia?.ruta === ''){ DocsPendientes.push("Vigilancia de la salud del trabajador") }
  //         lista.docs = DocsPendientes
  //         lista.consultora = consultoras[numCorreo]
          
  //         if(numActivas > 0){
  //           console.log(lista.nif, lista.consultora, numCorreo)
  //           listado.push(lista)
  //           numCorreo++
  //         } */
  //         // console.log(fincas)
  //         promises.push(firestore.collection("fincas").where("nifProveedores", "array-contains", lista.nif).get().then(snapshot => {
  //           snapshot.docs.forEach(finca => {
  //               //  nifAAFF = nifAAFF.concat(finca.data().nifAAFF)
  //                 if(finca.data().activo === true || finca.data().activo === "true" || finca.data().activo === undefined){
  //                   numActivas++
  //                 }
  //           })   
  //           /* nifAAFF = [...new Set(nifAAFF)] 
  //           for(let i=0; i< nifAAFF.length; i++ ){
  //             firestore.collection("consultores").doc(aaffs[nifAAFF[i]].nifConsultor).get().then(cons => {
  //               // console.log(cons.data())
  //               consultores = {nombre: cons.data().nombre, email:cons.data().email}
              
  //             })
  //             }
  //           lista.Consultor = consultores */
  //           // lista.nifAAFF = nifAAFF
  //           // lista.numActivas = numActivas
  //           // lista.Ultreclamacion = Ultreclamacion
  //           // //console.log(e.nif, numActivas)
  //           // if(numActivas > 0){
  //           //   proveedores.push(e)
  //           // }
  //           if(numCorreo===8){numCorreo=0}
  //           console.log(numCorreo)
  //           let DocsPendientes = []
  //           if(lista.documentos.justificante?.ruta === ''){ DocsPendientes.push("Justificante de pago de Servicio de Prevención Ajeno") }
  //           if(lista.documentos.certificadoSS?.ruta === ''){ DocsPendientes.push("Certificado corriente de pago Seguridad Social") }
  //           if(lista.documentos.seguro?.ruta === ''){ DocsPendientes.push("Seguro de responsabilidad civil") }
  //           if(lista.documentos.vigilancia?.ruta === ''){ DocsPendientes.push("Vigilancia de la salud del trabajador") }
  //           if(lista.documentos.planificacionPreventiva?.ruta === ''){ DocsPendientes.push("Planificación preventiva") }
  //           if(lista.documentos.evaluacionRiesgos?.ruta === ''){ DocsPendientes.push("Identificación riesgos") }
  //           lista.docs = DocsPendientes
  //           lista.consultora = consultoras[numCorreo]
            
  //           if(numActivas > 0){
  //             console.log(index, lista.nif, lista.consultora, numCorreo)
  //             listado.push(lista)
  //             numCorreo++
  //           }
  //         })) 
  //       }
  //     })
  //     Promise.all(promises).then(() => {
  //       console.log("A")
  //       // console.log(fincas)
  //       console.log(listado)
  //       const intervalo = 10 * 1000; // 10 segundos en milisegundos
  //       const elementosPorIteracion = 10;
  //       async function recorrerConIntervalo(listado, interval, elementosPorIteracion) {
  //         for (let i = 0; i < listado.length; i += elementosPorIteracion) {
  //           const elementosActuales = listado.slice(i, i + elementosPorIteracion);
  //           console.log(elementosActuales); // Aquí puedes realizar las operaciones que necesites con los elementos actuales
        
  //           await new Promise(resolve => setTimeout(resolve, interval));
  //         }
  //       }
        
  //       recorrerConIntervalo(listado, intervalo, elementosPorIteracion).catch(error => {
  //         console.error('Error:', error);
  //       });
  //       for (const key in listado) {
  //         // console.log(listado[key].nif)
  //       }
  //       /* const emailMensual = firebase.app().functions('europe-west1').httpsCallable('emailMensual');
  //       emailMensual({proveedores:listado })
  //       .then( result => {
  //         console.log(result.data);
  //         //toast.success("Email enviado con éxito")
  //       }) */
  //     })

  //   })
  
  //   /* firestore.collection("fincas").get().then(snapshot => {
  //     snapshot.docs.forEach( finca => {
  //       promises.push(firestore.collection("hojasVista").doc(finca.data().nif).get().then( hv => {
  //         if(!hv.exists){
  //           console.log(hv.data().comunidad.nif)
  //           if( finca.data().nifAAFF !== undefined){
  //             firestore.collection("aaff").where("nif", "==", finca.data().nifAAFF).get().then( aaffs => {
  //               aaffs.docs.forEach( aaff => {
  //                 console.log(aaff.data().razonSocial, finca.data().razonSocial, new Date(finca.data().fechaAlta?.seconds *1000).toLocaleDateString())
  //               })
  //             })
  //           }else{
  //             console.log("no tiene nifaaff", finca.data().razonSocial, new Date(finca.data().fechaAlta?.seconds *1000).toLocaleDateString())
  //           }
  //         }
  //       }))
  //       Promise.all(promises).then(() => {
          
  //       })
  //     })
  //   }) */

  // },[])
  const [listaFincas, setListaFincas ]= useState([])
  useEffect(() => {
    // fetchData()
    renovarComunidades()
    // renovarComunidadesPrimeraVez()
  },[])

  const verHojasVistas = async () => {
    const collectionRef = firestore.collection('hojasVisita');
    const pageSize = 1000; // Número de documentos por página
    let lastVisible = null;
    let fincasCount = 0;
    const hojasVisitaMap = {};
    try {
        while (true) {
            let query = collectionRef.orderBy('__name__').limit(pageSize);

            if (lastVisible) {
                query = query.startAfter(lastVisible);
            }

            const snapshot = await query.get();
            

            if (snapshot.empty) {
                break; // No hay más documentos
            }

            snapshot.docs.forEach(hojaVisita => {
              hojasVisitaMap[hojaVisita.id] = hojaVisita.data();
              // console.log(hojaVisita.id, hojasVisitaMap);
            });

            lastVisible = snapshot.docs[snapshot.docs.length - 1];
            fincasCount += snapshot.docs.length;
            console.log(`Processed ${fincasCount} hojas visita so far.`);
        }
        // console.log(hojasVisitaMap);
        console.log('All hojas visita processed.');
        return hojasVisitaMap
    } catch (error) {
        console.error('Error getting hojas visita: ', error);
    }
  }
  
  const renovarComunidades = async () => {
    const hojasVisita = await verHojasVistas()
    // console.log(hojasVisita)
    let desde = new Date('2023-01-01')
    let hoy = new Date();
    let hasta = new Date();
    hasta.setMonth(hoy.getMonth() - 12)
    const collectionRef = firestore.collection('fincas');
    const pageSize = 1000; // Número de documentos por página
    let lastVisible = null;
    let fincasCount = 0;
    const maxDocuments = 10000;
    try {
      
      while (fincasCount < maxDocuments) {
          let query = collectionRef
          .where('fechaRenovacion', '>=', desde)
          .where('fechaRenovacion', '<=', hasta)
          .orderBy('fechaRenovacion').limit(pageSize);

          if (lastVisible) {
              query = query.startAfter(lastVisible);
          }

          const snapshot = await query.get();

          if (snapshot.empty) {
              break; // No hay más documentos
          }

          const batch = firestore.batch();

          for (const finca of snapshot.docs) {
            // const hojaVisitaData = hojasVisitaMap[finca.id];
            // console.log(hojaVisitaData)
            let contdias;
            if (finca.data().fechaRenovacion !== undefined) {
              let d = new Date(finca.data().fechaRenovacion.seconds * 1000);
              let diasdif = new Date() - d;
              contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
              // console.log(finca.data().nif, contdias);
              if (contdias > 365) {
                  // console.log(finca.id + " Renovar");
                  // const hojaVisitasRef = firestore.collection('hojasVisita').doc(finca.id);
                  // const hojaVisitasDoc = await hojaVisitasRef.get();
                  // console.log(finca.id, finca.data().nif, hojaVisitasDoc.exists, " Renovada de nuevo!")
                  if (hojasVisita[finca.id] !== undefined && hojasVisita[finca.id]?.fechaVisitaRenovacion) {
                      let visitaRevYear = new Date(hojasVisita[finca.id].fechaVisitaRenovacion.seconds * 1000).getFullYear();
                      let visitaYear = new Date(hojasVisita[finca.id].fechaVisita.seconds * 1000).getFullYear();
                      if (visitaRevYear === 2024 && (finca.data().nifAAFF !== '44869458T' || finca.data().nifAAFF !== 'B02379972' || finca.data().nifAAFF !== 'B02604122' || finca.data().nifAAFF !== 'J96628086')) {
                        let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 12));
                        console.log(2024, finca.id + " Renovada de nuevo!", new Date(newFechaRenovacion), finca.data().nifAAFF);
                        // const docRef = firestore.collection("fincas").doc(finca.id);
                        // batch.update(docRef, { 
                        //   renovada: true, 
                        //   fechaRenovacion: new Date(newFechaRenovacion)
                        // });
                      }
                      if (visitaRevYear === 2023 && (finca.data().nifAAFF !== '44869458T' || finca.data().nifAAFF !== 'B02379972' || finca.data().nifAAFF !== 'B02604122' || finca.data().nifAAFF !== 'J96628086')) {
                        let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 12));
                          console.log(2023, finca.id + " Renovada de nuevo!", new Date(newFechaRenovacion), finca.data().nifAAFF);
                          // const docRef = firestore.collection("fincas").doc(finca.id);
                          // batch.update(docRef, { 
                          //   renovada: true, 
                          //   renovadaVisitada: false,
                          //   fechaRenovacion: new Date(newFechaRenovacion)
                          // });
                      }
                      // if(visitaYear === 1970 && finca.data().nifAAFF === 'B59312611'){
                      //   let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 12));
                      //   console.log(2023, finca.id + " Renovada de nuevo!", new Date(newFechaRenovacion), finca.data().nifAAFF);
                      // }
                  }
              }
            }
          };
          await batch.commit();
          lastVisible = snapshot.docs[snapshot.docs.length - 1];
          fincasCount += snapshot.docs.length;
          console.log(`Processed ${fincasCount} fincas so far.`);
      }

        console.log('All fincas processed.');
        await renovarComunidadesPrimeraVez(hojasVisita)
    } catch (error) {
        console.error('Error getting fincas: ', error);
    }
  }
  const renovarComunidadesPrimeraVez = async (hv) => {
    // const hojasVisita = await verHojasVistas()
    // console.log(hojasVisita)
    const hojasVisita = hv
    let desde = new Date('2023-01-01')
    let hoy = new Date();
    let hasta = new Date();
    hasta.setMonth(hoy.getMonth() - 13)
    const collectionRef = firestore.collection('fincas');
    const pageSize = 1000; // Número de documentos por página
    let lastVisible = null;
    let fincasCount = 0;
    const maxDocuments = 10000;
    try {
      
      while (true) {
          let query = collectionRef
          .where('fechaAlta', '>=', desde)
          .where('fechaAlta', '<=', hasta)
          .orderBy('fechaAlta').limit(pageSize);

          if (lastVisible) {
              query = query.startAfter(lastVisible);
          }

          const snapshot = await query.get();

          if (snapshot.empty) {
              break; // No hay más documentos
          }

          const batch = firestore.batch();

          for (const finca of snapshot.docs) {
            // const hojaVisitaData = hojasVisitaMap[finca.id];
            // console.log(hojaVisitaData)
            let contdias;
            if(finca.data().fechaAlta !== undefined && finca.data().fechaRenovacion === undefined){
              let d = new Date(finca.data().fechaAlta.seconds * 1000);
              let diasdif = new Date() - d;
              contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
              // console.log(finca.data().nif, contdias);
              if (contdias > 395) {
                  // console.log(finca.id + " Alta renovada por primera vez!");
                  // const hojaVisitasRef = firestore.collection('hojasVisita').doc(finca.id);
                  // const hojaVisitasDoc = await hojaVisitasRef.get();
                  // console.log(finca.id, hojaVisitasDoc.exists," Alta renovada por primera vez!")
                  if (hojasVisita[finca.id] !== undefined && hojasVisita[finca.id]?.fechaVisita) {
                      let visitaYear = new Date(hojasVisita[finca.id].fechaVisita.seconds * 1000).getFullYear();
                      if (visitaYear === 2023 && (finca.data().nifAAFF !== '44869458T' || finca.data().nifAAFF !== 'B02379972' || finca.data().nifAAFF !== 'B02604122' || finca.data().nifAAFF !== 'J96628086')) {
                        let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 13));
                        console.log(finca.id + " Renovada por primera vez!", new Date(newFechaRenovacion), hojasVisita[finca.id]?.fechaVisita, finca.data().nifAAFF);
                        // const docRef = firestore.collection("fincas").doc(finca.id);
                        // batch.update(docRef, { 
                        //   renovada: true, 
                        //   renovadaVisitada: false,
                        //   fechaRenovacion: new Date(newFechaRenovacion) 
                        // });
                      }
                      // if(visitaYear === 1970 && finca.data().nifAAFF === 'B59312611'){
                      //   let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 13));
                      //   console.log(2023, finca.id + " Renovada de nuevo!", new Date(newFechaRenovacion), finca.data().nifAAFF);
                      // }
                  }
              }
            }
          };
          await batch.commit();
          lastVisible = snapshot.docs[snapshot.docs.length - 1];
          fincasCount += snapshot.docs.length;
          console.log(`Processed ${fincasCount} fincas so far.`);
      }

        console.log('All fincas processed.');
    } catch (error) {
        console.error('Error getting fincas: ', error);
    }
  }

  const fetchData = async () => { //listaado de comunidades pendientes de visita y renovadas sin visitar

      const collectionRef = firestore.collection('fincas');
      const pageSize = 10000; // Número de documentos por lote
      let lastVisible = null;
      let fincasCount = 0;
      const maxDocuments = 50;
  
      try {
          while (fincasCount < maxDocuments) {
              let query = collectionRef.orderBy('__name__').limit(pageSize);
  
              if (lastVisible) {
                  query = query.startAfter(lastVisible);
              }
  
              const snapshot = await query.get();
  
              if (snapshot.empty) {
                  break; // No hay más documentos
              }
  
              const batch = firestore.batch();
  
              for (const finca of snapshot.docs) {
                // if (fincasCount >= maxDocuments) {
                //   break;
                // }
                let contdias;
                if (finca.data().fechaRenovacion !== undefined) {
                    let d = new Date(finca.data().fechaRenovacion.seconds * 1000);
                    let diasdif = new Date() - d;
                    contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
                    // console.log(finca.data().nif, contdias);
                    if (contdias > 365) {
                        // console.log(finca.id + " Renovar");
                        let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 12));
                        console.log(finca.id + " Renovada de nuevo!", new Date(newFechaRenovacion), finca.data().nifAAFF);
                        // const docRef = firestore.collection("fincas").doc(finca.id);
                        // batch.update(docRef, { 
                        //   renovada: true, 
                        //   renovadaVisitada: false,
                        //   fechaRenovacion: new Date(newFechaRenovacion)
                        // });
                    }
                } else if(finca.data().fechaAlta !== undefined && finca.data().fechaRenovacion === undefined){
                    let d = new Date(finca.data().fechaAlta.seconds * 1000);
                    let diasdif = new Date() - d;
                    contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
                    // console.log(finca.data().nif, contdias);
                    if (contdias > 395) {
                        // console.log(finca.id + " Alta renovada por primera vez!");
                        let newFechaRenovacion = new Date(d.setMonth(d.getMonth() + 13));
                        console.log(finca.id + " Renovada por primera vez!", new Date(newFechaRenovacion), finca.data().nifAAFF);
                        // const docRef = firestore.collection("fincas").doc(finca.id);
                        // batch.update(docRef, { 
                        //   renovada: true, 
                        //   renovadaVisitada: false,
                        //   fechaRenovacion: new Date(newFechaRenovacion) 
                        // });
                    }
                }
              }
  
              await batch.commit();
  
              lastVisible = snapshot.docs[snapshot.docs.length - 1];
              fincasCount += snapshot.docs.length;
              console.log(`Processed ${fincasCount} fincas so far.`);
              // if (fincasCount >= maxDocuments) {
              //   break;
              // }
          }
  
          console.log('All fincas processed.');
      } catch (error) {
          console.error('Error processing fincas: ', error);
      }
  
    // let nifFincas = []
    // let fincas = []
    // let hojasVisita =[]
    // let TotalFincas = []
    // const response = await firestore.collection('hojasVisita').get()

    // response.docs.forEach( hv => {
    //     nifFincas = nifFincas.concat(hv.data().comunidad.nif)
    //     hojasVisita.push(hv.data())
    // })
    // nifFincas = [...new Set(nifFincas)] 
    // // console.log(nifFincas)

    // const repFinca = await firestore.collection('fincas').get()
    // repFinca.docs.forEach( f => {
    //   if(f.data().activo !== "false"){
    //     fincas.push(f.data())
    //   }
    // })  
    // let i = 0
    // let fecha = ""
    // fincas.forEach(elemento => {
    //   const existe = nifFincas.includes(elemento.nif)
    //   let fechaNoVisita = null
    //   if(new Date(elemento?.fechaNoVisita?.seconds * 1000).toLocaleDateString() !== "Invalid Date" && new Date(elemento?.fechaNoVisita?.seconds * 1000).toLocaleDateString() !== undefined){
    //     fechaNoVisita = new Date(elemento?.fechaNoVisita?.seconds * 1000).toLocaleDateString()
    //   }
    //   if(existe && elemento?.renovada && !elemento?.renovadaVisitada){
    //     // const hv = hojasVisita.find(objeto => objeto.comunidad.nif === elemento.nif)
    //     if(elemento?.centrosTrabajo?.length > 0){
    //       // console.log(elemento?.centrosTrabajo?.length)
    //       // console.log(elemento.nif, hv?.centrosTrabajo)
    //       elemento.centrosTrabajo.forEach(ct => {
    //         // const hvCt = hv?.centrosTrabajo?.find(objet => objet.nif === ct.nif)
    //         // console.log(hvCt?.nif, hvCt?.nombre, hvCt?.fechaVisita?.seconds, hvCt?.fechaVisitaRenovacion?.seconds)
    //         // if(new Date(hvCt?.fechaVisitaRenovacion?.seconds * 1000).getFullYear() !== 1970 && hvCt?.fechaVisitaRenovacion !== undefined && hvCt?.fechaVisitaRenovacion !== ""){
    //         //   fecha = new Date(hvCt?.fechaVisitaRenovacion?.seconds * 1000).toLocaleDateString()
    //         // }else{
    //         //   fecha = new Date(hvCt?.fechaVisita?.seconds * 1000).toLocaleDateString()
    //         // }
    //         TotalFincas.push({obs: "CENTRO DE TRABAJO EXISTE", nif: elemento.nif, nombre: ct?.nombre, fechaAlta: new Date(elemento?.fechaAlta?.seconds * 1000).toLocaleDateString(), fechaRenovacion: new Date(elemento?.fechaRenovacion?.seconds * 1000).toLocaleDateString()})
    //         console.log(i, existe, elemento.nif, elemento?.renovada, elemento?.renovadaVisitada, new Date(elemento?.fechaAlta.seconds * 1000).toLocaleDateString(), new Date(ct?.fechaRenovacion?.seconds * 1000).toLocaleDateString())
    //         i++
    //       })
    //       // elemento?.centrosTrabajo.forEach(ct => {
    //       //   if(new Date(ct?.fechaVisitaRenovacion?.seconds * 1000).getFullYear() !== 1970 && ct?.fechaVisitaRenovacion !== undefined){
    //       //     fecha = new Date(ct?.fechaVisitaRenovacion?.seconds * 1000).toLocaleDateString()
    //       //   }else{
    //       //     fecha = new Date(ct?.fechaVisita?.seconds * 1000).toLocaleDateString()
    //       //   }
    //       //   TotalFincas.push({obs: "CENTRO DE TRABAJO EXISTE", nif: elemento.nif, nombre: elemento.razonSocial, fechaAlta: new Date(elemento?.fechaAlta?.seconds * 1000).toLocaleDateString(), fechaVisita: fecha, fechaVisita: new Date(ct?.fechaVisita?.seconds * 1000).toLocaleDateString(), fechaVisitaRenovacion: new Date(ct?.fechaVisitaRenovacion?.seconds * 1000).toLocaleDateString()})
    //       //   // console.log(i, existe, elemento.nif, elemento?.renovada, elemento?.renovadaVisitada, new Date(elemento?.fechaAlta.seconds * 1000).toLocaleDateString(), fecha, new Date(ct?.fechaVisita?.seconds * 1000).toLocaleDateString(), new Date(ct?.fechaVisitaRenovacion?.seconds * 1000).toLocaleDateString())
    //       //   i++
    //       // })
    //     }else{
    //       // if(new Date(hv?.fechaVisitaRenovacion?.seconds * 1000).getFullYear() !== 1970 && hv?.fechaVisitaRenovacion !== undefined){
    //       //   fecha = new Date(hv?.fechaVisitaRenovacion?.seconds * 1000).toLocaleDateString()
    //       // }else{
    //       //   fecha = new Date(hv?.fechaVisita?.seconds * 1000).toLocaleDateString()
    //       // }
    //       TotalFincas.push({obs: "FINCA EXISTE", nif: elemento.nif, nombre: elemento.razonSocial, fechaAlta: new Date(elemento?.fechaAlta?.seconds * 1000).toLocaleDateString(), fechaRenovacion: new Date(elemento?.fechaRenovacion?.seconds * 1000).toLocaleDateString(), fechaNoVisita: fechaNoVisita, observacionNoVisita: elemento?.observacionNoVisita})
    //       console.log(i, existe, elemento.nif, elemento?.renovada, elemento?.renovadaVisitada, new Date(elemento?.fechaAlta.seconds * 1000).toLocaleDateString(), new Date(elemento?.fechaRenovacion?.seconds * 1000).toLocaleDateString())
    //       i++
    //     }
    //   }else if(!existe){
    //     if(elemento?.centrosTrabajo?.length > 0){
    //       console.log(elemento.nif, elemento?.centrosTrabajo?.length)
    //       elemento.centrosTrabajo.forEach(ct => {
    //         TotalFincas.push({obs: "CENTRO DE TRABAJO NO EXISTE", nif: ct.nif, nombre: ct.nombre, fechaAlta: new Date(elemento?.fechaAlta?.seconds * 1000).toLocaleDateString(), fechaRenovacion:null})
    //         // console.log(i, existe, elemento.nif, elemento?.renovada, elemento?.renovadaVisitada, new Date(elemento?.fechaAlta.seconds * 1000).toLocaleDateString())
    //         i++
    //       })
    //     }else{
    //       TotalFincas.push({obs: "FINCA NO EXISTE", nif: elemento.nif, nombre: elemento.razonSocial, fechaAlta: new Date(elemento?.fechaAlta?.seconds * 1000).toLocaleDateString(), fechaRenovacion:null, fechaNoVisita: fechaNoVisita, observacionNoVisita: elemento?.observacionNoVisita})
    //       // console.log(i, existe, elemento.nif, elemento?.renovada, elemento?.renovadaVisitada, new Date(elemento?.fechaAlta.seconds * 1000).toLocaleDateString())
    //       i++
    //     }
    //   }
    // })
    // console.log(TotalFincas)
    // setListaFincas(TotalFincas)
  }

  return (<> 
  {/* <ExportToExcel apiData={listaFincas} fileName={"listadoComunidades"} /> */}
    <div style={{
      textAlign: 'center',
      fontSize: 28,
      color: "#303248",
      fontWeight: 'bold',
      marginTop: 90
    }}>Bienvenido a tu gestor de fincas</div>
    <div style={{
      textAlign: 'center',
      fontSize: 22,
      color: "#303248",
      marginTop: 10,
      marginBottom: 70
    }}>¿QUÉ QUIERES HACER?</div>
    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
      <Grid item><InicioCard icono={AAFFIcon} texto="Gestionar Administradores de Fincas" ruta="/aaff" /></Grid>
      <Grid item><InicioCard icono={ConsultoresIcon} texto="Gestionar Consultores" ruta="/consultores" /></Grid>
      <Grid item><InicioCard icono={ProveedoresIcon} texto="Gestionar proveedores" ruta="/proveedores" /></Grid>
      <Grid item><InicioCard icono={TecnicosIcon} texto="Gestionar Técnicos SPA" ruta="/tecnicos" /></Grid>
      <Grid item><InicioCard icono={TecnicosIcon} texto="Gestionar Comerciales" ruta="/comerciales" /></Grid>
    </Grid>
  </>);
}

export default Inicio;
