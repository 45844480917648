import React from "react"
import { Routes, Route, Link } from "react-router-dom";
import Edificios from "../icons/edificios.svg";

import Inicio from "./pages/prov/Inicio";
import AAFF from "./pages/prov/AAFF";
import AAFFComunidades from "./pages/prov/AAFFComunidades";
import Comunidades from "./pages/prov/Comunidades";
import Comunidad from "./pages/prov/Comunidad";
import Proveedor from "./pages/prov/Proveedor";


export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff/:nif/comunidades" element={<AAFFComunidades />} />
      <Route path="/comunidades" element={<Comunidades />} />
      <Route path="/comunidades/:nif" element={<Comunidad />} />
      <Route path="/proveedor" element={<Proveedor />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <Link to="/proveedor">
        <div className="menuBtn">
          <img src={Edificios} alt="Panel Principal" />
          Panel Principal
        </div>
      </Link>
      <Link to="/aaff">
        <div className="menuBtn">
          <img src={Edificios} alt="Administradores de Fincas" />
          Administradores de Fincas
        </div>
      </Link>
      <Link to="/comunidades">
        <div className="menuBtn">
          <img src={Edificios} alt="Comunidades" />
          Comunidades
        </div>
      </Link>
    </>
  )
}