import React, {useEffect, useState, useCallback} from "react";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
//import Pagination from '../../../hooks/pagination';
import Swal from 'sweetalert2'

const AlertasProveedores = () => {
    
    const [proveedores, setProveedores] = useState([]);
    const [listaOriginal, setListaOriginal] = useState([]);
    //const [fincas, setFincas] = useState([]);
    const [Consultores, setConsultores] = useState([]);
    //const [AAFFS, setAAFFS] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [filtrarConsultor, setFiltrarConsultor] = useState('')

    
    /* const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); */

    /* const provsPerPage = 100
    console.log(currentPage) */
    useEffect (() =>{
        let proveedores = []
        let promises1 = []
        let promises = []
        let consultores = []
        let aaffs = {}
        promises1.push(firestore.collection("consultores").get().then(snapshot => {
            snapshot.docs.forEach(consultor => {
              consultores.push(consultor.data())
            })
        }))
        promises1.push(firestore.collection("aaff").get().then(aaffsnapshot => {
            aaffsnapshot.docs.forEach(aaff =>{
                aaffs = { ...aaffs, [aaff.data().nif]: aaff.data()}
            })
       }))
        Promise.all(promises1).then(() => {
            setConsultores( consultores )
            //setAAFFS({ aaffs })
        })
        
        firestore.collection("proveedores").orderBy("razonSocial").get().then(querysnapshot => {
          querysnapshot.docs.forEach(proveedor => {
              let nifAAFF = []
              let consultor = []
              let Ultreclamacion = undefined
              let numActivas = 0
              let promisesNum = []
              let fincas = []
              const e = proveedor.data()
              /* promisesNum.push(firestore.collection("fincas").where("nifProveedores", "array-contains", e.nif).get().then(provsnapshot => {
                //console.log(e.nif +" "+provsnapshot.size)
                provsnapshot.docs.forEach(pn => {
                  //console.log(e.nif, pn.data().nif, pn.data()?.activo)
                  if(pn.data().activo === true || pn.data().activo === "true" || pn.data().activo === undefined){
                    //console.log(pn.data().nif)
                    numActivas++
                    fincas.push(pn.data())
                  }
                })

              }))
              Promise.all(promisesNum).then(() => {
                if(fincas.length === 0 ){
                  console.log(e.nif, fincas.length)
                }
              }) */
              if(e.ultimaReclamacion !== undefined){
                Ultreclamacion = e.ultimaReclamacion.seconds
              }else{
                Ultreclamacion = e.fechaAlta.seconds
              }
              if(daysDiff(new Date(Ultreclamacion *1000), new Date()) > 60 && 
                ((e.documentos?.vigilancia?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds *1000), new Date()) > 30)
                || (e.documentos?.certificadoSS?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
                || (e.documentos?.seguro?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.seguro?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
                || (e.documentos?.justificante?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.justificante?.fechaCaducidad?.seconds *1000), new Date()) > 30)
                || (e.documentos?.evaluacionRiesgos?.ruta === '' || e.documentos?.planificacionPreventiva?.ruta === '' || e.documentos?.vigilancia?.ruta === '' 
                || e.documentos?.certificadoSS?.ruta === '' || e.documentos?.seguro?.ruta === '' || e.documentos?.justificante?.ruta === '' ))){
                      //sacar todos los 
                    promises.push(firestore.collection("fincas").where("nifProveedores", "array-contains", proveedor.data().nif).get().then(snapshot => {
                       snapshot.docs.forEach(finca => {
                            nifAAFF = nifAAFF.concat(finca.data().nifAAFF)
                            if(finca.data().activo === true || finca.data().activo === "true" || finca.data().activo === undefined){
                              numActivas++
                            }
                       })   
                       nifAAFF = [...new Set(nifAAFF)] 
                       for(let i=0; i< nifAAFF.length; i++ ){
                            consultor.push(aaffs[nifAAFF[i]]?.nifConsultor)
                        }
                       e.nifConsultor = consultor
                       e.nifAAFF = nifAAFF
                       e.numActivas = numActivas
                       e.Ultreclamacion = Ultreclamacion
                       //console.log(e.nif, numActivas)
                       if(numActivas > 0){
                         proveedores.push(e)
                       }
                    })) 
              }
          })
           
        
          Promise.all(promises).then(() => {
            // console.log(proveedores)
            proveedores.sort((a, b) => {
                            
              if (a.Ultreclamacion > b.Ultreclamacion) {
                return 1;
              }
              if (a.Ultreclamacion < b.Ultreclamacion) {
                return -1;
              }
              return 0;
            })

            setProveedores(proveedores)
            setListaOriginal(proveedores)            
            setIsLoading(false)
            //setTotalPages(Math.ceil(proveedores.length / provsPerPage))
            
          })
        })
    },[])
    const handleChange = (e) =>{
        let provs = []
        let consultor = e.target.value
        setFiltrarConsultor(e.target.value);
        if(consultor !==''){
            listaOriginal.map((p) => {
                if(p.nifConsultor.includes(consultor))
                provs.push(p)
                setProveedores(provs)
                //setPage(Math.ceil(provs.length / provsPerPage))
            })
        }else{
            setProveedores(listaOriginal)
            //setPage(Math.ceil(listaOriginal.length / provsPerPage))
        }
    }

    
   /*  let NUM_OF_RECORDS = proveedores.length;
    let LIMIT = 100;

    const onPageChanged = (event, page) => {
      event.preventDefault();
      setPage(page);
    }
    const currentData = proveedores.slice(
      (currentPage - 1) * provsPerPage,
      (currentPage - 1) * provsPerPage + provsPerPage
    );
    const handleClick = num => {
      setPage(num)
    } */
    //const startIndex = (currentPage - 1 ) * provsPerPage
    //const selectedUsers = proveedores.slice(startIndex, startIndex + provsPerPage)
    /* const startIndex = (page - 1 ) * provsPerPage
    const selectedUsers = proveedores.slice(startIndex, startIndex + provsPerPage) */

    const monthDiff = (dateFrom, dateTo) => {
        return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) //mostrar la diferencia en meses
    }

    const daysDiff = (dateFrom, dateTo) => {
        var diasdif= dateTo.getTime()-dateFrom.getTime();
        var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
        return contdias 
    }

    const cambiarUltimaReclamacion = (f) => {
      Swal.fire({
        title: 'Cambiar Ultima Reclamacion',
        html: `<input type="date" id="fecha" class="swal2-input">`,
        confirmButtonText: 'Cambiar',
        focusConfirm: false,
        preConfirm: () => {
          const fecha = Swal.getPopup().querySelector('#fecha').value
          if (!fecha) {
            Swal.showValidationMessage(`insertar fecha`)
          }
          return {fecha: fecha }
        }
      }).then((result) => {
        if(result.value !== undefined){
          Swal.fire({
            icon: 'success',
            title: 'Fecha cambiada'
          })
          firestore.collection("proveedores").doc(f).update({ 
            ultimaReclamacion: new Date(result.value.fecha)
          })
        }
      })
    }

    const ordenarUltimaReclamacion = () => {
      const proveedoresReverse = [...proveedores].reverse()
      setProveedores(proveedoresReverse)
    }

    if (proveedores === []) return <></> //Loading data...
    return ( 
        <>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>alerta Proveedores</div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              ALERTAS
              {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
            </div>
            <div className="tableContainer">
              <div className="buscador">
              <select value={filtrarConsultor} onChange={handleChange}>
                  <option value=""></option>
                  {Consultores.map((e, i) => {
                      return (
                          <option key={i} value={e.nif}>{e.nombre+" "+e.apellidos}</option>
                      )
                  })}
                </select>
              </div>  
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Proveedor</th>
                      <th style={{cursor:'pointer'}} onClick={ordenarUltimaReclamacion}>Última reclamación</th>
                      <th>NIF</th>
                      <th>Planificación preventiva</th>
                      <th>Identificación riesgos</th>
                      <th>Vigilancia salud</th>
                      <th>Certificado SS</th>
                      <th>Justificante pago SPA</th>
                      <th>Seguro Resp. Civil</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? <tr><td colSpan='10' style={{textAlign:'center'}}><CircularProgress style={{color:'#fc7271'}} /> </td></tr> :
                    proveedores.length === 0 ? <tr><td colSpan='10' style={{textAlign:'center'}}>No hay alertas pendientes</td></tr> :
                    proveedores.map((e, i) => {
                        const ultimaReclamacion = e.ultimaReclamacion !==undefined ? e.ultimaReclamacion?.seconds : e.fechaAlta.seconds
                        return (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td><Link to={"/proveedores/" + e.nif}>{e.razonSocial}</Link></td>
                          <td style={{ textAlign: "center" }} onClick={() => cambiarUltimaReclamacion(e.nif)} >{daysDiff(new Date(ultimaReclamacion *1000), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(ultimaReclamacion * 1000).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(ultimaReclamacion * 1000).toLocaleDateString()}</span>}</td>
                          <td><Link to={"/proveedores/" + e.nif}>{e.nif}</Link></td>
                          <td style={{ textAlign: "center" }}>{e.documentos.planificacionPreventiva?.ruta === "" || e.documentos.planPrevencion?.ruta === "" ? <img style={{width:"20px"}} src={Cross} alt="vacio"/> : <img style={{width:"20px"}} src={Tick} alt="correcto" />}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.ruta === "" || e.documentos.planEmergencia?.ruta === "" ? <img style={{width:"20px"}} src={Cross} alt="vacio"/> : <img style={{width:"20px"}} src={Tick} alt="correcto" />}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.vigilancia?.fechaCaducidad?.seconds !== undefined ? daysDiff(new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds *1000), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : "-"}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.certificadoSS?.fechaCaducidad?.seconds !== undefined ? daysDiff(new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds *1000), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : "-"}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.justificante?.fechaCaducidad?.seconds !== undefined ? daysDiff(new Date(e.documentos.justificante?.fechaCaducidad?.seconds *1000), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(e.documentos.justificante?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(e.documentos.justificante?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : "-"}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.seguro?.fechaCaducidad?.seconds !== undefined ? daysDiff(new Date(e.documentos.seguro?.fechaCaducidad?.seconds *1000), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(e.documentos.seguro?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(e.documentos.seguro?.fechaCaducidad?.seconds *1000).toLocaleDateString()}</span> : "-"}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {/* <Pagination
            totalRecords={NUM_OF_RECORDS}
            pageLimit={provsPerPage}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
            currentPage={page}
          /> */}
              
            </div>
          </div>
        </div>
      </>
    );
}
 
export default AlertasProveedores;