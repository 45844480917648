import React from "react";
import Portada from "../../../images/PortadaRiesgos.png"
import Encabezado from "../../../images/LogoLAENEW.png"
import Logo from "../../../images/LogoLAENEW.png"
import LogoTSP from "../../../images/logo_tsp.png"
import salida from "../../../images/salida.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import salidaEmergencia1 from "../../../images/salidaEmergencia1.png"
import extintor from "../../../images/extintor.png"
import ascensor from "../../../images/ascensor.png"
import peligro from "../../../images/peligro.png"
import { firestore } from "../../../providers/firebase"
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";

class InformeSituacionRiesgos extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: undefined,
      fincas: undefined,
      fincasHV:undefined,
      num1_0:0,
      num1_1:0,
      num1_2:0,
      num1_3:0,
      num1_4:0,
      num1_5:0,
      num1_6:0,
      num1_7:0,
      num1_8:0,
      numCT1_0:0,
      numCT1_1:0,
      numCT1_2:0,
      numCT1_3:0,
      numCT1_4:0,
      numCT1_5:0,
      numCT1_6:0,
      numCT1_7:0,
      numCT1_8:0,
      num2_1:0,
      num2_2:0,
      num2_3:0,
      num2_4:0,
      num2_5:0,
      num2_6:0,
      num2_7:0,
      num2_8:0,
      num2_9:0,
      num2_10:0,
      numCT2_1:0,
      numCT2_2:0,
      numCT2_3:0,
      numCT2_4:0,
      numCT2_5:0,
      numCT2_6:0,
      numCT2_7:0,
      numCT2_8:0,
      numCT2_9:0,
      numCT2_10:0,
      num3_1:0,
      num3_2:0,
      num3_3:0,
      numCT3_1:0,
      numCT3_2:0,
      numCT3_3:0,
      num4_1:0,
      num4_2:0,
      num4_3:0,
      num4_4:0,
      num4_6:0,
      num4_7:0,
      num4_8:0,
      num4_9:0,
      num4_10:0,
      num4_11:0,
      num4_12:0,
      num4_13:0,
      num4_14:0,
      num4_15:0,
      numCT4_1:0,
      numCT4_2:0,
      numCT4_3:0,
      numCT4_4:0,
      numCT4_6:0,
      numCT4_7:0,
      numCT4_8:0,
      numCT4_9:0,
      numCT4_10:0,
      numCT4_11:0,
      numCT4_12:0,
      numCT4_13:0,
      numCT4_14:0,
      numCT4_15:0,
      num5_1:0,
      num6_1:0,
      num6_2:0,
      num6_3:0,
      numCT5_1:0,
      numCT6_1:0,
      numCT6_2:0,
      numCT6_3:0,
      num10_0:0,
      num10_1:0,
      num10_2:0,
      num10_3:0,
      num10_4:0,
      numCT10_0:0,
      numCT10_1:0,
      numCT10_2:0,
      numCT10_3:0,
      numCT10_4:0,
      num11_0:0,
      num11_1:0,
      num11_2:0,
      numCT11_0:0,
      numCT11_1:0,
      numCT11_2:0,
      num12_0:0,
      num12_1:0,
      num12_2:0,
      numCT12_0:0,
      numCT12_1:0,
      numCT12_2:0,
    }
  }
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nif).get().then(snapshot => {
        let aaffs = snapshot.data()
        let promises = []
        promises.push(firestore.collection("tecnicos").doc(snapshot.data().nifTecnico).get().then( tecnico => {
            aaffs.tecnico = tecnico.data().nombre+ " "+tecnico.data().apellidos
        }))
        promises.push(firestore.collection("fincas").where('nifAAFF', '==', snapshot.data().nif).get().then( snapshot => {
            let fincas = []
            snapshot.docs.forEach(finca => fincas.push(finca.data()))
            this.setState({ fincas: fincas })
        }))
        promises.push(firestore.collection("hojasVisita").where('aaff.nif', '==', snapshot.data().nif).get().then( snapshot => {
            let fincas = []
            let n1_1 = 0
            let n1_2 = 0
            let n1_4 = 0
            let n1_5 = 0
            let n1_6 = 0
            let n1_7 = 0
            let n1_8 = 0
            let n2_1 = 0
            let n2_2 = 0
            let n2_3 = 0
            let n2_4 = 0
            let n2_5 = 0
            let n2_6 = 0
            let n2_7 = 0
            let n2_8 = 0
            let n2_9 = 0
            let n2_10 = 0
            let n3_1 = 0
            let n3_2 = 0
            let n3_3 = 0
            let n4_1 = 0
            let n4_2 = 0
            let n4_3 = 0
            let n4_4 = 0
            let n4_6 = 0
            let n4_7 = 0
            let n4_8 = 0
            let n4_9 = 0
            let n4_10 = 0
            let n4_11 = 0
            let n4_12 = 0
            let n4_13 = 0
            let n4_14 = 0
            let n4_15 = 0
            let n5_1 = 0
            let n6_1 = 0
            let n6_2 = 0
            let n6_3 = 0
            let n10_0 = 0
            let n10_1 = 0
            let n10_2 = 0
            let n10_3 = 0
            let n10_4 = 0
            let n11_0 = 0
            let n11_1 = 0
            let n11_2 = 0
            let n12_0 = 0
            let n12_1 = 0
            let n12_2 = 0
            snapshot.docs.forEach(finca => {
                fincas.push(finca.data())
                if(finca.data().centrosTrabajo?.length === 0 || finca.data().centrosTrabajo == undefined){
                    if(finca.data().p1_1 === 's') {n1_1++}
                    if(finca.data().p1_2 === 's') {n1_2++}
                    if(finca.data().p1_4 === 's') {n1_4++}
                    if(finca.data().p1_5 === 's') {n1_5++}
                    if(finca.data().p1_6 === 's') {n1_6++}
                    if(finca.data().p1_7 === 's') {n1_7++}
                    if(finca.data().p1_8 === 'n') {n1_8++}
                    if(finca.data().p2_1 === 'n') {n2_1++}
                    if(finca.data().p2_2 === 'n') {n2_2++}
                    if(finca.data().p2_3 === 'n') {n2_3++}
                    if(finca.data().p2_4 === 'n') {n2_4++}
                    if(finca.data().p2_5 === 'n') {n2_5++}
                    if(finca.data().p2_6 === 'n') {n2_6++}
                    if(finca.data().p2_7 === 'n') {n2_7++}
                    if(finca.data().p2_8 === 'n') {n2_8++}
                    if(finca.data().p2_9 === 'n') {n2_9++}
                    if(finca.data().p2_10 === 'n') {n2_10++}
                    if(finca.data().p3_1 === 'n') {n3_1++}
                    if(finca.data().p3_2 === 'n') {n3_2++}
                    if(finca.data().p3_3 === 'n') {n3_3++}
                    if(finca.data().p4_1 === 'n') {n4_1++}
                    if(finca.data().p4_2 === 'n') {n4_2++}
                    if(finca.data().p4_3 === 'n') {n4_3++}
                    if(finca.data().p4_4 === 'n') {n4_4++}
                    if(finca.data().p4_6 === 'n') {n4_6++}
                    if(finca.data().p4_7 === 'n') {n4_7++}
                    if(finca.data().p4_8 === 'n') {n4_8++}
                    if(finca.data().p4_9 === 'n') {n4_9++}
                    if(finca.data().p4_10 === 'n') {n4_10++}
                    if(finca.data().p4_11 === 'n') {n4_11++}
                    if(finca.data().p4_12 === 'n') {n4_12++}
                    if(finca.data().p4_13 === 'n') {n4_13++}
                    if(finca.data().p4_14 === 'n') {n4_14++}
                    if(finca.data().p4_15 === 'n') {n4_15++}
                    if(finca.data().p5_1 === 's') {n5_1++}
                    if(finca.data().p6_1 === 's') {n6_1++}
                    if(finca.data().p6_2 === 'n') {n6_2++}
                    if(finca.data().p6_3 === 'n') {n6_3++}
                    if(finca.data().p10_0 === 'n') {n10_0++}
                    if(finca.data().p10_1 === 'n') {n10_1++}
                    // if(finca.data().p10_2 === 'n') {n10_2++}
                    if(finca.data().p10_3 === 'n') {n10_3++}
                    if(finca.data().p10_4 === 'n') {n10_4++}
                    if(finca.data().p11_0 === 'n') {n11_0++}
                    if(finca.data().p11_1 === 'n') {n11_1++}
                    if(finca.data().p11_2 === 'n') {n11_2++}
                    if(finca.data().p12_0 === 'n') {n12_0++}
                    if(finca.data().p12_1 === 'n') {n12_1++}
                    if(finca.data().p12_2 === 'n') {n12_2++}
                }
                //comprobar si tiene centro de trabajo y en caso afirmativo ver que preguntas tiene cada uno y sumarle 1 si corresponde
                if(finca.data().centrosTrabajo?.length > 0){
                    let nc1_1 = 0
                    let nc1_2 = 0
                    let nc1_4 = 0
                    let nc1_5 = 0
                    let nc1_6 = 0
                    let nc1_7 = 0
                    let nc1_8 = 0
                    let nc2_1 = 0
                    let nc2_2 = 0
                    let nc2_3 = 0
                    let nc2_4 = 0
                    let nc2_5 = 0
                    let nc2_6 = 0
                    let nc2_7 = 0
                    let nc2_8 = 0
                    let nc2_9 = 0
                    let nc2_10 = 0
                    let nc3_1 = 0
                    let nc3_2 = 0
                    let nc3_3 = 0
                    let nc4_1 = 0
                    let nc4_2 = 0
                    let nc4_3 = 0
                    let nc4_4 = 0
                    let nc4_6 = 0
                    let nc4_7 = 0
                    let nc4_8 = 0
                    let nc4_9 = 0
                    let nc4_10 = 0
                    let nc4_11 = 0
                    let nc4_12 = 0
                    let nc4_13 = 0
                    let nc4_14 = 0
                    let nc4_15 = 0
                    let nc5_1 = 0
                    let nc6_1 = 0
                    let nc6_2 = 0
                    let nc6_3 = 0
                    let nc10_0 = 0
                    let nc10_1 = 0
                    let nc10_2 = 0
                    let nc10_3 = 0
                    let nc10_4 = 0
                    let nc11_0 = 0
                    let nc11_1 = 0
                    let nc11_2 = 0
                    let nc12_0 = 0
                    let nc12_1 = 0
                    let nc12_2 = 0
                    // console.log(finca.data().centrosTrabajo?.length)
                    finca.data().centrosTrabajo.forEach(c => {
                        // console.log(c)
                        if(c.p1_1 === 's') {nc1_1++}
                        if(c.p1_1 === 's') {nc1_2++}
                        if(c.p1_4 === 's') {nc1_4++}
                        if(c.p1_5 === 's') {nc1_5++}
                        if(c.p1_6 === 's') {nc1_6++}
                        if(c.p1_7 === 's') {nc1_7++}
                        if(c.p1_8 === 'n') {nc1_8++}
                        if(c.p2_1 === 'n') {nc2_1++}
                        if(c.p2_2 === 'n') {nc2_2++}
                        if(c.p2_3 === 'n') {nc2_3++}
                        if(c.p2_4 === 'n') {nc2_4++}
                        if(c.p2_5 === 'n') {nc2_5++}
                        if(c.p2_6 === 'n') {nc2_6++}
                        if(c.p2_7 === 'n') {nc2_7++}
                        if(c.p2_8 === 'n') {nc2_8++}
                        if(c.p2_9 === 'n') {nc2_9++}
                        if(c.p2_10 === 'n') {nc2_10++}
                        if(c.p3_1 === 'n') {nc3_1++}
                        if(c.p3_2 === 'n') {nc3_2++}
                        if(c.p3_3 === 'n') {nc3_3++}
                        if(c.p4_1 === 'n') {nc4_1++}
                        if(c.p4_2 === 'n') {nc4_2++}
                        if(c.p4_3 === 'n') {nc4_3++}
                        if(c.p4_4 === 'n') {nc4_4++}
                        if(c.p4_6 === 'n') {nc4_6++}
                        if(c.p4_7 === 'n') {nc4_7++}
                        if(c.p4_8 === 'n') {nc4_8++}
                        if(c.p4_9 === 'n') {nc4_9++}
                        if(c.p4_10 === 'n') {nc4_10++}
                        if(c.p4_11 === 'n') {nc4_11++}
                        if(c.p4_12 === 'n') {nc4_12++}
                        if(c.p4_13 === 'n') {nc4_13++}
                        if(c.p4_14 === 'n') {nc4_14++}
                        if(c.p4_15 === 'n') {nc4_15++}
                        if(c.p5_1 === 's') {nc5_1++}
                        if(c.p6_1 === 's') {nc6_1++}
                        if(c.p6_2 === 'n') {nc6_2++}
                        if(c.p6_3 === 'n') {nc6_3++}
                        if(c.p10_0 === 'n') {nc10_0++}
                        if(c.p10_1 === 'n') {nc10_1++}
                        // if(c.p10_2 === 'n') {nc10_2++}
                        if(c.p10_3 === 'n') {nc10_3++}
                        if(c.p10_4 === 'n') {nc10_4++}
                        if(c.p11_0 === 'n') {nc11_0++}
                        if(c.p11_1 === 'n') {nc11_1++}
                        if(c.p11_2 === 'n') {nc11_2++}
                        if(c.p12_0 === 'n') {nc12_0++}
                        if(c.p12_1 === 'n') {nc12_1++}
                        if(c.p12_2 === 'n') {nc12_2++}
                    })
                    this.setState({ 
                        numCT1_1: nc1_1, numCT1_2: nc1_2, numCT1_4: nc1_4, numCT1_5: nc1_5, numCT1_6: nc1_6, numCT1_7: nc1_7, numCT1_8: nc1_8,
                        numCT2_1: nc2_1, numCT2_2: nc2_2, numCT2_3: nc2_3, numCT2_4: nc2_4, numCT2_5: nc2_5, numCT2_6: nc2_6, numCT2_7: nc2_7, numCT2_8: nc2_8,  numCT2_9: nc2_9,  numCT2_10: nc2_10,
                        numCT3_1: nc3_1, numCT3_2: nc3_2, numCT3_3: nc3_3,
                        numCT4_1: nc4_1, numCT4_2: nc4_2, numCT4_3: nc4_3, numCT4_4: nc4_4, numCT4_6: nc4_6, numCT4_7: nc4_7, numCT4_8: nc4_8,  numCT4_9: nc4_9,  numCT4_10: nc4_10,  numCT4_11: nc4_11,  numCT4_12: nc4_12,  numCT4_13: nc4_13,  numCT4_14: nc4_14,  numCT4_15: nc4_15,
                        numCT5_1: nc5_1,
                        numCT6_1: nc6_1, numCT6_2: nc6_2, numCT6_3: nc6_3,
                        numCT10_0: nc10_0, numCT10_1: nc10_1, numCT10_2: nc10_2, numCT10_3: nc10_3, numCT10_4: nc10_4,
                        numCT11_0: nc11_0, numCT11_1: nc11_1, numCT11_2: nc11_2, 
                        numCT12_0: nc12_0, numCT12_1: nc12_1, numCT12_2: nc12_2, 

                     })
                }
            })
            // console.log(fincas)
            this.setState({ 
                fincasHV: fincas, 
                num1_1: n1_1, num1_2: n1_2,
                num2_1: n2_1, num2_2: n2_2, num2_3: n2_3, num2_4: n2_4, num2_5: n2_5, num2_6: n2_6, num2_7: n2_7, num2_8: n2_8,  num2_9: n2_9,  num2_10: n2_10,
                num3_1: n3_1, num3_2: n3_2, num3_3: n3_3,
                num4_1: n4_1, num4_2: n4_2, num4_3: n4_3, num4_4: n4_4, num4_6: n4_6, num4_7: n4_7, num4_8: n4_8,  num4_9: n4_9,  num4_10: n4_10, num4_11: n4_11, num4_12: n4_12, num4_13: n4_13, num4_14: n4_14, num4_15: n4_15,
                num5_1: n5_1,
                num6_1: n6_1, num6_2: n6_2, num6_3: n6_3,
                num10_0: n10_0, num10_1: n10_1, num10_2: n10_2, num10_3: n10_3, num10_4: n10_4,
                num11_0: n11_0, num11_1: n11_1, num11_2: n11_2, 
                num12_0: n12_0, num12_1: n12_1, num12_2: n12_2,
            })
        }))
        Promise.all(promises).then(() => {

            aaffs.fechaInforme = new Date().toLocaleDateString()
            this.setState({ aaff: aaffs }, () => {
              var src = document.getElementById('portada').style.backgroundImage
              var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');
      
              var img = new Image();
              img.onload = function () {
                window.print()
              }
              img.src = url;
              if (img.complete) img.onload();
            })
        })
        
      })
  }

  render() {
    let indice = []
    let i = 0
    if (this.state.aaff === undefined) return <CircularProgress style={{color:'#fc7271', position: 'absolute', top: '50%', left: '50%'}} />
    return (
      <>
        {console.log(this.state.fincasHV)}
        {console.log(this.state.num10_1, this.state.numCT10_1)}
        <div className="pagina" style={{ paddingTop: 0, paddingBottom:55}}>
            <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}></div>
        </div>
        <table>
          <thead><tr><td style={{ border: 'none' }}>
            <div style={{display: 'flex', alignItem: 'center', justifyContent: 'space-around', padding: '20px 0'}}>
                <img src={Logo} width={170} />
                <img src={LogoTSP} width={130} />
            </div>
          </td></tr></thead>
          <tbody>
            {/* <div className="pagina" style={{ paddingTop: 0, paddingBottom:55}}>
                <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}></div>
            </div> */}
            <div className="pagina paginaPrimera">
              <div style={{ height: 60 }}></div>
              <p style={{ paddingTop: 40, paddingBottom: 30 }}>A continuación se detalla el resumen de los diferentes riesgos identificados en las comunidades de propietarios</p>
              <table className="simpleTable">
                    <tbody>
                        <tr>
                            <td colSpan="2" className="colorRojo" align='center'><strong>INFORME DE RIESGOS</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Administrador de fincas:</strong></td>
                            <td>{this.state.aaff.razonSocial}</td>
                        </tr>
                        <tr>
                            <td><strong>CIF:</strong></td>
                            <td>{this.state.aaff.nif}</td>
                        </tr>
                        <tr>
                            <td><strong>Localidad:</strong></td>
                            <td>{this.state.aaff.municipio}</td>
                        </tr>
                        <tr>
                            <td><strong>Teléfono:</strong></td>
                            <td>{this.state.aaff.telefono}</td>
                        </tr>
                        <tr>
                            <td><strong>E-mail:</strong></td>
                            <td>{this.state.aaff.email}</td>
                        </tr>
                        <tr>
                            <td><strong>Técnico:</strong></td>
                            <td>{this.state.aaff.tecnico}</td>
                        </tr>
                        <tr>
                            <td><strong>Fecha del informe:</strong></td>
                            <td>{this.state.aaff.fechaInforme}</td>
                        </tr>
                        <tr>
                            <td><strong>Número de Comunidades:</strong></td>
                            <td>{this.state.fincas.length}</td>
                        </tr>
                    </tbody>
              </table>
              <h3><strong><u>Objeto</u></strong></h3>
              <p>El informe de riesgos tiene por objeto proponer medidas en base a los riesgos identificados en las comunidades de propietarios.</p>
              <h3><strong><u>Alcance</u></strong></h3>
              <p>El presente informe afecta a todas las comunidades de propietarios visitadas de {this.state.aaff.razonSocial}.</p>
              <h3><strong><u>Metodología</u></strong></h3>
              <p>Para elaborar el informe se tendrán en cuenta los riesgos identificados durante la visita por parte del técnico de prevención de riesgos laborales, que propondrá medidas preventivas y/o correctoras con el fin de minimizar dichos riesgos. </p>
              {/* <p>Los riesgos se clasificarán como “inmediatos” o como “no inmediatos” en base a la urgencia de su corrección. </p> */}
            </div>
            <div className="pagebreak"></div>
            <div className="pagina paginaSegunda">
              <table className="simpleTable">
                <thead>
                    <tr>
                        <td style={{ width: "15%" }} className="colorRojo">Riesgo identificado</td>
                        <td style={{ width: "10%" }} className="colorRojo">Origen</td>
                        <td style={{ width: "30%" }} className="colorRojo">Medidas Propuestas</td>
                        <td style={{ width: "45%" }} className="colorRojo">Comunidades afectadas</td>
                    </tr>
                </thead>
                <tbody>
                    {
                    (this.state.num1_2 > 0 || this.state.numCT1_2 > 0) && 
                        <>
                            <tr>
                                <td>Golpes y cortes por objetos y herramientas</td>
                                <td>Zonas comunes</td>
                                <td>Sustituir los cristales afectados, por otros en correcto estado</td>
                                <td>
                                    {this.state.fincasHV.map(f => {
                                        return (
                                            f.centrosTrabajo?.length > 0 ?
                                            f.centrosTrabajo.map(c=>{
                                                return(
                                                    c.p1_2 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                    )
                                                })
                                            :                                    
                                                f.p1_2 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{<Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link>}</span></Link> : null
                                        )
                                    })}
                                </td>
                            </tr>
                        </>
                    }
                    {
                    (this.state.num1_1 > 0 || this.state.numCT1_1 > 0) && 
                        <tr>
                            <td>Caídas de personas a distinto nivel</td>
                            <td>Zonas comunes</td>
                            <td>Reparar los desperfectos encontrados en el suelo y/o paredes</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                            f.centrosTrabajo.map(c=>{
                                                return(
                                                    c.p1_1 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                    )
                                            })
                                        : 
                                            f.p1_1 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                               
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num1_4 > 0 || this.state.numCT1_4 > 0) && 
                        <tr>
                            <td><span className="espacio">Contactos eléctricos.</span> <span className="espacio">Cables sueltos o en mal estado</span></td>
                            <td>Zonas comunes</td>
                            <td>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. <span className="espacio"></span>En especial las que crucen zonas de paso</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p1_4 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :
                                            f.p1_4 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null

                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num1_5 > 0 || this.state.numCT1_5 > 0) && 
                        <tr>
                            <td><span className="espacio">Contactos eléctricos.</span><span className="espacio">Cajas de registro abiertas</span></td>
                            <td>Zonas comunes</td>
                            <td>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). Documentar las revisiones. 
                                <span className="espacio"></span>
                                Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico 
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p1_5 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                    
                                            f.p1_5 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num1_6 > 0 || this.state.numCT1_6 > 0) && 
                        <tr>
                            <td>Caídas de personas al mismo nivel</td>
                            <td>General</td>
                            <td>Adecuar las rampas con tiras antideslizantes</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p1_6 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                    
                                            f.p1_6 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num1_7 > 0 || this.state.numCT1_7 > 0) && 
                        <tr>
                            <td><span className="espacio">Contactos eléctricos.</span><span className="espacio">Cuadro eléctrico abierto.</span></td>
                            <td>General</td>
                            <td>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p1_7 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                    
                                            f.p1_7 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num1_8 > 0 || this.state.numCT1_8 > 0) &&
                        <tr>
                            <td>Contactos eléctricos</td>
                            <td>General</td>
                            <td>Se recomienda señalizar el riesgo de contacto eléctrico en los cuadros eléctricos de las instalaciones mediante pictograma homologado.
                                <span className="espacio"></span>
                                <img src={peligro} alt="peligro riesgo eléctrico" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p1_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.nif+' - '+c.nombre}</span></Link></Link> : null 
                                                )
                                            })
                                        :                       
                                            f.p1_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.nif+' - '+f.comunidad.direccion}</span></Link></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_1 > 0 || this.state.numCT2_1 > 0) && 
                        <tr>
                            <td>Ascensores</td>
                            <td>Ascensores</td>
                            <td>Revisar periódicamente los ascensores mediante una empresa de mantenimiento y por personal autorizado.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_2 > 0 || this.state.numCT2_2 > 0) && 
                        <tr>
                            <td>Ascensores</td>
                            <td>Ascensores</td>
                            <td>Dotar de iluminación interior 24h. Revisarla periódicamente.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_3 > 0 || this.state.numCT2_3 > 0) && 
                        <tr>
                            <td>Ascensores</td>
                            <td>Ascensores</td>
                            <td>Se recomienda dotar de iluminación exterior 24h. Por ejemplo; sensores de movimiento para activar luz al entrar y salir del ascensor. 
                                <span className="espacio"></span>
                                Revisarla periódicamente.
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_4 > 0 || this.state.numCT2_4 > 0) && 
                        <tr>
                            <td>Ascensores</td>
                            <td>Ascensores</td>
                            <td>Dotar de alarma con conexión 24h. Revisarla periódicamente</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_5 > 0 || this.state.numCT2_5 > 0) && 
                        <tr>
                            <td>Ascensores</td>
                            <td>Ascensores</td>
                            <td>La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_5 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_5 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_6 > 0 || this.state.numCT2_6 > 0) && 
                        <tr>
                            <td>Ascensores</td>
                            <td>Ascensores</td>
                            <td>La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_6 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_6 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_7 > 0 || this.state.numCT2_7 > 0) && 
                        <tr>
                            <td>Contacto eléctrico</td>
                            <td>Ascensores</td>
                            <td>Se recomienda señalizar el riesgo eléctrico.
                                <span className="espacio"></span>
                                <img src={peligro} alt="peligro riesgo eléctrico" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_7 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_7 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_8 > 0 || this.state.numCT2_8 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Ascensores</td>
                            <td>Se recomienda disponer de un extintor de CO² en el interior de la sala de máquinas (si no lo hay fuera), a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual. </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_9 > 0 || this.state.numCT2_9 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Ascensores</td>
                            <td>Se recomienda disponer de un extintor, preferentemente, de CO² en el exterior de la sala de máquinas.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_9 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_9 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_10 > 0 || this.state.numCT2_10 > 0) && 
                        <tr>
                            <td>Atrapamientos</td>
                            <td>Ascensores</td>
                            <td>Se recomienda la instalación de un cartel con el texto: “Cuarto de maquinaria del ascensor”.
                                <span className="espacio"></span>
                                <img src={ascensor} style={{ height:'50px' }} alt="cuarto de maquinas ascensor" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num2_10 > 0 || this.state.numCT2_10 > 0) && 
                        <tr>
                            <td>Atrapamientos</td>
                            <td>Ascensores</td>
                            <td>Colocar un cartel con el texto “Se prohíbe la entrada a toda persona no autorizada”.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p2_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p2_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num3_1 > 0 || this.state.numCT3_1 > 0) && 
                        <tr>
                            <td>Caída de personal a distinto nivel</td>
                            <td>Azotea</td>
                            <td>Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p3_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p3_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num3_2 > 0 || this.state.numCT3_2 > 0) &&
                        <tr>
                            <td>Caída de personal a distinto nivel</td>
                            <td>Azotea</td>
                            <td>Se recomienda instalar escaleras de servicio para el acceso seguro a la zona de azoteas , o valorar la instalación de un medio de acceso seguro.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p3_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p3_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num3_3 > 0 || this.state.numCT3_3 > 0) &&
                        <tr>
                            <td>Caída de personal a distinto nivel</td>
                            <td>Azotea</td>
                            <td>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié.  
                                <span className="espacio"></span>
                                En caso de ser una azotea no transitable o se realicen trabajaos verticales se deberá estudiar la posibilidad de instalar línea de vida o verificar que todo el personal que acceda a la misma cuente con medidas anticaída, como por ejemplo una línea de vida provisional.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p3_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p3_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num3_3 > 0 && this.state.num3_4 > 0) || (this.state.numCT3_3 > 0 && this.state.numCT3_4 > 0) && 
                        <tr>
                            <td>Caída de personas al mismo nivel</td>
                            <td>Azotea</td>
                            <td>Se recomienda la instalación de anclajes para las líneas de vida para realizar los trabajos con mayor seguridad. 
                                <span className="espacio"></span>
                                En su defecto, se deberá verificar que el trabajador dispone de una línea de vida portátil.
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p3_3 === 'n'&& c.p3_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p3_3 === 'n'&& f.p3_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_1 > 0 || this.state.numCT4_1 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Se recomienda instalar extintores suficientes en las instalaciones. 
                                <span className="espacio"></span>
                                El número mínimo de extintores deberá ser el suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 metros.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_2 > 0 || this.state.numCT4_2 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017 por el que se aprueba el Reglamento de instalaciones de protección contra incendios.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_3 > 0 || this.state.numCT4_3 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.
                                <span className="espacio"></span>
                                <img src={extintor} alt="extintor" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_4 > 0 || this.state.numCT4_4 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m).  
                                <span className="espacio"></span>
                                Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_6 > 0 || this.state.numCT4_6 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Se recomienda señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida según CTE.
                                <span className="espacio"></span>
                                <img src={salidaEmergencia1} alt="salida de emergencia" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_6 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_6 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_7 > 0 || this.state.numCT4_7 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Se recomienda instalar luces de emergencia sobre las puertas de salida, para dotar de suficiente visibilidad el recorrido de evacuación y las salidas de emergencia.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_7 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_7 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_8 > 0 || this.state.numCT4_8 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Señalizar las direcciones de circulación en el interior del parking cuando éste supere los 500m2. Seguir normativa autonómica/municipal.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_9 > 0 || this.state.numCT4_9 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Señalizar la dirección y sentido de acceso en las entradas al parking cuando se disponga de dos o más puertas. </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_9 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_9 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_10 > 0 || this.state.numCT4_10 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Se recomienda dotar de sistemas automáticos de extinción de incendios (rociadores).
                                <span className="espacio"></span>
                                Seguir normativa autonómica/municipal.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_11 > 0 || this.state.numCT4_11 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Se recomienda instalar sistemas de extracción de humos en el parking siempre que éste supere los 500m2. 
                                <span className="espacio"></span>
                                Seguir normativa autonómica/municipal.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_11 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_11 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    } 
                    {(this.state.num4_12 > 0 || this.state.numCT4_12 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Seguir normativa autonómica/municipal para la instalación y mantenimiento de la BIE.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_12 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_12 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_13 > 0 || this.state.numCT4_13 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Parking</td>
                            <td>Seguir normativa autonómica/municipal para la instalación y mantenimiento de detectores de humo.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_13 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_13 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_14 > 0 || this.state.numCT4_14 > 0) && 
                        <tr>
                            <td>Contactos eléctricos</td>
                            <td>Parking</td>
                            <td>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_14 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_14 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num4_15 > 0 || this.state.numCT4_15 > 0) && 
                        <tr>
                            <td>Contactos eléctricos</td>
                            <td>Parking</td>
                            <td>Señalizar el cuadro eléctrico con “señal riesgo eléctrico”. Pictograma triangular amarillo con letras negras 
                                <span className="espacio"></span>
                                <img src={peligro} alt="peligro" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p4_15 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p4_15 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num5_1 > 0 || this.state.numCT5_1 > 0) && 
                        <tr>
                            <td>Exposición a contaminantes químicos</td>
                            <td>Jardín</td>
                            <td>Disponer de un armario cerrado bajo llave  para guardar los productos químicos. 
                                <span className="espacio"></span>
                                Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p5_1 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p5_1 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num6_1 > 0 || this.state.numCT6_1 > 0) && 
                        <tr>
                            <td>Exposición a contaminantes químicos</td>
                            <td>Piscina</td>
                            <td>Almacenar y manipular los productos químicos según las indicaciones de las Fichas de datos de seguridad de productos químicos. 
                                <span className="espacio"></span>
                                Disponer de un armario cerrado bajo llave para guardar los productos químicos. 
                                <span className="espacio"></span>
                                Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen.
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                                                          
                                            f.p6_1 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num6_2 > 0 || this.state.numCT6_2 > 0) &&     
                        <tr>
                            <td>Caídas de personal a diferente nivel</td>
                            <td>Piscina</td>
                            <td>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.  
                                <span className="espacio"></span>
                                Las piscinas en las que el acceso de niños a la zona de baño no esté controlado dispondrán de barreras de protección que impidan su acceso al vaso excepto a través de puntos previstos para ello, los cuales tendrán elementos practicables con sistemas de cierre y bloqueo. (Documento Básico SU Seguridad de Utilización Apartado Piscinas punto 1.1)
                                <span className="espacio"></span>
                                Las barreras de protección tendrán una altura mínima de 1200mm (Documento Básico SU Seguridad de Utilización Apartado Piscinas punto 1.1)
                                <span className="espacio"></span>
                                Seguir normativa autonómica/municipal.
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p6_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p6_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num6_3 > 0 || this.state.numCT6_3 > 0) && 
                        <tr>
                            <td>Caídas de personal a diferente nivel</td>
                            <td>Piscina</td>
                            <td>Se recomienda disponer de un aro salvavidas en las inmediaciones de la piscina.  La obligación y el número mínimo de aros salvavidas dependerá de la normativa de cada comunidad autónoma se recomienda realizar la consulta para determinar su instalación.
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p6_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p6_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num10_0 > 0 || this.state.numCT10_0 > 0) && 
                        <tr>
                            <td>Incendios</td>
                            <td>Extintores</td>
                            <td>Los edificios construidos o rehabilitados a partir de 1996 deberán contar con los medios de extinción necesarios y suficientes según las características del edificio de acuerdo al NBE-CPI/96,
                                <span className="espacio"></span>
                                Para los construidos antes de ésta fecha siempre es muy recomendable su instalación

                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p10_0 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p10_0 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num10_1 > 0 || this.state.numCT10_1 > 0) &&
                            <tr>
                                <td>Incendios</td>
                                <td>Extintores</td>
                                <td>Se recomienda consultar con empresa especializada en extinción de incendios si los extintores disponibles son suficientes para las características del edificio y realizar el mantenimiento periódico de los mismos.
                                    <span className="espacio"></span>
                                    Según CTE se dispondrán extintores en número suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 m. 
                                </td>
                                <td>
                                    {this.state.fincasHV.map(f => {
                                        return (
                                            f.centrosTrabajo?.length > 0 ?
                                            f.centrosTrabajo.map(c=>{
                                                return(
                                                    c.p10_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                    )
                                                })
                                            :                                   
                                                f.p10_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                        )
                                    })}
                                </td>
                            </tr>
                    }
                    {/* {(this.state.num10_2 > 0 || this.state.numCT10_2 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Extintores</td>
                            <td>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017 por el que se aprueba el Reglamento de instalaciones de protección contra incendios.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p10_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p10_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    } */}
                    {(this.state.num10_3 > 0 || this.state.numCT10_3 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Extintores</td>
                            <td>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.  Es muy importante que los extintores de incendios se encuentren colocados en lugares visibles y accesibles.
                                <span className="espacio"></span>
                                <img src={extintor} alt="extintor" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p10_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p10_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num10_4 > 0 || this.state.numCT10_4 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Extintores</td>
                            <td>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m). Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p10_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p10_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num11_0 > 0 || this.state.numCT11_0 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Iluminación</td>
                            <td>Se recomienda la instalación de iluminación de emergencia tanto en recorrido de evacuación como en las salidas de la finca.
                                <span className="espacio"></span>
                                La iluminación de emergencia está destinada a alertar a los usuarios de un edificio ante una situación de riesgo, e indicar los medios de evacuación. También está pensada para indicar junto con la señalización el protocolo de actuación dependiendo la situación, y la posterior evacuación.
                                <span className="espacio"></span>
                                <img src={lucesEmergencia} alt="luces de emergencia" />
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p11_0 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p11_0 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num11_1 > 0 || this.state.numCT11_1 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Iluminación</td>
                            <td>Se recomienda equipar las vías y salidas de evacuación con iluminación de emergencia de suficiente intensidad  según Reglamento actual.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p11_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p11_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num11_2 > 0 || this.state.numCT11_2 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Iluminación</td>
                            <td>Se recomienda equipar las salidas de la finca con iluminación de emergencia de suficiente intensidad con el fin de reducir consecuencias en caso de emergencia y ayudar a la evacuación del edificio.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p11_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p11_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num12_0 > 0 || this.state.numCT12_0 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Señalización</td>
                            <td>Se recomienda la instalación de señalización de emergencia tanto en recorrido de evacuación como en las salidas de la finca.</td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p12_0 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p12_0 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num12_1 > 0 || this.state.numCT12_1 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Señalización</td>
                            <td>Señalizar las salidas de la finca con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.
                                <span className="espacio"></span>
                                <img src={salida} alt="salida de emergencia" />
                                <span className="espacio"></span>
                                <i><small>Las salidas o salidas de emergencia constituyen una vía de escape ante cualquier accidente o situación de riesgo. Por esta razón, deben de estar señalizadas y visibles. La finalidad es poder prevenir pérdidas de vidas, evitar lesiones o proteger los bienes del edificio.</small></i>
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p12_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p12_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                    {(this.state.num12_2 > 0 || this.state.numCT12_2 > 0) &&
                        <tr>
                            <td>Incendios</td>
                            <td>Señalización</td>
                            <td>Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.
                                <span className="espacio"></span>
                                <img src={salidaEmergencia} alt="salida de emergencia" />
                                <span className="espacio"></span>
                                <i><small>La señalización de emergencia está destinada a alertar a los usuarios de un edificio ante una situación de riesgo, e indicar los medios de evacuación. También está pensada para indicar el protocolo de actuación dependiendo la situación, y la posterior evacuación.</small></i>
                            </td>
                            <td>
                                {this.state.fincasHV.map(f => {
                                    return (
                                        f.centrosTrabajo?.length > 0 ?
                                        f.centrosTrabajo.map(c=>{
                                            return(
                                                c.p12_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{c.nombre}</span></Link> : null 
                                                )
                                            })
                                        :                                   
                                            f.p12_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null
                                    )
                                })}
                            </td>
                        </tr>
                    }
                </tbody>
              </table>
              <p>Agradecemos la atención prestada por el personal de {this.state.aaff.razonSocial} en la gestión de las visitas a las comunidades así como en la continua colaboración con los proveedores a los que solicitamos la documentación necesaria para cumplir la coordinación de actividades empresariales.</p>
              <br/>
              <p>Quedamos a su entera disposición para aclarar cualquier duda,</p>
              <br />
              <img alt='logo' src={Logo} width={170} />
            </div>
          </tbody>
          <tfoot><tr><td style={{ border: 'none' }}>
            <div className="footer-space">&nbsp;</div>
          </td></tr></tfoot>
        </table>
        {/* <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}></div>
        <div className="pagebreak"></div>
        <div className="pagina">
            <div style={{position: 'fixed', width: '100%'}}>
                <div style={{display: 'flex', alignItem: 'center', justifyContent: 'space-around', padding: '20px 0'}}>
                    <img src={Logo} width={170} />
                    <img src={LogoTSP} width={130} />
                </div>
            </div>
            <div>
                <p style={{ paddingBottom: 30 }}>A continuación se detalla el resumen de los diferentes riesgos identificados en las comunidades de propietarios</p>
                <table className="simpleTable">
                    <tbody>
                        <tr>
                            <td colspan="2" className="colorRojo" align='center'><strong>INFORME DE RIESGOS</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Administrador de fincas:</strong></td>
                            <td>{this.state.aaff.razonSocial}</td>
                        </tr>
                        <tr>
                            <td><strong>CIF:</strong></td>
                            <td>{this.state.aaff.nif}</td>
                        </tr>
                        <tr>
                            <td><strong>Localidad:</strong></td>
                            <td>{this.state.aaff.municipio}</td>
                        </tr>
                        <tr>
                            <td><strong>Teléfono:</strong></td>
                            <td>{this.state.aaff.telefono}</td>
                        </tr>
                        <tr>
                            <td><strong>E-mail:</strong></td>
                            <td>{this.state.aaff.email}</td>
                        </tr>
                        <tr>
                            <td><strong>Técnico:</strong></td>
                            <td>{this.state.aaff.tecnico}</td>
                        </tr>
                        <tr>
                            <td><strong>Fecha del informe:</strong></td>
                            <td>{this.state.aaff.fechaInforme}</td>
                        </tr>
                        <tr>
                            <td><strong>Número de Comunidades:</strong></td>
                            <td>{this.state.fincas.length}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3><strong><u>Objeto</u></strong></h3>
            <p>El informe de riesgos tiene por objeto proponer medidas en base a los riesgos identificados en las comunidades de propietarios.</p>
            <h3><strong><u>Alcance</u></strong></h3>
            <p>El presente informe afecta a todas las comunidades de propietarios visitadas del Administrador de Fincas.</p>
            <h3><strong><u>Metodología</u></strong></h3>
            <p>Para elaborar el informe se tendrán en cuenta los riesgos identificados durante la visita por parte del técnico de prevención de riesgos laborales, que propondrá medidas preventivas y/o correctoras con el fin de minimizar dichos riesgos. </p>
            <p>Los riesgos se clasificarán como “inmediatos” o como “no inmediatos” en base a la urgencia de su corrección. </p>
            <table className="simpleTable">
                <thead>
                    <tr>
                        <td className="colorRojo">Riesgo identificado</td>
                        <td className="colorRojo">Origen</td>
                        <td className="colorRojo">Medidas Propuestas</td>
                        <td className="colorRojo">Comunidades afectadas</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Golpes y cortes por objetos y herramientas</td>
                        <td>Zonas comunes</td>
                        <td>Sustituir los cristales afectados, por otros en correcto estado</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p1_2 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Caídas de personas a distinto nivel</td>
                        <td>Zonas comunes</td>
                        <td>Reparar los desperfectos encontrados en el suelo y/o paredes</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p1_1 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Contactos eléctricos. <br/>Cables sueltos o en mal estado</td>
                        <td>Zonas comunes</td>
                        <td>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. En especial las que crucen zonas de paso</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p1_4 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Contactos eléctricos. <br/>Cajas de registro abiertas</td>
                        <td>Zonas comunes</td>
                        <td>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). Documentar las revisiones. <br/>
                            Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico 
                        </td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p1_5 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Caídas de personas al mismo nivel</td>
                        <td>General</td>
                        <td>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p1_6 === 's' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Contactos eléctricos</td>
                        <td>General</td>
                        <td>Se recomienda señalizar el riesgo de contacto eléctrico en los cuadros eléctricos de las instalaciones mediante pictograma homologado</td>
                        <td>
                            FALTA PREGUNTA
                        </td>
                    </tr>
                    <tr>
                        <td>Ascensores</td>
                        <td>Ascensores</td>
                        <td>Revisar periódicamente los ascensores mediante una empresa de mantenimiento y por personal autorizado </td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Ascensores</td>
                        <td>Ascensores</td>
                        <td>Dotar de iluminación interior 24h. Revisarla periódicamente</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Ascensores</td>
                        <td>Ascensores</td>
                        <td>Se recomienda dotar de iluminación exterior 24h. Por ejemplo; sensores de movimiento para activar luz al entrar y salir del ascensor. Revisarla periódicamente</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Ascensores</td>
                        <td>Ascensores</td>
                        <td>Dotar de alarma con conexión 24h. Revisarla periódicamente</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Ascensores</td>
                        <td>Ascensores</td>
                        <td>La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_5 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Ascensores</td>
                        <td>Ascensores</td>
                        <td>La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_6 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Contacto eléctrico</td>
                        <td>Ascensores</td>
                        <td>Se recomienda señalizar el riesgo eléctrico.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_7 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Ascensores</td>
                        <td>Se recomienda disponer de un extintor de CO² en el interior de la sala de máquinas (si no lo hay fuera), a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual. </td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_8 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Ascensores</td>
                        <td>Se recomienda disponer de un extintor, preferentemente, de CO² en el exterior de la sala de máquinas.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_9 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Atrapamientos</td>
                        <td>Ascensores</td>
                        <td>Se recomienda la instalación de un cartel con el texto: “Cuarto de maquinaria del ascensor”.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Atrapamientos</td>
                        <td>Ascensores</td>
                        <td>Colocar un cartel con el texto “Se prohíbe la entrada a toda persona no autorizada”.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p2_10 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Caída de personal a distinto nivel</td>
                        <td>Azotea</td>
                        <td>Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p3_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Caída de personal a distinto nivel</td>
                        <td>Azotea</td>
                        <td>Se recomienda instalar escaleras de servicio para el acceso seguro a la zona de azoteas , o valorar la instalación de un medio de acceso seguro.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p3_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Caída de personal a distinto nivel</td>
                        <td>Azotea</td>
                        <td>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié.  En caso de ser una azotea no transitable o se realicen trabajaos verticales se deberá estudiar la posibilidad de instalar línea de vida o verificar que todo el personal que acceda a la misma cuente con medidas anticaída, como por ejemplo una línea de vida provisional.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p3_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Caída de personas al mismo nivel</td>
                        <td>Azotea</td>
                        <td>Se recomienda la instalación de anclajes para las líneas de vida para realizar los trabajos con mayor seguridad. En su defecto, se deberá verificar que el trabajador dispone de una línea de vida portátil.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p3_3 === 'n' && f.p3_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Parking</td>
                        <td>Se recomienda instalar extintores suficientes en las instalaciones. El número mínimo de extintores deberá ser el suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 metros.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p4_1 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Parking</td>
                        <td>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017 por el que se aprueba el Reglamento de instalaciones de protección contra incendios.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p4_2 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Parking</td>
                        <td>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p4_3 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Parking</td>
                        <td>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m).  Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p4_4 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Incendios</td>
                        <td>Parking</td>
                        <td>Se recomienda señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida según CTE.</td>
                        <td>
                            {this.state.fincasHV.map(f => {
                                return (
                                    f.p4_6 === 'n' ? <Link to={`/doc-render/situacion-riesgos/${f.comunidad.nif}`}><span className="espacio">{f.comunidad.direccion}</span></Link> : null                                    
                                )
                            })}
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            <img alt='logo' src={Logo} width={170} />
        </div>
        <div className="pagebreak"></div>
        <div style={{display: 'flex', alignItem: 'center', justifyContent: 'space-around', padding: '20px 0'}}>
            <img src={Logo} width={170} />
            <img src={LogoTSP} width={130} />
        </div>
        <div className="pagina">
            
        </div> */}

        <style jsx>{`
          .header-space {
            height: 100px;
            background-image: url(${`'${Encabezado}'`});
            background-position: bottom;
            background-size: cover;
            margin-bottom: 50px;
          }
          .footer-space {
            height: 30px;
          }
          .portada {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: bottom;
            background-size: cover;
          }
          .pagina {
            margin-top: 150px;
            margin-bottom: 150px;
            padding: 0px 80px;
            font-size: 14px;
          }
          .paginaPrimera{
            padding-top: 300px;
            margin-bottom: 0px;
          }
          .paginaSegunda{
            margin-top:50px;
          }
          p {
            line-height: 1.8;
            text-align: justify;
          }
          br {
            display: block;
            margin: 18px 0 20px 0;
            line-height: 22px;
            content: " ";
          }
         .simpleTable {
            border-collapse: collapse;
            margin-bottom: 60px;
            width: 100%;
            font-size: 14px;
          }
          .simpleTable th {
            text-transform: uppercase
          }
          .simpleTable td {
            page-break-inside: avoid;
          }
          .simpleTable th, .simpleTable td {
            border: 1px solid #000000;
            padding: 5px;
            line-height: 1.1
          }
          .simpleTable img {
            height: 70px
          }
          .logos{
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
          @page {
            size: A4 portrait;
            margin: 0;
          }
          .colorRojo{
            background: #c31e64;
            color: #fff;
          }
          .espacio{
            display:block;
            margin-bottom: 5px;
          }
        `}</style>
      </>
    )
  }
}

export default withRouter(InformeSituacionRiesgos);
