import React from "react";
import { Link } from "react-router-dom";
import { firestore, auth, storage } from "../../../providers/firebase";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Editar from "../../../icons/editar.svg"
import Documento from "../../../icons/document.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import { abrirArchivo } from "../../../providers/documentos";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Collapse } from "@material-ui/core";
import NoVisitado from "../../../components/noVisitadoComunidad";

class Comunidades extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      fincasPendientes: [],
      fincasVisitadas: [],
      fincasRenovadasPendientes: [],
      fincasRenovadasVisitadas: [],
      campo: 'fincas',
      query: '',
      tab: 0,
      isLoading: true,
      mensaje: '',
      expandir: true,
      nifHojasVisita: [],
      hojasVisita: [],
      tipoOrden: 'asc',
      tipoOrdenCP: 'asc'
    }
  }
  componentDidMount() {
      this.loadPageContent()
  }

  loadPageContent = () => {
    let promises2 = []
    let hojasVisita = []
    let nifHojasVisita = []
    firestore.collection("hojasVisita").get().then( snapshot => {
      snapshot.docs.forEach( hv => {
        hojasVisita.push(hv.data())
        nifHojasVisita.push(hv.data().comunidad.nif)

      })
      this.setState({hojasVisita: hojasVisita, nifHojasVisita: nifHojasVisita})
    })
    firestore.collection("aaff").where("nifTecnico", "==", auth.currentUser.uid).get().then(aaffs => {
      //console.log(nifHojasVisita)
      let promises = []
      let fincas = []
      let fincasPendientes = []
      let fincasVisitadas = []
      let fincasRenovadasPendientes = []
      let fincasRenovadasVisitadas = []
      aaffs.docs.forEach(aaff => {
        promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaff.data().nif).get().then(snapshot => {
          snapshot.docs.forEach(e => {
            let finca = e.data()
            if(finca.activo === true || finca.activo === "true" ||finca.activo === undefined){
              
                if(nifHojasVisita.includes(finca.nif)){
                  hojasVisita.forEach( fhv => {
                    if(fhv.comunidad.nif === finca.nif){
                      finca['aaff'] = aaff.data()
                      let dias = ''
                      let year = new Date(fhv.fechaVisitaRenovacion?.seconds*1000).getFullYear()
                      if(finca.renovada && fhv.fechaVisitaRenovacion !== undefined && year !== 1970){
                        //console.log(finca.nif, this.daysDiff(new Date(fhv.fechaVisitaRenovacion?.seconds*1000), new Date()), finca.renovadaVisitada)
                        dias = this.daysDiff(new Date(fhv.fechaVisitaRenovacion?.seconds*1000), new Date())
                      }else{
                        //console.log(finca.nif, this.daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date()), finca.renovadaVisitada)
                        dias = this.daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date())
                      }
    
                      if(finca.renovada && !finca.renovadaVisitada && dias > 304){ //9 meses = 273 dias - 6 meses = 182 dias - 10 meses = 304 dias
                        finca.visita = 1
                        fincasRenovadasPendientes.push(finca)
                      }else if(finca.renovadaVisitada){
                        finca.visita = 0
                        fincasRenovadasVisitadas.push(finca)
                      }else{
                        finca.visita = 0
                        fincasVisitadas.push(finca)
                      }
                    }
                  })
                }else{
                  finca['aaff'] = aaff.data()
                  finca.visita = 1
                  fincasPendientes.push(finca)
                }
              
            }
          })
        }))
      })
      setTimeout(()=>{

        Promise.all(promises).then(() => {
          if(this.state.tab === 0){
            this.setState({ fincas: fincasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 1){
            this.setState({ fincas: fincasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }else if(this.state.tab === 2){
            this.setState({ fincas: fincasRenovadasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 3){
            this.setState({ fincas: fincasRenovadasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }
          /* console.log(fincas)
          console.log(fincasVisitadas)
          console.log(fincasPendientes) 
          console.log(fincasRenovadasVisitadas)
          console.log(fincasRenovadasPendientes)*/
          this.setState({fincasPendientes: fincasPendientes, fincasVisitadas: fincasVisitadas, fincasRenovadasVisitadas: fincasRenovadasVisitadas, fincasRenovadasPendientes: fincasRenovadasPendientes, isLoading: false })
        })
      },5000)
    })
    
    setTimeout(() =>{
      this.ordenarCP();
    },16000)
  }

  buscar = () => {
    if (this.state.campo === "fincas") {
      firestore.collection("aaff").where("nifTecnico", "==", auth.currentUser.uid).get().then(aaffs => {
        let promises = []
        let fincas = []
        let fincasPendientes = []
        let fincasVisitadas = []
        let fincasRenovadasPendientes = []
        let fincasRenovadasVisitadas = []
        aaffs.docs.forEach(aaff => {
          promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaff.data().nif).where('razonSocial', '>=', this.state.query.toUpperCase()).where('razonSocial', '<=', this.state.query.toUpperCase() + '\uf8ff').orderBy("razonSocial", "asc").get().then(snapshot => {
            snapshot.docs.forEach(e => {
              let finca = e.data()
              if(finca.activo === true || finca.activo === "true" ||finca.activo === undefined){
                if(this.state.nifHojasVisita.includes(finca.nif)){
                  this.state.hojasVisita.forEach( fhv => {
                    if(fhv.comunidad.nif === finca.nif){
                      finca['aaff'] = aaff.data()
                      if(finca.renovada && !finca.renovadaVisitada && this.daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date()) > 304){
                        fincasRenovadasPendientes.push(finca)
                      }else if(finca.renovadaVisitada){
                        fincasRenovadasVisitadas.push(finca)
                      }else{
                        fincasVisitadas.push(finca)
                      }
                    }
                  })
                }else{
                  finca['aaff'] = aaff.data()
                  fincasPendientes.push(finca)
                }

              }
              /* if(finca.documentos?.evaluacionRiesgos?.validado && finca.documentos?.planEmergencia?.validado && finca.documentos?.prevencionRiesgos?.validado){
                finca['aaff'] = aaff.data()
                fincasVisitadas.push(finca)
              }else{
                finca['aaff'] = aaff.data()
                fincasPendientes.push(finca)
              } */
              /* finca['aaff'] = aaff.data()
              fincas.push(finca) */
            })
          }))
        })
        Promise.all(promises).then(() => {
          if(this.state.tab === 0){
            this.setState({ fincas: fincasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 2){
            this.setState({ fincas: fincasRenovadasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 3){
            this.setState({ fincas: fincasRenovadasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }else{
            this.setState({ fincas: fincasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }
          //console.log(fincas)
          //console.log(fincasVisitadas)
          //console.log(fincasPendientes)
          this.setState({fincasPendientes: fincasPendientes, fincasVisitadas: fincasVisitadas, fincasRenovadasVisitadas: fincasRenovadasVisitadas, fincasRenovadasPendientes: fincasRenovadasPendientes, isLoading: false })
        })
      })
    } else {
      firestore.collection("aaff").where("nifTecnico", "==", auth.currentUser.uid).where('razonSocial', '>=', this.state.query.toUpperCase()).where('razonSocial', '<=', this.state.query.toUpperCase() + '\uf8ff').orderBy("razonSocial", "asc").get().then(aaffs => {
        let promises = []
        let fincas = []
        let fincasPendientes = []
        let fincasVisitadas = []
        let fincasRenovadasPendientes = []
        let fincasRenovadasVisitadas = []
        aaffs.docs.forEach(aaff => {
          promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaff.data().nif).get().then(snapshot => {
            snapshot.docs.forEach(e => {
              let finca = e.data()
              if(e.data().activo === true || e.data().activo === "true" ||e.data().activo === undefined){
                if(this.state.nifHojasVisita.includes(finca.nif)){
                  this.state.hojasVisita.forEach( fhv => {
                    if(fhv.comunidad.nif === finca.nif){
                      let dias = ''
                      finca['aaff'] = aaff.data()
                      if(finca.renovada && fhv.fechaVisitaRenovacion !== undefined){
                        //console.log(finca.nif, this.daysDiff(new Date(snapshot.data().fechaVisitaRenovacion?.seconds*1000), new Date()), finca.renovadaVisitada)
                        dias = this.daysDiff(new Date(fhv.fechaVisitaRenovacion?.seconds*1000), new Date())
                      }else{
                        //console.log(finca.nif, this.daysDiff(new Date(snapshot.data().fechaVisita?.seconds*1000), new Date()), finca.renovadaVisitada)
                        dias = this.daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date())
                      }
                      if(finca.renovada && !finca.renovadaVisitada && this.daysDiff(new Date(fhv.fechaVisita?.seconds*1000), new Date()) > 304){
                        finca.visita = 1
                        fincasRenovadasPendientes.push(finca)
                      }else if(finca.renovadaVisitada){
                        finca.visita = 0
                        fincasRenovadasVisitadas.push(finca)
                      }else{
                        finca.visita = 0
                        fincasVisitadas.push(finca)
                      }
                    }
                  })
                }else{
                  finca['aaff'] = aaff.data()
                  finca.visita = 1
                  fincasPendientes.push(finca)
                }
              }
              /* if(finca.documentos?.evaluacionRiesgos?.validado && finca.documentos?.planEmergencia?.validado && finca.documentos?.prevencionRiesgos?.validado){
                finca['aaff'] = aaff.data()
                fincasVisitadas.push(finca)
              }else{
                finca['aaff'] = aaff.data()
                fincasPendientes.push(finca)
              } */
              /* finca['aaff'] = aaff.data()
              fincas.push(finca) */
            })
          }))
        })
        Promise.all(promises).then(() => {
          if(this.state.tab === 0){
            this.setState({ fincas: fincasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 2){
            this.setState({ fincas: fincasRenovadasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 3){
            this.setState({ fincas: fincasRenovadasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }else{
            this.setState({ fincas: fincasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }
          //console.log(fincas)
          //console.log(fincasVisitadas)
          //console.log(fincasPendientes)
          this.setState({fincasPendientes: fincasPendientes, fincasVisitadas: fincasVisitadas, fincasRenovadasVisitadas: fincasRenovadasVisitadas, fincasRenovadasPendientes: fincasRenovadasPendientes, isLoading: false })
        })
      })
    }
    setTimeout(() =>{
      this.ordenarCP();
    },800)
  }

  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  handleChange = (event, newValue) => {
    if(newValue === 0){
      this.setState({ fincas: this.state.fincasPendientes })
      this.setState({mensaje: "No hay fincas pendientes"})
    }else if(newValue === 2){
      this.setState({ fincas: this.state.fincasRenovadasPendientes })
      this.setState({mensaje: "No hay fincas pendientes"})
    }else if(newValue === 3){
      this.setState({ fincas: this.state.fincasRenovadasVisitadas })
      this.setState({mensaje: "No hay fincas visitadas"})
    }else{
      this.setState({ fincas: this.state.fincasVisitadas })
      this.setState({mensaje: "No hay fincas visitadas"})
    }
    this.setState({tab: newValue});
    setTimeout(() =>{
      this.ordenarCP();
    },800)
  };

  ordenarCP = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      //console.log("a: "+a.codigoPostal+" "+"b "+b.codigoPostal)
      
      if (a.codigoPostal > b.codigoPostal) {
        return 1;
      }
      if (a.codigoPostal < b.codigoPostal) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
  }

  ordenarCPHeader = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (this.state.tipoOrdenCP === 'asc') {
        return a.codigoPostal - b.codigoPostal
      }else{
        return b.codigoPostal - a.codigoPostal
      }
    })
    this.setState(this.state.tipoOrdenCP === 'asc' ? {tipoOrdenCP:'desc'} : {tipoOrdenCP:'asc'})
  }

  ordenarFecha = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (this.state.tipoOrden === 'asc') {
        return a.fechaAlta - b.fechaAlta;
      } else {
        return b.fechaAlta - a.fechaAlta;
      }
    });
    // console.log(this.state.tipoOrden)
    this.setState(this.state.tipoOrden === 'asc' ? {tipoOrden:'desc'} : {tipoOrden:'asc'})
  };
  ordenarSinArchivos = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      //console.log("a: "+a.codigoPostal+" "+"b "+b.codigoPostal)
      if (a.documentos?.evaluacionRiesgos?.validado === true || a.documentos?.evaluacionRiesgos?.validado === "true") {
        return 1; 
      }
      if (b.documentos?.evaluacionRiesgos?.validado === true || b.documentos?.evaluacionRiesgos?.validado === "true") {
        return -1; 
      }

      if (a.documentos?.evaluacionRiesgos?.validado > b.documentos?.evaluacionRiesgos?.validado) {
        return 1;
      }
      if (a.documentos?.evaluacionRiesgos?.validado < b.documentos?.evaluacionRiesgos?.validado) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
  }

  monthDiff = (dateFrom, dateTo) => {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) //mostrar la diferencia en meses
  }

  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  render() {
    
    if (this.state.fincas === []) return <></> //Loading data...
    return (
      <>
      
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})` }}>Comunidades</div>
        <div className="pageContent tableContainer">
          <div className="buscador">
            <select value={this.state.campo} onChange={e => this.setState({ campo: e.target.value })}>
              <option value="fincas">Comunidades</option>
              <option value="aaff">Administradores de fincas</option>
            </select>
            <input placeholder="Razón social" value={this.state.query} onChange={e => this.setState({ query: e.target.value })}></input>
            <button onClick={this.buscar}>BUSCAR</button>
          </div>
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab label="No visitadas" />
            <Tab label="Visitadas" />
            <Tab label="Renovadas no Visitadas" />
            <Tab label="Renovadas Visitadas" />
          </Tabs>
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Administrador de fincas</th>
                  <th>Comunidad</th>
                  <th>NIF</th>
                  <th onClick={() => this.ordenarFecha()} style={{ cursor:'pointer'}}>Fecha alta</th>
                  <th>Dirección</th>
                  <th onClick={() => this.ordenarCPHeader()} style={{ cursor:'pointer'}}>C.P.</th>
                  <th>Municipio</th>
                  <th>Servicios</th>
                  <th>Empleados</th>
                  <th style={{ textAlign: "center" }}>Identificación de riesgos y medidas preventivas</th>
                  <th style={{ textAlign: "center" }}>Plan de emergencia</th>
                  <th style={{ textAlign: "center" }}>Plan de prevención</th>
                  <th style={{ textAlign: "center" }}>Hoja de visita</th>
                  <th style={{ textAlign: "center" }}>No visitada</th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? <tr><td colSpan='15' style={{textAlign:'center'}}><CircularProgress style={{color:'#fc7271'}} /> </td></tr> : 
                this.state.fincas.length===0 ? <tr><td colSpan='15' style={{textAlign:'center'}}>{this.state.mensaje}</td></tr> :
                this.state.fincas.map((e, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td>{i+1}</td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.aaff.razonSocial}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.nif}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{new Date(e.fechaAlta.seconds * 1000).toLocaleDateString()}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.direccion}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.codigoPostal}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.municipio}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE"}</Link></td>
                        <td><Link to={"/comunidades/" + e.nif}>{e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No"}</Link></td>
                        {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? 
                          <>
                            <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.validado ? <Link to={`/comunidades/${e.nif}/evaluacion-riesgos`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                            <td style={{ textAlign: "center" }}>{e.documentos.planEmergencia?.validado ? <Link to={`/comunidades/${e.nif}/PlanEmergencia`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                            <td style={{ textAlign: "center" }}>{e.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(e.documentos.prevencionRiesgos?.ruta)} /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                            <td style={{ textAlign: "center", width: 105 }}>
                              {this.state.nifHojasVisita.includes(e.nif) ? 
                                <Link to={`/comunidades/${e.nif}/hoja-visita`} target={'_blank'}><img src={Documento} alt="Hoja de visita" className="tableIcon" style={{ opacity: e.documentos.hojaVisita?.validado ? 1 : 0.4 }} /></Link>
                              :
                                <img src={Documento} alt="Hoja de visita" className="tableIcon" style={{ opacity: e.documentos.hojaVisita?.validado ? 1 : 0.4 }} onClick={() => abrirArchivo(e.documentos.hojaVisita?.ruta)} />
                              }
                              <Link to={"/comunidades/" + e.nif}><img src={Editar} alt="Editar" className="tableIcon" style={{ marginLeft: 15 }} /></Link>
                            </td>
                            <td style={{ textAlign: "center", width: 105 }}>{e.visita === 1 ? <NoVisitado finca={e} nif={e.nif} tipo={'finca'} /> : null}</td>
                          </>
                        : 
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        }
                      </tr>
                      {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? null :
                            <tr>
                              <td colSpan="16">
                                <Collapse in={this.state.expandir}>
                                  <table className="subTable">
                                    <tbody>
                                      {e.centrosTrabajo.map((c,j) => {
                                        return(
                                          <tr key={j}>
                                            <td colSpan={13} style={{ width: '120px'}}>{c.nombre}</td>
                                            <td style={{ textAlign: "center", width: 105 }}>{c.documentos.evaluacionRiesgos?.validado ? <Link to={`/comunidades/${e.nif}/${c.nif}/evaluacion-riesgos`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ textAlign: "center", width: 105 }}>{c.documentos.planEmergencia?.validado ? <Link to={`/comunidades/${e.nif}/${c.nif}/PlanEmergencia`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ textAlign: "center", width: 105 }}>{c.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(c.documentos.prevencionRiesgos?.ruta)} /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                            <td style={{ textAlign: "center", width: 105 }}>
                                              {this.state.nifHojasVisita.includes(e.nif) ? 
                                                <Link to={`/comunidades/${e.nif}/${c.nif}/hoja-visita`} target={'_blank'}><img src={Documento} alt="Hoja de visita" className="tableIcon" style={{ opacity: e.documentos.hojaVisita?.validado ? 1 : 0.4 }} /></Link>
                                              :
                                                <img src={Documento} alt="Hoja de visita" className="tableIcon" style={{ opacity: c.documentos.hojaVisita?.validado ? 1 : 0.4 }} onClick={() => abrirArchivo(c.documentos.hojaVisita?.ruta)} />
                                              }
                                              <Link to={"/comunidades/" + e.nif + "/" + c.nif}><img src={Editar} alt="Editar" className="tableIcon" style={{ marginLeft: 15 }} /></Link>
                                            </td>
                                            <td style={{ textAlign: "center", width: 105 }}>{e.visita === 1 ? <NoVisitado finca={e} nif={c.nif} tipo={'centroTrabajo'} /> : null} </td>
                                          </tr>
                                        )
                                      })}

                                    </tbody>
                                  </table>
                                </Collapse>
                              </td>

                            </tr>

                          }
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Comunidades);

