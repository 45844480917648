import React, {useState, Component} from "react";
import algoliasearch from 'algoliasearch/lite'
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import { LinearProgress, withStyles, Box } from "@material-ui/core";
import { firestore } from "../../../providers/firebase";
import {
  InstantSearch,
  connectHits,
  connectSearchBox,
  Configure,
} from 'react-instantsearch-dom';
import { ExportToExcel } from "../../../providers/export-excel";


const searchClient = algoliasearch(
  '4FZ79L1I7R',
  '5e4362c558e9cf1323f10a8da99ae95a'
);
const indexName = 'proveedores'



const columnas = []
const setColumnas = (result) => {
  columnas.push({
    nif: result.nif, 
    razonSocial: result.razonSocial, 
    telefono: result.telefono,
    email: result.email,
    municipio: result.municipio, 
    estado: result.estado, 
    ultimaReclamacion: new Date(result.fecha)
  })
}

let filtro = '' 
const setFiltro =(res) =>{
  filtro = res
}

let fecha=null
const Hits = ({ hits }) => (
  
  
  columnas.length = 0, 
  
  
  
  hits.length > 0 ? 
    hits.map(hit => (
      <>
        {
         //LA VARIABLE fecha RECOGE UNO DE LOS DOS FORMATOS QUE SE GENERAN EN LA PLATAFORMA ALGOLIA (LA DEL BUSCADOR). 
          //AL INSERTARLOS POR PRIMERA VEZ CREA UN FORMATO Y CUANDO SE MODIFICA EN LA PLATAFORMA CAE SE GUARDA EN OTRO FORMATO.
          //dE ESTA FORMA EVITAMOS POSIBLES ERRORES.
        fecha = hit.ultimaReclamacion !== undefined && hit.ultimaReclamacion !== null && hit.ultimaReclamacion !=='' ? 
          hit.ultimaReclamacion.toString().includes(" UTC") ? 
            new Date(hit.ultimaReclamacion.toString().replace(' UTC','')).toISOString()
          : 
            hit.ultimaReclamacion
        : 
          hit.fechaAlta.toString().includes(" UTC") ? 
            new Date(hit.fechaAlta.toString().replace(' UTC','')).toISOString()
          : 
            hit.fechaAlta,
        hit = {...hit, fecha:fecha},
        setColumnas(hit)
        }
      </>
    ))
  :''
);
const CustomHits = connectHits(Hits);

const MySearchBox = connectSearchBox(({ currentRefinement, searchState, isSearchStalled, refine }) => {
  
  return (
    <>
      {refine(filtro)}
    </>
  );
});
let listaProveedores = []
const setProveedores = (listp) => {
  listaProveedores = listp
}

class BuscadorProvs extends Component {
  componentDidMount() {
    let proveedor = []
    firestore.collection("proveedores").get().then(snapshot => {
      snapshot.docs.forEach(e => {
        proveedor.push(e.data())
      })
      setProveedores(proveedor)
    })
  }
  render(){
    setFiltro(this.props.busqueda)
    return (
      <>
      <ExportToExcel apiData={columnas} fileName={"listadoProveedores"} />
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <MySearchBox />
        <Configure restrictSearchableAttributes = {["razonSocial", "nif", "telefono", "email", "municipio", "provincia"]}/>
        <CustomHits />
      </InstantSearch>
      </>
    );
  }
  
}


export default BuscadorProvs;
